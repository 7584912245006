// TODOリファクタ: shelfのコンポーネントと統合する
import { Box, Popper, styled } from '@mui/material';
import { FC } from 'react';
import { Product } from 'types/common';
import { BucketArea, Position } from 'types/planogram';
import { theme } from '../../../../theme';
import { ProductsButtonGroup } from './productsButtonGroup';
import { ProductsButtonGroupVertical } from './productsButtonGroupVertical';

type Props = {
  open: boolean;
  product: Product;
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  handleClickRotate: () => void;
  targetCompartment: BucketArea;
  bucketIndex: number;
  areaPath?: Position[];
};

const marginTop = 2;
const marginLeft = -1;

export const precisePosition = (
  node: HTMLElement | null
): { marginTop: number; marginLeft: number } => {
  const rect = node?.getBoundingClientRect();
  const rectTop = rect?.top ?? 0;
  const rectHeight = rect?.height ?? 0;
  const rectLeft = rect?.left ?? 0;
  const marginTop = rectTop + rectHeight - Math.round(rectTop + rectHeight);
  const marginLeft = rectLeft - Math.round(rectLeft);
  return { marginTop, marginLeft };
};

export const CompartmentMenu: FC<Props> = ({
  open,
  anchorEl,
  handleClose,
  handleClickRotate,
  targetCompartment,
  bucketIndex,
  areaPath,
}) => {
  return (
    <>
      <Popper
        anchorEl={anchorEl}
        open={open}
        placement="left-end"
        sx={{
          overflow: 'visible',
          position: 'relative',
          width: 0,
          height: 0,
          minWidth: 0,
          minHeight: 0,
          background: 'unset',
          boxShadow: 'unset',
          display: 'flex',
          marginTop: marginTop + precisePosition(anchorEl).marginTop, //it needs because <Popper /> logic rounding the position
          marginLeft: marginLeft + precisePosition(anchorEl).marginLeft,
          '& .MuiMenu-list': {
            padding: 0,
            width: 'unset',
            height: 'unset',
          },
        }}
      >
        {/* Popperのwidth（50px) + border(1px)分ずらす */}
        <StyledButtonGroupBox sx={{ bottom: 0, left: '-51px' }}>
          <ProductsButtonGroupVertical
            targetCompartment={targetCompartment}
            bucketIndex={bucketIndex}
            areaPath={areaPath}
          />
        </StyledButtonGroupBox>
        {/* Popperのwidth（50px) + border(1px)分ずらす */}
        <StyledButtonGroupBox sx={{ bottom: '-51px' }}>
          <ProductsButtonGroup
            target={targetCompartment}
            handleClickRotate={handleClickRotate}
            handleClose={handleClose}
            bucketIndex={bucketIndex}
            areaPath={areaPath}
          />
        </StyledButtonGroupBox>
      </Popper>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase
const StyledButtonGroupBox = styled(Box)(() => ({
  position: 'absolute',
  borderRadius: '4px',
  border: 'solid 1px #DDDDDD',
  backdropFilter: 'blur(6px)',
  // eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase
  WebkitBackdropFilter: 'blur(6px)',
  backgroundColor: theme.palette.white.translucent,
}));
