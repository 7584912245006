import { CircularSpinner, ProductEmptyResult } from '@components/molecules';
import { ProductsGrid } from '@components/organisms';
import { Box, Divider } from '@mui/material';
import { setTags } from '@reducers/planogramEditor/reducer';
import { selectPlanogramEditorState } from '@reducers/planogramEditor/selectors';
import { useAppDispatch, useAppSelector } from '@store/index';
import { fullHeight } from '@utils/const';
import { FC, MutableRefObject, useCallback } from 'react';
import { Product } from '../../../types/common';
import { ProductFlagChips } from '../productFlagChips/productFlagChips';

type Props = {
  productsRef: MutableRefObject<HTMLDivElement | null>;
  isCompareMode?: boolean;
  chipsRef?: MutableRefObject<HTMLDivElement | undefined>;
  drawerHeight: string;
  handleEndReached: (index: number) => void;
  products?: Product[];
  isLoading: boolean;
  handleClickProduct: (product: Product) => void;
  isDraggable?: boolean;
  offset: number;
  selectedItemId?: number;
  setOffset: (offset: number) => void;
};
const heightCalcValue = '230px'; //TODO: do not use hard-code
const getStatus = ({
  isLoading,
  offset,
  hasProducts,
}: {
  isLoading: boolean;
  offset: number;
  hasProducts: boolean;
}) => {
  if (isLoading && offset === 0) return 'loading';
  if (!isLoading && !hasProducts) return 'empty';
  return 'products';
};

export const ProductsList: FC<Props> = ({
  productsRef,
  isCompareMode,
  chipsRef,
  drawerHeight,
  handleEndReached,
  products,
  isLoading = false,
  handleClickProduct,
  isDraggable,
  offset,
  selectedItemId,
  setOffset,
}) => {
  const dispatch = useAppDispatch();
  const { tags } = useAppSelector(selectPlanogramEditorState);
  const handleTagClick = useCallback(
    (tag?: string) => {
      if (!tag && tags.length === 0) return;
      setOffset(0);
      if (!tag) {
        dispatch(setTags([]));
      } else if (tags.includes(tag)) {
        dispatch(setTags(tags.filter((t) => t !== tag)));
      } else {
        dispatch(setTags([...tags, tag]));
      }
    },
    [dispatch, tags, setOffset]
  );
  const componentsMap = {
    loading: (
      <Box component="div" p={2}>
        <CircularSpinner />
      </Box>
    ),
    empty: (
      <Box
        component="div"
        sx={{ height: `calc(${fullHeight} - ${heightCalcValue})` }}
      >
        {/* messageがundefinedの時にデフォルト文字が表示されてしまうため空文字を挿入 */}
        <ProductEmptyResult title="該当する商品がありません" message="" />
      </Box>
    ),
    products: (
      <ProductsGrid
        isCompareMode={isCompareMode}
        isLoading={isLoading}
        products={products}
        handleClick={handleClickProduct}
        handleEndReached={handleEndReached}
        height={drawerHeight}
        isDraggable={isDraggable}
        selectedItemId={selectedItemId}
      />
    ),
  };
  return (
    <>
      <Box component="div" ref={chipsRef}>
        <ProductFlagChips
          handleClick={handleTagClick}
          tags={tags}
          isDisabled={isLoading}
        />
      </Box>
      <Divider sx={{ mb: 1, mx: -1 }} />
      <Box
        ref={productsRef}
        component="div"
        sx={{
          height: drawerHeight,
          overflow: 'hidden',
          position: 'relative',
          zIndex: 1,
        }}
      >
        {
          componentsMap[
            getStatus({ isLoading, offset, hasProducts: !!products?.length })
          ]
        }
      </Box>
    </>
  );
};
