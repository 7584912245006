import {
  ComparisonGridContainer,
  ComparisonGridItem,
  SelectableCard,
} from '@components/organisms';
import { ComparisonDivider } from '@components/organisms/comparisonDivider/comparisonDivider';
import {
  ColumnType,
  StatisticBox,
} from '@components/organisms/statisticBox/statisticBox';
import { useUpdateUrlQueryParamsOfDetailPages } from '@hooks/useUpdateUrlQueryParamsOfDetailPages';
import { Box, Divider, Typography } from '@mui/material';
import { getComparisonStatisticType } from '@utils/comparison';
import { emptyText } from '@utils/const';
import { FC } from 'react';
import { ProductTag, Rate, ShelfDetailView, ProfitTab } from 'types/common';
import { ProductSalesReport } from 'types/products';
import { RealogramShelfBoard } from 'types/realogram';
import { HeatMapBar } from './heatMapBar';
import {
  createComparisonProfitValue,
  createDefaultValue,
  createProductTagInformation,
  createRateValues,
} from '@utils/statistics';
import { Statistic, ProfitInformation } from 'types/statistics';

type Props = {
  view: ShelfDetailView;
  productTag: ProductTag;
  rate: Rate;
  shelfBoards?: RealogramShelfBoard[];
  realogramStatistics?: Statistic;
  comparedStatistics?: Statistic;
  report?: ProductSalesReport;
  comparedReport?: ProductSalesReport;
  handleChangeProductTag: (productTag: ProductTag) => void;
  handleChangeRate: (rate: Rate) => void;
  comparisonProfitTabValue: ProfitTab;
  setComparisonProfitTabValue: (profitTab: ProfitTab) => void;
  isFlatPlanogram?: boolean;
  isComparedWithPlanogram?: boolean;
};

export const ComparisonStatistics: FC<Props> = ({
  view,
  productTag,
  rate,
  realogramStatistics,
  report,
  handleChangeProductTag,
  handleChangeRate,
  comparisonProfitTabValue,
  setComparisonProfitTabValue,
  comparedStatistics,
  comparedReport,
  isFlatPlanogram = false,
  isComparedWithPlanogram,
}) => {
  const { updateAnalyticsQueryParams } = useUpdateUrlQueryParamsOfDetailPages();
  const defaultValue = createDefaultValue(
    isFlatPlanogram,
    realogramStatistics,
    comparedStatistics,
    isComparedWithPlanogram
  );
  const productTagInformation = createProductTagInformation(
    realogramStatistics,
    comparedStatistics
  );

  const comparisonProfitValue: ProfitInformation[] =
    createComparisonProfitValue(report, comparedReport);

  const rateValues = createRateValues(
    comparedStatistics,
    realogramStatistics,
    isComparedWithPlanogram
  );
  const scoreDiff = comparedStatistics?.score?.toString()
    ? (realogramStatistics?.score ?? 0) - (comparedStatistics.score ?? 0)
    : '';
  const scoreStatisticType = getComparisonStatisticType(scoreDiff);
  switch (view) {
    case 'default':
      return (
        <>
          <Typography variant="subtitle2" fontWeight="700" mb={2}>
            各データの比較
          </Typography>
          <ComparisonGridContainer sx={{ flexWrap: 'nowrap', mb: { sm: 1 } }}>
            {defaultValue.map((defaultInfo, index) => (
              <ComparisonGridItem
                key={index}
                display={{ xs: 'flex', breakpoint: 'block' }}
              >
                <StatisticBox
                  title={defaultInfo.title}
                  columnType={defaultInfo.columnType as ColumnType}
                  statisticType={defaultInfo.statisticType}
                  prevValue={defaultInfo.prevValue}
                  value={defaultInfo.value}
                  diffValue={defaultInfo.diffValue}
                  isPrevValueEmpty={defaultInfo.isPrevValueEmpty}
                  isValueEmpty={defaultInfo.isValueEmpty}
                />
                <ComparisonDivider />
              </ComparisonGridItem>
            ))}
          </ComparisonGridContainer>
        </>
      );
    case 'productFlag':
      return (
        <>
          <Typography variant="subtitle2" fontWeight="700" mb={1}>
            各属性のフェイス数
          </Typography>
          <ComparisonGridContainer>
            {productTagInformation.map((productTagInfo, index) => {
              const isSelected = productTag === productTagInfo.title;
              return (
                <ComparisonGridItem key={index}>
                  <SelectableCard
                    key={index}
                    selected={isSelected}
                    onClick={() => {
                      if (isSelected) return;
                      handleChangeProductTag(productTagInfo.title);
                    }}
                  >
                    <StatisticBox {...productTagInfo} />
                  </SelectableCard>
                </ComparisonGridItem>
              );
            })}
          </ComparisonGridContainer>
        </>
      );
    case 'profit':
      return (
        <>
          <ComparisonGridContainer>
            {comparisonProfitValue.map((profit, index) => {
              const isSelected = comparisonProfitTabValue === profit.key;
              return (
                <ComparisonGridItem key={index}>
                  <SelectableCard
                    key={index}
                    selected={isSelected}
                    onClick={() => {
                      if (isSelected) return;
                      setComparisonProfitTabValue(profit.key);
                      updateAnalyticsQueryParams(profit.key);
                    }}
                    sx={{ display: 'block' }}
                  >
                    <StatisticBox
                      title={profit.title}
                      columnType="positive"
                      statisticType={profit.statisticType}
                      percent={profit.percent}
                      prevValue={profit.prevValue || emptyText}
                      value={profit.value || emptyText}
                      diffValue={profit.diff}
                      isPrevValueEmpty={!profit.prevValue}
                      isValueEmpty={!profit.value}
                      isTotalGrossSalesPrice={profit.isTotalGrossSalesPrice}
                    />
                    {isSelected && (
                      <Box component="div" mt={2}>
                        <HeatMapBar
                          title="分布指標"
                          min={profit.min}
                          max={profit.max}
                        />
                      </Box>
                    )}
                  </SelectableCard>
                </ComparisonGridItem>
              );
            })}
          </ComparisonGridContainer>
        </>
      );
    case 'rate':
      return (
        <>
          <ComparisonGridContainer>
            <ComparisonGridItem>
              <Box
                component="div"
                display={{ xs: 'flex', breakpoint: 'block' }}
                mt={1}
                mb={2}
              >
                <StatisticBox
                  title="スコア"
                  columnType="positive"
                  statisticType={scoreStatisticType}
                  prevValue={comparedStatistics?.score?.toString() || emptyText}
                  value={realogramStatistics?.score?.toString() || emptyText}
                  diffValue={scoreDiff.toString()}
                  isPrevValueEmpty={!comparedStatistics?.score?.toString()}
                  isValueEmpty={!realogramStatistics?.score?.toString()}
                />
              </Box>
              <Divider
                sx={{
                  mx: -2,
                  mt: 2,
                  display: { xs: 'none', breakpoint: 'block' },
                }}
              />
            </ComparisonGridItem>
          </ComparisonGridContainer>

          <Typography variant="subtitle2" fontWeight="700" mt={2} mb={1}>
            各評価のアイテム数
          </Typography>
          <ComparisonGridContainer>
            {rateValues.map(
              (
                {
                  item,
                  prevValue,
                  value,
                  columnType,
                  statisticType,
                  diff,
                  isPrevValueEmpty,
                  isValueEmpty,
                },
                index
              ) => {
                const isSelected = item === rate;
                return (
                  <ComparisonGridItem key={index}>
                    <SelectableCard
                      selected={isSelected}
                      onClick={() => {
                        if (isSelected) return;
                        handleChangeRate(item);
                      }}
                    >
                      <StatisticBox
                        title={item}
                        columnType={columnType}
                        statisticType={statisticType}
                        prevValue={prevValue}
                        value={value}
                        diffValue={diff}
                        isPrevValueEmpty={isPrevValueEmpty}
                        isValueEmpty={isValueEmpty}
                      />
                    </SelectableCard>
                  </ComparisonGridItem>
                );
              }
            )}
          </ComparisonGridContainer>
        </>
      );
    default:
      return <></>;
  }
};
