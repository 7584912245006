import { Box, CircularProgress, SxProps } from '@mui/material';
import { FC } from 'react';

type CircularSpinnerProps = {
  color?: string;
  sx?: SxProps;
};

export const CircularSpinner: FC<CircularSpinnerProps> = ({
  color = '#BDBDBD',
  sx = {},
}) => {
  return (
    <Box
      component="div"
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100%"
      color={color}
      sx={sx}
    >
      <CircularProgress color="inherit" />
    </Box>
  );
};
