import { ComparisonBayHeader } from '@components/molecules/comparisionBayHeader/comparisionBayHeader';
import { SelectComparison } from '@components/molecules/selectComparison/selectComparison';
import { RealogramImageAndBbox } from '@components/organisms';
import { DevelopingFeatureDialog } from '@components/organisms/developingFeatureDialog/developingFeatureDialog';
import { SearchModal } from '@components/organisms/searchModal/searchModal';
import { SelectedItemCompare } from '@components/organisms/selectedItemCompare/selectedItemCompare';
import { StatisticsValueSelector } from '@components/organisms/statisticsValueSelector/statisticsValueSelector';
import { useComparisonItemModal } from '@hooks/useComparisonItemModal';
import { useEstimatedProfit } from '@hooks/useEstimatedProfit';
import { useSelectPrevNextRealogram } from '@hooks/useSelectPrevNextRealogram';
import { Box, Divider } from '@mui/material';
import { CurrentSelectedType } from '@reducers/comparisonItemModal';
import { setComparisonSameIds } from '@reducers/product';
import { useAppDispatch } from '@store/index';
import {
  ComparisonTabModal,
  getDisplayValue,
  getProfitsMenu,
  pointersDummy,
} from '@utils/const';
import { extractPlanogramProductIds } from '@utils/planogram';
import { findSameProductIds } from '@utils/product';
import { extractRealogramProductIds } from '@utils/realogram';
import { t } from 'i18next';
import { FC, useEffect, useMemo, useState } from 'react';
import { theme } from 'theme';
import {
  ProfitTab,
  Rate,
  ShelfDetailMode,
  ShelfDetailView,
} from 'types/common';
import { Planogram } from 'types/planogram';
import { ProductReport, ProductSalesReport } from 'types/products';
import {
  RealogramCandidate,
  RealogramCandidateContainCurrentRole,
  RealogramSelectedItem,
  RealogramShelfBoard,
} from 'types/realogram';

type Props = {
  shelfBoards?: RealogramShelfBoard[];
  comparedPlanogram?: Planogram;
  comparedRealogram?: RealogramCandidateContainCurrentRole;
  isLoading: boolean;
  handleClickRealogramBbox: (selectedItem: RealogramSelectedItem) => void;
  view: ShelfDetailView;
  mode: ShelfDetailMode;
  realogramCandidateId: number;
  storeBayName: string;
  createdAt: string;
  rate: Rate;
  profitTab: ProfitTab;
  comparedShelfboards?: RealogramShelfBoard[];
  productReports?: ProductReport[];
  comparedProductReports?: ProductReport[];
  isImageLoading: boolean;
  handleChangeLoading: () => void;
  realogramCandidate?: RealogramCandidate;
  currentSelectedType?: CurrentSelectedType;
  report?: ProductSalesReport;
  isForbidden?: boolean;
  handleClickOutSideRealogramBbox?: () => void;
  isBucketType: boolean;
  isTenantSalesAnalytics?: boolean;
  analyticsDataTerm: string;
  disableChangeToPreviousWeek: boolean;
  disableChangeToNextWeek: boolean;
  handleNextWeekTerm: () => void;
  handlePreviousWeekTerm: () => void;
  comparedAnalyticsData?: ProductSalesReport;
  comparedAnalyticsDataTerm: string;
  disableChangeToComparedPreviousWeek: boolean;
  disableChangeToComparedNextWeek: boolean;
  handleComparedNextWeekTerm: () => void;
  handleComparedPreviousWeekTerm: () => void;
};
export const RealogramOrPlanogramImages: FC<Props> = ({
  shelfBoards,
  comparedPlanogram,
  comparedRealogram,
  isLoading,
  handleClickRealogramBbox,
  handleClickOutSideRealogramBbox,
  view,
  mode,
  realogramCandidateId,
  storeBayName,
  createdAt,
  rate,
  profitTab,
  comparedShelfboards,
  productReports,
  comparedProductReports,
  isImageLoading,
  handleChangeLoading,
  realogramCandidate,
  currentSelectedType,
  report,
  isForbidden = false,
  isBucketType,
  isTenantSalesAnalytics = false,
  comparedAnalyticsData,
  comparedAnalyticsDataTerm,
  disableChangeToComparedPreviousWeek,
  disableChangeToComparedNextWeek,
  handleComparedNextWeekTerm,
  handleComparedPreviousWeekTerm,
  analyticsDataTerm,
  disableChangeToPreviousWeek,
  disableChangeToNextWeek,
  handleNextWeekTerm,
  handlePreviousWeekTerm,
}) => {
  const dispatch = useAppDispatch();
  const { onClickNext, onClickPrev } =
    useSelectPrevNextRealogram(comparedRealogram);
  const [isComparedImageLoading, setIsComparedImageLoading] = useState(false); //realogramImageが完全に読み込まれたことを確認するフラグ
  const [modalOpen, setModalOpen] = useState(false);

  const realogramIds = useMemo(() => {
    if (!shelfBoards) return [];
    return extractRealogramProductIds(shelfBoards);
  }, [shelfBoards]);

  const comparedIds = useMemo(() => {
    if (comparedPlanogram?.plan) {
      return extractPlanogramProductIds(comparedPlanogram.plan);
    }
    if (comparedShelfboards) {
      return extractRealogramProductIds(comparedShelfboards);
    }
    return [];
  }, [comparedPlanogram?.plan, comparedShelfboards]);

  useEffect(() => {
    const sameProductIds = findSameProductIds(realogramIds, comparedIds);
    dispatch(setComparisonSameIds(sameProductIds));
  }, [dispatch, comparedIds, realogramIds]);

  const [openDevFeatureDialog, setOpenDevFeatureDialog] = useState(false);
  const { handleOpenComparisonItemModal } = useComparisonItemModal();
  const {
    handleChangeProfit,
    storeAreaType,
    profit,
    isLoadingEstimate: isLoadingEstimateCandidate,
  } = useEstimatedProfit({ realogram: realogramCandidate });
  const {
    realogramEstimatedData: comparedRealogramEstimatedData,
    handleChangeProfit: handleChangeComparedProfit,
    storeAreaType: comparedStoreAreaType,
    profit: comparedProfit,
    planogramEstimatedData,
    isLoadingEstimate,
  } = useEstimatedProfit({
    realogram: comparedRealogram,
    planogram: comparedPlanogram,
  });

  const isComparedRealogramBucketType =
    comparedRealogram?.shot_type === 'split_bucket';
  return (
    <Box
      component="div"
      display="flex"
      flexDirection={isBucketType ? 'column-reverse' : 'row'}
      flex={1}
      width="calc(100% - 254px)"
    >
      <Box
        component="div"
        flex={1}
        display="flex"
        flexDirection="column"
        height={isBucketType ? '50%' : 'auto'}
        width={isBucketType ? '100%' : '50%'}
      >
        {comparedPlanogram || comparedRealogram ? (
          <SelectedItemCompare
            currentSelectedType={currentSelectedType}
            rateValue={rate}
            profitTab={profitTab}
            detailView={view}
            detailMode={mode}
            comparedPlanogram={comparedPlanogram}
            comparedRealogram={comparedRealogram}
            comparedProfit={comparedProfit}
            comparedRealogramEstimatedData={comparedRealogramEstimatedData}
            comparedStoreAreaType={comparedStoreAreaType}
            comparedShelfboards={comparedShelfboards}
            isComparedImageLoading={isComparedImageLoading}
            handleChangeComparedProfit={handleChangeComparedProfit}
            handleClickRealogramBbox={handleClickRealogramBbox}
            setIsComparedImageLoading={setIsComparedImageLoading}
            handleOpenComparisonItemModal={handleOpenComparisonItemModal}
            showDevFeatureDialog={() => {
              setOpenDevFeatureDialog(true);
            }}
            handleRecalculate={() => void 0}
            comparedPlanogramEstimatedData={planogramEstimatedData}
            handleClickOutItemCompareBbox={handleClickOutSideRealogramBbox}
            isBucketType={isBucketType}
            isComparedBucketType={isComparedRealogramBucketType}
            isLoadingEstimate={isLoadingEstimate}
            realogramCandidate={realogramCandidate}
            isTenantSalesAnalytics={isTenantSalesAnalytics}
            comparedAnalyticsData={comparedAnalyticsData}
            comparedAnalyticsDataTerm={comparedAnalyticsDataTerm}
            disableChangeToComparedPreviousWeek={
              disableChangeToComparedPreviousWeek
            }
            disableChangeToComparedNextWeek={disableChangeToComparedNextWeek}
            handleComparedNextWeekTerm={handleComparedNextWeekTerm}
            handleComparedPreviousWeekTerm={handleComparedPreviousWeekTerm}
            onClickNext={onClickNext}
            onClickPrev={onClickPrev}
            isLoading={isLoading}
          />
        ) : (
          <Box component="div" height="100%">
            {!isLoading && !comparedPlanogram && !comparedRealogram && (
              <SelectComparison
                emptyTopBoxSx={{
                  height: isBucketType
                    ? '50px'
                    : !isTenantSalesAnalytics
                    ? '44px'
                    : '94px',
                }}
                emptyBottomBoxSx={{ height: '56px' }}
                handleOpenModal={() =>
                  handleOpenComparisonItemModal({
                    defaultTab: ComparisonTabModal.REALOGRAM_TAB,
                    originRealogramId: realogramCandidate?.id,
                  })
                }
                isForbidden={isForbidden}
              />
            )}
          </Box>
        )}
      </Box>
      <Divider
        flexItem
        orientation={isBucketType ? 'horizontal' : 'vertical'}
      />
      <Box
        component="div"
        flex={1}
        display="flex"
        flexDirection="column"
        height={isBucketType ? '50%' : 'auto'}
        width={isBucketType ? '100%' : '50%'}
      >
        <ComparisonBayHeader
          headerStyleDirection={isBucketType ? 'row' : 'column'}
          name={storeBayName}
          bayName={realogramCandidate?.store_bay?.store?.name ?? '-'}
        >
          {isTenantSalesAnalytics && (
            <StatisticsValueSelector
              value={getDisplayValue(
                profit,
                report?.summary,
                t('gross_profit')
              )}
              profits={getProfitsMenu(t('gross_profit'))}
              pointers={pointersDummy}
              selectedProfitType={profit}
              selectedPointerType={storeAreaType}
              handleChangeProfitValue={handleChangeProfit}
              isPlans={false}
              category="実績"
              term={analyticsDataTerm}
              isLoading={isLoadingEstimateCandidate}
              analyticsDataTerm={analyticsDataTerm}
              disableChangeToPreviousWeek={disableChangeToPreviousWeek}
              disableChangeToNextWeek={disableChangeToNextWeek}
              handleNextWeekTerm={handleNextWeekTerm}
              handlePreviousWeekTerm={handlePreviousWeekTerm}
            />
          )}
        </ComparisonBayHeader>
        <Box
          component="div"
          display="flex"
          flexDirection="column"
          sx={{
            backgroundColor: theme.palette.backgroundBlack.black,
          }}
          flex={1}
          onClick={handleClickOutSideRealogramBbox}
        >
          <RealogramImageAndBbox
            realogramCandidateId={realogramCandidateId}
            view={view}
            mode={mode}
            shelfBoards={shelfBoards}
            handleClickBbox={handleClickRealogramBbox}
            createdAt={createdAt}
            rate={rate}
            profitTab={profitTab}
            productReports={productReports}
            imageIsLoading={isImageLoading}
            handleChangeLoading={handleChangeLoading}
            isBucketType={isBucketType}
            comparedProductReports={comparedProductReports}
          />
        </Box>
      </Box>
      <SearchModal open={modalOpen} handleClose={() => setModalOpen(false)} />
      <DevelopingFeatureDialog
        open={openDevFeatureDialog}
        handleCancel={() => setOpenDevFeatureDialog(false)}
      />
    </Box>
  );
};
