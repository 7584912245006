import { CircularSpinner } from '@components/molecules';
import { SelectComparison } from '@components/molecules/selectComparison/selectComparison';
import { DevelopingFeatureDialog } from '@components/organisms/developingFeatureDialog/developingFeatureDialog';
import { SearchModal } from '@components/organisms/searchModal/searchModal';
import { SelectedItemCompare } from '@components/organisms/selectedItemCompare/selectedItemCompare';
import { useComparisonItemModal } from '@hooks/useComparisonItemModal';
import { useEstimatedProfit } from '@hooks/useEstimatedProfit';
import { useFlatPlanogramPlan } from '@hooks/useFlatPlanogramPlan';
import { useUrlQueryParams } from '@hooks/useUrlQueryParams';
import { Box, Divider } from '@mui/material';
import { CurrentSelectedType } from '@reducers/comparisonItemModal';
import {
  updateProductPosition,
  updateSelectedProductCompartment,
} from '@reducers/planogramEditor/reducer';
import { selectPlanogramEditorState } from '@reducers/planogramEditor/selectors';
import { setComparisonSameIds } from '@reducers/product';
import { selectProductState } from '@reducers/product/selector';
import { updateSelectedRealogramItem } from '@reducers/realogramCandidate';
import { selectRealogramSelectedItem } from '@reducers/realogramCandidate/selector';
import { useAppDispatch, useAppSelector } from '@store/index';
import { ComparisonTabModal, getComparisonDiffColor } from '@utils/const';
import { Format, format } from '@utils/date';
import { getBucketsProductIds, isPlanogramBucketPlan } from '@utils/planogram';
import { isSelectedItem } from '@utils/realogram';
import { intersection } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { theme } from 'theme';
import { BucketPlanogramPlan, Planogram } from 'types/planogram';
import {
  RealogramCandidateContainCurrentRole,
  RealogramSelectedItem,
  RealogramShelfBoard,
} from 'types/realogram';
import { FlatPlanogramComparison } from './flatPlanogramComparison';
import { ProductSalesReport } from 'types/products';
import { useSelectPrevNextRealogram } from '@hooks/useSelectPrevNextRealogram';

type Props = {
  planogram?: Planogram;
  comparedPlanogram?: Planogram;
  comparedRealogram?: RealogramCandidateContainCurrentRole;
  isLoading?: boolean;
  currentSelectedType?: CurrentSelectedType;
  comparedShelfboards?: RealogramShelfBoard[];
  comparisonProductIds?: string;
  isForbidden?: boolean;
  isBucketType?: boolean;
  isTenantSalesAnalytics?: boolean;
  comparedAnalyticsData?: ProductSalesReport;
  comparedAnalyticsDataTerm?: string;
  disableChangeToComparedPreviousWeek?: boolean;
  disableChangeToComparedNextWeek?: boolean;
  handleComparedNextWeekTerm?: () => void;
  handleComparedPreviousWeekTerm?: () => void;
};

export const FlatPlanogramImages: FC<Props> = ({
  planogram,
  comparedPlanogram,
  comparedRealogram,
  isLoading,
  currentSelectedType,
  comparedShelfboards,
  comparisonProductIds,
  isForbidden = false,
  isBucketType = true,
  isTenantSalesAnalytics,
  comparedAnalyticsData,
  comparedAnalyticsDataTerm,
  disableChangeToComparedPreviousWeek,
  disableChangeToComparedNextWeek,
  handleComparedNextWeekTerm,
  handleComparedPreviousWeekTerm,
}) => {
  const dispatch = useAppDispatch();
  const { detailView, detailMode, profitTab, rateValue } = useAppSelector(
    selectPlanogramEditorState
  );
  const { comparisonSameIds } = useAppSelector(selectProductState);
  const selectedRealogramItem = useAppSelector(selectRealogramSelectedItem);
  const { updateBucketProductPosition } = useFlatPlanogramPlan();
  const { handleOpenComparisonItemModal } = useComparisonItemModal();
  const [isComparedImageLoading, setIsComparedImageLoading] = useState(false); // //Flag to ensure realogramImage is fully loaded
  const { updateQueryParameter, removeQueryParameter } = useUrlQueryParams();
  const [modalOpen, setModalOpen] = useState(false);
  const [openDevFeatureDialog, setOpenDevFeatureDialog] = useState(false);
  const showDevFeatureDialog = () => {
    setOpenDevFeatureDialog(true);
  };
  const {
    realogramEstimatedData,
    planogramEstimatedData: comparedPlanogramEstimatedData,
    handleChangeProfit,
    storeAreaType: comparedStoreAreaType,
    profit: comparedProfit,
    isLoadingEstimate,
  } = useEstimatedProfit({
    realogram: comparedRealogram,
    planogram: comparedPlanogram,
  });
  const { onClickNext, onClickPrev } =
    useSelectPrevNextRealogram(comparedRealogram);
  const handleClickRealogramBbox = (item: RealogramSelectedItem) => {
    updateBucketProductPosition(undefined);
    dispatch(updateProductPosition(undefined));
    dispatch(updateSelectedProductCompartment(undefined));

    if (isSelectedItem(detailView, item, selectedRealogramItem)) {
      dispatch(updateSelectedRealogramItem(undefined));
      removeQueryParameter('item');
      return;
    }
    dispatch(updateSelectedRealogramItem(item));
    updateQueryParameter('item', item.compartmentId);
  };

  useEffect(() => {
    if (!planogram || !comparisonProductIds) return;
    const source = comparisonProductIds.split(',').map(Number);
    const productIds = (
      planogram.plan as BucketPlanogramPlan
    ).frame.detail.buckets
      ?.flatMap((bucket) => getBucketsProductIds(bucket.detail.area))
      .sort();

    dispatch(setComparisonSameIds(intersection(productIds, source)));
  }, [dispatch, comparisonProductIds, planogram]);

  if (!planogram || !isPlanogramBucketPlan(planogram.plan)) {
    console.error('No planogram found to display');
    return <></>;
  }

  return (
    <Box component="div" display="flex" flex={1} height="100%">
      <Box
        component="div"
        flex={1}
        width="50%"
        display="flex"
        flexDirection="column"
      >
        {comparedPlanogram || comparedRealogram ? (
          <SelectedItemCompare
            currentSelectedType={currentSelectedType}
            comparedPlanogram={comparedPlanogram}
            comparedRealogram={comparedRealogram}
            comparedProfit={comparedProfit}
            rateValue={rateValue}
            profitTab={profitTab}
            comparedRealogramEstimatedData={realogramEstimatedData}
            comparedStoreAreaType={comparedStoreAreaType}
            comparedShelfboards={comparedShelfboards}
            detailView={detailView}
            detailMode={detailMode}
            comparisonSameIds={comparisonSameIds}
            isComparedImageLoading={isComparedImageLoading}
            handleChangeComparedProfit={handleChangeProfit}
            handleClickRealogramBbox={handleClickRealogramBbox}
            setIsComparedImageLoading={setIsComparedImageLoading}
            handleOpenComparisonItemModal={handleOpenComparisonItemModal}
            showDevFeatureDialog={showDevFeatureDialog}
            handleRecalculate={() => void 0}
            comparedPlanogramEstimatedData={comparedPlanogramEstimatedData}
            planogram={planogram}
            isComparedBucketType={
              comparedRealogram?.shot_type === 'split_bucket'
            }
            isBucketType={isBucketType}
            isLoadingEstimate={isLoadingEstimate}
            isTenantSalesAnalytics={isTenantSalesAnalytics}
            comparedAnalyticsData={comparedAnalyticsData}
            comparedAnalyticsDataTerm={comparedAnalyticsDataTerm}
            disableChangeToComparedPreviousWeek={
              disableChangeToComparedPreviousWeek
            }
            disableChangeToComparedNextWeek={disableChangeToComparedNextWeek}
            handleComparedNextWeekTerm={handleComparedNextWeekTerm}
            handleComparedPreviousWeekTerm={handleComparedPreviousWeekTerm}
            onClickNext={onClickNext}
            onClickPrev={onClickPrev}
          />
        ) : (
          <Box component="div" height="100%">
            {isLoading && (
              <CircularSpinner sx={{ display: 'flex', alignItems: 'center' }} />
            )}
            {!isLoading && (
              <SelectComparison
                emptyTopBoxSx={{ height: '43px' }}
                handleOpenModal={() =>
                  handleOpenComparisonItemModal({
                    defaultTab: ComparisonTabModal.PLANOGRAM_TAB,
                    originPlanogramId: planogram.id,
                  })
                }
                isForbidden={isForbidden}
              />
            )}
          </Box>
        )}
      </Box>
      <Divider orientation="vertical" flexItem />
      <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
        <FlatPlanogramComparison
          key={planogram.id}
          planogram={planogram}
          flatPlan={planogram.plan}
          detailMode={detailMode}
          detailView={detailView}
          bboxColors={{
            borderColor: theme.palette.beforeAfter.before,
            backgroundColor: theme.palette.beforeAfter.beforeOverlay,
          }}
          diffColor={getComparisonDiffColor(detailView)}
          backgroundColor={theme.palette.shelf.backgroundTana}
          createdAt={format(planogram.created_at, Format.datetimeWithWeekday)}
          isBucketType={isBucketType}
          isTenantSalesAnalytics={isTenantSalesAnalytics}
        />
      </DndProvider>
      <SearchModal open={modalOpen} handleClose={() => setModalOpen(false)} />
      <DevelopingFeatureDialog
        open={openDevFeatureDialog}
        handleCancel={() => setOpenDevFeatureDialog(false)}
      />
    </Box>
  );
};
