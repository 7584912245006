import { PasswordInput } from '@components/organisms';
import {
  AuthAppAddition,
  AuthAppInstallation,
  Initial,
  MfaCompleted,
  OneTimePassword,
} from '@components/pages/mfa/fragments';
import { selectAccessToken } from '@reducers/auth/selectors';
import {
  useInitializeMfaMutation,
  useSignInMutation,
} from '@reducers/shelfAppsApi';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { useAppSelector } from '@store/index';
import httpStatus from 'http-status';
import { FC, useState } from 'react';
import { isMfaRegistered as isMfaRegisteredFn } from 'utils/users';

type Step =
  | 'initial'
  | 'passwordInput'
  | 'authAppInstallation'
  | 'authAppAddition'
  | 'oneTimePassword'
  | 'mfaCompleted';

type Props = {
  email: string;
  isInitial: boolean;
};

export const MfaStep: FC<Props> = ({ email, isInitial }) => {
  const [step, setStep] = useState<Step>('initial');
  const accessToken = useAppSelector(selectAccessToken);
  const [
    initializeMfa,
    { error: initializeMfaError, isLoading: initializeIsLoading },
  ] = useInitializeMfaMutation();
  const [secretCode, setSecretCode] = useState<string>();
  const [passwordFailed, setPasswordFailed] = useState(false);
  const [isMfaRegistered, setIsMfaRegistered] = useState(false);
  const [signIn, { isLoading: signInIsLoading }] = useSignInMutation();

  const handleChangeSecretCode = (value: string) => {
    setSecretCode(value);
  };

  const handleEmailPasswordLogin = async (password: string) => {
    setPasswordFailed(false);
    try {
      const result = await signIn({
        username: email,
        password,
        //TODO: 定数化すべきか
        authFlow: 'USER_PASSWORD_AUTH',
      }).unwrap();
      setStep('authAppInstallation');
      if (isMfaRegisteredFn(result)) {
        //NOTE: MFAを登録しているユーザーがAuthenticatorアプリのインストール画面に行くとエラーになる
        setIsMfaRegistered(true);
      }
    } catch (err) {
      console.log({ err });
      setPasswordFailed(true);
    }
  };

  const handleInitializeMfa = async () => {
    try {
      const { secret_code: secretCode } = await initializeMfa({
        accessToken,
      }).unwrap();
      handleChangeSecretCode(secretCode);
      setStep('authAppAddition');
    } catch (err) {
      console.error(err);
    }
  };

  switch (step) {
    case 'initial':
      return (
        <Initial next={() => setStep('passwordInput')} isInitial={isInitial} />
      );
    case 'passwordInput':
      return (
        <PasswordInput
          passwordFailed={passwordFailed}
          isLoading={signInIsLoading}
          next={handleEmailPasswordLogin}
          isInitial={isInitial}
        />
      );
    case 'authAppInstallation':
      return (
        <AuthAppInstallation
          isLoading={initializeIsLoading}
          next={async () => {
            await handleInitializeMfa();
          }}
          errorMessage={
            initializeMfaError &&
            toInitializeMfaErrorMessage(initializeMfaError, isMfaRegistered)
          }
          isInitial={isInitial}
        />
      );
    case 'authAppAddition':
      return (
        <AuthAppAddition
          secretCode={secretCode}
          next={() => setStep('oneTimePassword')}
          isInitial={isInitial}
        />
      );
    case 'oneTimePassword':
      return (
        <OneTimePassword
          initializeIsLoading={initializeIsLoading}
          next={() => setStep('mfaCompleted')}
          prev={async () => {
            await handleInitializeMfa();
          }}
        />
      );
    case 'mfaCompleted':
      return <MfaCompleted />;
  }
};

const toInitializeMfaErrorMessage = (
  error: FetchBaseQueryError | SerializedError,
  isMfaRegistered: boolean
) => {
  switch ('status' in error && error.status) {
    case httpStatus.UNAUTHORIZED:
      if (isMfaRegistered) {
        return 'このアカウントは既に二要素認証登録済みです。お手数ですが、「キャンセル」ボタンからログイン画面に戻ってください。ログイン後、アプリを使用できます。';
      }
      break;
    // Todo: defaultのエラーメッセージ文を検討する。
    default:
      return 'エラーが発生しました。';
  }
};
