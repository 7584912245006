import { Box, Button, Typography } from '@mui/material';
import { FC } from 'react';
import { theme } from 'theme';
import Image from 'next/legacy/image';
import { StepFiveInfo } from 'types/realogram';
import { useImagePreview } from '../../hooks/useImagePreview';
import { paths } from '@utils/const';

type Props = {
  isFlat: boolean;
  stepFiveInfo?: StepFiveInfo;
  handleNavigate: (nextPath: string) => void;
  isXs?: boolean;
};

export const RealogramDetail: FC<Props> = ({
  isFlat,
  stepFiveInfo,
  handleNavigate,
  isXs = false,
}) => {
  const { src: firstFileSrc } = useImagePreview(stepFiveInfo?.firstFile);
  const { src: secondFileSrc } = useImagePreview(stepFiveInfo?.secondFile);
  return (
    <Box
      component="div"
      display="flex"
      flexDirection="row"
      justifyContent="space-around"
      alignItems="center"
      p={3}
      gap={3}
      sx={{
        backgroundColor: theme.palette.backgroundBlack.primary,
        flexWrap: {
          xs: 'wrap',
          sm: 'nowrap',
        },
      }}
    >
      {firstFileSrc && (
        <Box component="div" display="flex" flexDirection="row" gap="4px">
          <Image
            src={firstFileSrc}
            alt=""
            width={180}
            height={240}
            objectFit="contain"
          />
          {isFlat && secondFileSrc && (
            <Image
              src={secondFileSrc}
              alt=""
              width={180}
              height={240}
              objectFit="contain"
            />
          )}
        </Box>
      )}
      <Box
        component="div"
        gap={4}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        width={{
          xs: '100%',
          sm: 'auto',
        }}
      >
        <Box
          component="div"
          gap="12px"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box
            component="div"
            gap={2}
            display="flex"
            justifyContent="flex-start"
          >
            <Typography variant="subtitle2" width="85px">
              店舗
            </Typography>
            <Typography variant="body2">{stepFiveInfo?.storeName}</Typography>
          </Box>
          <Box
            component="div"
            gap={2}
            display="flex"
            justifyContent="flex-start"
          >
            <Typography variant="subtitle2" width="85px">
              商品カテゴリ
            </Typography>
            <Typography variant="body2">
              {stepFiveInfo?.storeBayName}
            </Typography>
          </Box>
          {stepFiveInfo?.bayPlanName && (
            <Box
              component="div"
              gap={2}
              display="flex"
              justifyContent="flex-start"
            >
              <Typography variant="subtitle2" width="85px">
                ゴンドラ番号
              </Typography>
              <Typography variant="body2">
                {stepFiveInfo?.bayPlanName}
              </Typography>
            </Box>
          )}
        </Box>
        {!isXs && (
          <Box component="div" mx="auto">
            <Button
              sx={{
                height: '48px',
                borderRadius: '4px',
                border: `1px solid ${theme.palette.dividerBlack.dark}`,
                px: '20px',
                gap: 1,
                backgroundColor: theme.palette.white.primary,
                color: theme.palette.textBlack.primary,
                fontWeight: 700,
                fontSize: '16px',
                lineHeight: '16px',
              }}
              onClick={() =>
                handleNavigate(paths.actuals.id(String(stepFiveInfo?.id)))
              }
            >
              詳細を見る
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};
