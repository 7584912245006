import { StyledIconButton } from '@components/molecules/faceAreaControllerBox/fragments/createdAt';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { theme } from 'theme';

type Props = {
  disableChangeToNextWeek?: boolean;
  disableChangeToPreviousWeek?: boolean;
  term?: string;
  handleNextWeekTerm?: () => void;
  handlePreviousWeekTerm?: () => void;
};

export const WeekSwitcher: FC<Props> = ({
  disableChangeToNextWeek,
  disableChangeToPreviousWeek,
  term,
  handleNextWeekTerm,
  handlePreviousWeekTerm,
}) => {
  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <StyledIconButton
        disabled={disableChangeToPreviousWeek}
        onClick={handlePreviousWeekTerm}
      >
        <KeyboardArrowLeft fontSize="small" />
      </StyledIconButton>
      <Typography
        variant="caption1"
        color={theme.palette.textBlack.primary}
        width="100%"
        textAlign="center"
      >
        {term}
      </Typography>
      <StyledIconButton
        disabled={disableChangeToNextWeek}
        onClick={handleNextWeekTerm}
      >
        <KeyboardArrowRight fontSize="small" />
      </StyledIconButton>
    </Box>
  );
};
