import { CircularSpinner } from '@components/molecules';
import { headerHeight } from '@components/organisms/header/header';
import { SearchModal } from '@components/organisms/searchModal/searchModal';
import { PlanogramImages } from '@components/pages/planogramEditor/fragments/planogramImages';
import { PreviewDragLayer } from '@components/pages/planogramEditor/fragments/previewDragLayer';
import { RightSideArea } from '@components/pages/planogramEditor/fragments/rightSideArea';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { useComparisonItemModal } from '@hooks/useComparisonItemModal';
import { useExtractRealogramData } from '@hooks/useExtractRealogramData';
import { useZoomController } from '@hooks/useZoomController';
import { useUpdateUrlQueryParamsOfDetailPages } from '@hooks/useUpdateUrlQueryParamsOfDetailPages';
import { Box, Divider } from '@mui/material';
import {
  CurrentSelectedType,
  setCurrentTab,
  updateCurrentSelectedItemId,
  updateCurrentSelectedType,
  updateSelectedItemId,
} from '@reducers/comparisonItemModal';
import { selectComparisonItemModal } from '@reducers/comparisonItemModal/selectors';
import {
  updateProductPosition,
  updateSelectedProductCompartment,
} from '@reducers/planogramEditor/reducer';
import { selectPlanogramEditorState } from '@reducers/planogramEditor/selectors';
import { updateSelectedRealogramItem } from '@reducers/realogramCandidate';
import { selectRealogramSelectedItem } from '@reducers/realogramCandidate/selector';
import {
  EstimateForPlanogramResponse,
  useListProductsBulkQuery,
} from '@reducers/shelfAppsApi';
import { useAppDispatch, useAppSelector } from '@store/index';
import { ComparisonTabModal, fullHeight } from '@utils/const';
import { getProductsLayout } from '@utils/planogram';
import { isInferredAsProduct } from '@utils/product';
import { isSelectedItem } from '@utils/realogram';
import { FC, useEffect, useMemo, useState } from 'react';
import { Planogram, PlanogramPlan } from 'types/planogram';
import { RealogramSelectedItem } from '../../../../types/realogram';
import { selectModalProps } from '@reducers/modal/selector';
import { useInitSelectedPlanogram } from '@hooks/useInitSelectedPlanogram';
import { useRealogramAnalyticsData } from '@hooks/useRealogramAnalyticsData';

type Props = {
  planogram?: Planogram;
  plan: PlanogramPlan;
  handleMoveDownBayPart: () => void;
  handleMoveUpBayPart: () => void;
  step?: number;
  handleClickAway: () => void;
  handleRecalculate: () => void;
  planogramEstimatedData?: EstimateForPlanogramResponse;
  isBucketType?: boolean;
  isTenantSalesAnalytics?: boolean;
  initRerenderingSelectedProduct: boolean;
  setInitRerenderingSelectedProduct: (value: boolean) => void;
  compareQueryParams?: {
    id: number;
    type: CurrentSelectedType;
  };
};

export const ComparisonPlanogram: FC<Props> = ({
  planogram,
  step,
  handleMoveUpBayPart,
  handleMoveDownBayPart,
  plan,
  handleClickAway,
  handleRecalculate,
  planogramEstimatedData,
  isBucketType,
  isTenantSalesAnalytics,
  initRerenderingSelectedProduct,
  setInitRerenderingSelectedProduct,
  compareQueryParams,
}) => {
  const dispatch = useAppDispatch();
  const { open: isOpenModal } = useAppSelector(selectModalProps);
  const { isLarger } = useBreakpoint();
  const {
    detailView: view,
    detailMode,
    productTag,
    rateValue,
    profitTab,
  } = useAppSelector(selectPlanogramEditorState);
  const { updateModeQueryParams } = useUpdateUrlQueryParamsOfDetailPages();
  const selectedRealogramItem = useAppSelector(selectRealogramSelectedItem);
  const { currentSelectedItemId, currentSelectedType } = useAppSelector(
    selectComparisonItemModal
  );
  const { zoomScale } = useZoomController();
  const handleClickPlanogramBbox = (item: RealogramSelectedItem) => {
    dispatch(updateProductPosition(undefined));
    dispatch(updateSelectedProductCompartment(undefined));
    if (isSelectedItem(view, item, selectedRealogramItem)) {
      dispatch(updateSelectedRealogramItem(undefined));
      return;
    }
    dispatch(updateSelectedRealogramItem(item));
  };
  const [modalOpen, setModalOpen] = useState(false);
  const originRealogramCandidateId =
    planogram?.link?.origin_realogram_candidate_id;
  const originPlanogramId = planogram?.link?.origin_planogram_id;

  const {
    isLoading,
    comparedPlanogram,
    fetchGetPlanogram,
    comparedRealogram,
    isForbidden,
  } = useComparisonItemModal(
    currentSelectedType === 'actual' ||
      (originRealogramCandidateId && !currentSelectedItemId?.toString())
      ? 'actual'
      : ''
  );

  useEffect(() => {
    if (originRealogramCandidateId && !currentSelectedItemId?.toString()) {
      dispatch(updateSelectedItemId(originRealogramCandidateId));
      dispatch(updateCurrentSelectedItemId(originRealogramCandidateId));
      dispatch(updateCurrentSelectedType('actual'));
      dispatch(setCurrentTab(ComparisonTabModal.REALOGRAM_TAB));
      updateModeQueryParams(
        detailMode,
        view,
        productTag,
        profitTab,
        rateValue,
        {
          selectedItem: `actual-${originRealogramCandidateId}`,
        }
      );
      void fetchGetPlanogram(originRealogramCandidateId, 'actual');
    } else if (originPlanogramId && !currentSelectedItemId?.toString()) {
      dispatch(updateSelectedItemId(originPlanogramId));
      dispatch(updateCurrentSelectedItemId(originPlanogramId));
      dispatch(updateCurrentSelectedType('plan'));
      dispatch(setCurrentTab(ComparisonTabModal.PLANOGRAM_TAB));
      updateModeQueryParams(
        detailMode,
        view,
        productTag,
        profitTab,
        rateValue,
        {
          selectedItem: `plan-${originPlanogramId}`,
        }
      );
      void fetchGetPlanogram(originPlanogramId, 'plan');
    } else if (currentSelectedItemId?.toString() && currentSelectedType) {
      void fetchGetPlanogram(currentSelectedItemId, currentSelectedType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- updateModeQueryParams only updates queryParams the first time the page loads, Avoid falling into an infinite loop
  }, [
    currentSelectedItemId,
    currentSelectedType,
    detailMode,
    dispatch,
    fetchGetPlanogram,
    originPlanogramId,
    originRealogramCandidateId,
    productTag,
    profitTab,
    rateValue,
    view,
  ]);

  useEffect(() => {
    const { id: queryId, type: queryType } = compareQueryParams || {};
    const shouldSkip = !queryId || !queryType || isOpenModal;
    if (shouldSkip) return;
    if (queryType === 'plan') {
      dispatch(setCurrentTab(ComparisonTabModal.PLANOGRAM_TAB));
    } else if (queryType === 'actual') {
      dispatch(setCurrentTab(ComparisonTabModal.REALOGRAM_TAB));
    }
    dispatch(updateSelectedItemId(queryId));
    dispatch(updateCurrentSelectedItemId(compareQueryParams?.id));
    dispatch(updateCurrentSelectedType(compareQueryParams?.type));
  }, [compareQueryParams, dispatch, isOpenModal]);

  const {
    analyticsDataTerm: comparedAnalyticsDataTerm,
    displayAnalyticsData: displayComparedAnalyticsData,
    isLoadingAnalyticsData,
    disableChangeToPreviousWeek: disableChangeToComparedPreviousWeek,
    disableChangeToNextWeek: disableChangeToComparedNextWeek,
    handleNextWeekTerm: handleComparedNextWeekTerm,
    handlePreviousWeekTerm: handleComparedPreviousWeekTerm,
  } = useRealogramAnalyticsData(
    comparedRealogram?.id ?? 0,
    !comparedRealogram || !isTenantSalesAnalytics || comparedRealogram?.id === 0
  );

  const { filteredShelfBoards: comparedShelfBoards } = useExtractRealogramData(
    view,
    productTag,
    displayComparedAnalyticsData?.products ?? [],
    'スコア',
    comparedRealogram?.detail?.products_shelves.shelf_boards
  );

  const comparisonProductIds = useMemo(() => {
    if (comparedPlanogram) {
      return (
        comparedPlanogram?.plan ? getProductsLayout(comparedPlanogram.plan) : []
      )
        .flatMap(({ row }) => row.map(({ product_id }) => product_id))
        .join(',');
    }
    if (comparedShelfBoards) {
      return (comparedShelfBoards ?? [])
        ?.flatMap((shelfboard) =>
          shelfboard.compartments.flatMap((compartment) =>
            compartment.faces
              .filter((face) => isInferredAsProduct(face))
              .map((face) => face.primary_candidate?.product_id)
          )
        )
        .join(',');
    }
    return '';
  }, [comparedPlanogram, comparedShelfBoards]);

  const { data: listProductsData } = useListProductsBulkQuery(
    {
      productIds: comparisonProductIds,
      shape: true,
    },
    {
      skip: !comparedShelfBoards || !comparisonProductIds,
    }
  );

  const products = listProductsData?.products || [];

  useInitSelectedPlanogram({
    shelfBoards: comparedShelfBoards,
    planogramPlan: plan,
    comparedPlanogramPlan: comparedPlanogram?.plan as PlanogramPlan,
    isSkipUpdateBucket: false,
    initSelectedData: {
      isEditMode: true,
      isSkipInit:
        !plan.products_layout?.length ||
        initRerenderingSelectedProduct ||
        isLoading ||
        isLoadingAnalyticsData,
      view,
      productTag,
      onInitCompleted: setInitRerenderingSelectedProduct,
    },
  });
  return (
    <>
      <Divider orientation="horizontal" flexItem />
      <Box
        component="div"
        display="flex"
        flexDirection={{ xs: 'column', breakpoint: 'row' }}
        height={`calc(${fullHeight} - ${headerHeight}px)`}
        overflow="hidden"
      >
        <Box
          component="div"
          flex={{ xs: 'unset', breakpoint: 1 }}
          height={{
            xs: `calc(${fullHeight} - ${headerHeight}px - 170px)`,
            breakpoint: `calc(${fullHeight} - ${headerHeight}px)`,
          }}
        >
          <PlanogramImages
            isLoading={isLoading}
            handleClickAway={handleClickAway}
            planogram={planogram}
            plan={plan}
            handleClickBbox={handleClickPlanogramBbox}
            step={step}
            handleMoveUpBayPart={handleMoveUpBayPart}
            handleMoveDownBayPart={handleMoveDownBayPart}
            comparedPlanogram={comparedPlanogram}
            comparedRealogram={comparedRealogram}
            currentSelectedType={currentSelectedType}
            comparedShelfboards={comparedShelfBoards}
            comparisonProductIds={comparisonProductIds}
            handleRecalculate={handleRecalculate}
            planogramEstimatedData={planogramEstimatedData}
            isForbidden={isForbidden}
            isBucketType={isBucketType}
            isTenantSalesAnalytics={isTenantSalesAnalytics}
            products={products}
            comparedAnalyticsData={displayComparedAnalyticsData}
            comparedAnalyticsDataTerm={comparedAnalyticsDataTerm}
            disableChangeToComparedPreviousWeek={
              disableChangeToComparedPreviousWeek
            }
            disableChangeToComparedNextWeek={disableChangeToComparedNextWeek}
            handleComparedNextWeekTerm={handleComparedNextWeekTerm}
            handleComparedPreviousWeekTerm={handleComparedPreviousWeekTerm}
          />
        </Box>
        <Divider orientation={isLarger ? 'vertical' : 'horizontal'} flexItem />
        <Box
          component="div"
          p={2}
          minWidth={{ xs: 'unset', breakpoint: '254px' }}
          maxWidth={{ xs: 'unset', breakpoint: '254px' }}
          height="calc(100% + 15px)"
          position="relative"
          overflow="hidden"
          marginTop="-15px"
        >
          <Box component="div" height="100%">
            {isLoading ? (
              <CircularSpinner sx={{ display: 'flex', alignItems: 'center' }} />
            ) : (
              <RightSideArea bayPlanId={planogram?.bay_plan_id ?? 0} />
            )}
          </Box>
        </Box>
        <PreviewDragLayer detailMode={detailMode} scale={zoomScale} />
      </Box>
      <SearchModal open={modalOpen} handleClose={() => setModalOpen(false)} />
    </>
  );
};
