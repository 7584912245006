import { CreatePlanogramModalProps } from '@components/organisms';
import { updateLoadingIndicatorState } from '@reducers/loadingIndicator';
import { closeModal } from '@reducers/modal';
import {
  setPlanogramsPageNumber,
  setSelectedItemIndex,
} from '@reducers/planogram';
import { useCreatePlanogramMutation } from '@reducers/shelfAppsApi';
import { openToast } from '@reducers/toast';
import { useAppDispatch, useAppSelector } from '@store/index';
import { paths, toastMessages } from '@utils/const';
import { createPlanogram as toPlanogram } from '@utils/planogram';
import { useNavigate, useParams } from 'react-router-dom';
import { useBayPlanApi } from './useBayPlanApi';
import { useGetPlanogramPermission } from './useGetPlanogramPermission';
import { useModal } from './useModal';

export const useCreatePlanogram = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isViewFolder, planogramDirectoryParent } = useAppSelector(
    (state) => state.SharePermission
  );
  const { isNotEnable: isCanNotCreate } = useGetPlanogramPermission({
    action: 'create',
    planogram: planogramDirectoryParent,
    isPlanogram: false,
    isCan: false,
  });
  const params = useParams();
  const currentDirectoryId = params.id;
  const [createPlanogram] = useCreatePlanogramMutation();
  const { fetchBayPlan } = useBayPlanApi();
  const { showModal: showCreatePlanogramModal } =
    useModal<CreatePlanogramModalProps>('createPlanogram', '新規棚割');

  const handleCreatePlanogram = () => {
    if (isViewFolder && isCanNotCreate) {
      dispatch(
        openToast({
          message: toastMessages.error.notPermittedCreatePlanogram,
          type: 'error',
        })
      );

      return;
    }
    showCreatePlanogramModal({
      onSubmit: async ({ name, bayPlanId, bayPlanCodeId }) => {
        dispatch(updateLoadingIndicatorState(true));
        try {
          const bayPlan = await fetchBayPlan(bayPlanId);
          const planogram = toPlanogram(bayPlan, name, bayPlanCodeId);
          const { planogram: newPlanogram } = await createPlanogram({
            ...planogram,
            bay_plan_id: bayPlanId,
            name,
            bay_plan_code_id: bayPlanCodeId,
            directory_parent_id: currentDirectoryId,
          }).unwrap();
          dispatch(closeModal());
          dispatch(setPlanogramsPageNumber(1));
          dispatch(setSelectedItemIndex(0));
          navigate(paths.plans.edit(newPlanogram.id));
        } catch (e) {
          console.error(e);
          dispatch(
            openToast({
              message: toastMessages.error.createPlanogram,
              type: 'error',
            })
          );
        } finally {
          dispatch(updateLoadingIndicatorState(false));
        }
      },
    });
  };

  return handleCreatePlanogram;
};
