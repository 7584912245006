import { Button } from '@mui/material';
import { paths } from '@utils/const';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
  isInitial: boolean;
};

export const CancelButton: FC<Props> = ({ isInitial }) => {
  const navigate = useNavigate();

  return (
    <Button
      fullWidth
      onClick={() => navigate(isInitial ? paths.login : paths.setting)}
    >
      キャンセル
    </Button>
  );
};
