import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { productTags, rateValues, profitTabSales } from '@utils/const';
import { isEqual } from 'lodash';
import {
  Bucket,
  BucketMode,
  PlanogramBayPart,
  Position,
  ProductPosition,
} from 'types/planogram';
import {
  Mode,
  Product,
  ProductTag,
  ProfitTab,
  Rate,
  ShelfDetailMode,
  ShelfDetailView,
} from '../../types/common';

type FlatOverflows = {
  bucketIndex: number;
  areaPath: Position[];
  overflow: boolean;
};

// TODO:平置きゴンドラ用と通常用が混同しているため、コンポーネントの分離含めて再検討する。
type PlanogramEditorState = {
  mode: Mode;
  detailView: ShelfDetailView;
  detailMode: ShelfDetailMode;
  productTag: ProductTag;
  tags: string[];
  profitTab: ProfitTab;
  rateValue: Rate;
  overflows: boolean[];
  selectedProductCompartment?: Product;
  selectedProductCompartmentClone?: Product;
  productPosition?: ProductPosition;
  isShowProductDetail: boolean;
  isShowProductDetailEditor: boolean;
  isShowBayPartDetail: boolean;
  selectedBayPartId?: number;
  openOrientationMenu: boolean;
  searchWord: string;
  productsListScrollPos: number;
  bucketMode: BucketMode;
  selectedBucketId?: number;
  rotateDeg: number;
  rotateDegInCompare: number;
  isTooltipOpen: boolean;
  selectedBucketIdClone?: number | undefined;
  isDragProduct?: boolean;
  flatOverflows: FlatOverflows[];
  bucketsExceed: boolean[];
  isSwappingBayPartMode?: boolean;
  initialBayPart?: PlanogramBayPart;
  initialBucket?: Bucket;
  isRotateTransitionFlatPlanogram: boolean;
};

const initialState: PlanogramEditorState = {
  mode: 'Viewer',
  detailMode: 'default',
  detailView: 'default',
  productTag: productTags[0],
  tags: [],
  profitTab: profitTabSales,
  rateValue: rateValues[0],
  overflows: [],
  isShowProductDetail: false,
  isShowProductDetailEditor: false,
  isShowBayPartDetail: false,
  openOrientationMenu: false,
  searchWord: '',
  productsListScrollPos: 0,
  bucketMode: 'compartment',
  rotateDeg: 0,
  rotateDegInCompare: 0,
  isTooltipOpen: false,
  isDragProduct: false,
  flatOverflows: [],
  bucketsExceed: [],
  isSwappingBayPartMode: false,
  isRotateTransitionFlatPlanogram: false,
};

const planogramEditorSlice = createSlice({
  name: 'planogramEditor',
  initialState,
  reducers: {
    changeEditorMode: (
      state: PlanogramEditorState,
      action: PayloadAction<Mode>
    ) => {
      state.mode = action.payload;
    },
    updateOverflowState: (
      state: PlanogramEditorState,
      action: PayloadAction<{ rowIndex: number; overflow: boolean }>
    ) => {
      const { rowIndex, overflow } = action.payload;
      state.overflows[rowIndex] = overflow;
    },
    updateFlatOverflowState: (
      state: PlanogramEditorState,
      action: PayloadAction<FlatOverflows & { isDelete?: boolean }>
    ) => {
      const { bucketIndex, areaPath, isDelete } = action.payload;
      const targetComparment = state.flatOverflows.find((el) => {
        return el.bucketIndex === bucketIndex && isEqual(el.areaPath, areaPath);
      });
      if (isDelete && targetComparment) {
        state.flatOverflows = state.flatOverflows.filter(
          (_, i) => i !== state.flatOverflows.indexOf(targetComparment)
        );
        return;
      }

      if (targetComparment) {
        const index = state.flatOverflows.indexOf(targetComparment);
        state.flatOverflows[index] = action.payload;
      } else {
        state.flatOverflows.push(action.payload);
      }
    },
    updateBucketsExceed: (
      state: PlanogramEditorState,
      action: PayloadAction<{ bucketIndex: number; isExceedSize: boolean }>
    ) => {
      const { bucketIndex, isExceedSize } = action.payload;
      state.bucketsExceed[bucketIndex] = isExceedSize;
    },
    forget: (state: PlanogramEditorState) => {
      state.mode = initialState.mode;
      state.detailView = 'default';
      state.detailMode = 'default';
      state.productTag = productTags[0];
      state.profitTab = profitTabSales;
      state.rateValue = rateValues[0];
      state.selectedBayPartId = undefined;
      state.overflows = [];
      state.productPosition = undefined;
      state.selectedProductCompartment = undefined;
      state.tags = [];
      state.openOrientationMenu = false;
      state.searchWord = '';
      state.productsListScrollPos = 0;
      state.isTooltipOpen = false;
      state.rotateDeg = 0;
      state.selectedBucketId = undefined;
      state.rotateDegInCompare = 0;
      state.flatOverflows = [];
      state.initialBayPart = undefined;
      state.isSwappingBayPartMode = false;
      state.bucketsExceed = [];
      state.isRotateTransitionFlatPlanogram = false;
    },
    updateSelectedProductCompartment: (
      state: PlanogramEditorState,
      action: PayloadAction<Product | undefined>
    ) => {
      state.selectedProductCompartment = action.payload;
    },
    // To save the old value of selectedProductCompartment to the active product selected
    updateSelectedProductCompartmentClone: (
      state: PlanogramEditorState,
      action: PayloadAction<Product | undefined>
    ) => {
      state.selectedProductCompartmentClone = action.payload;
    },
    updateIsShowProductDetail: (
      state: PlanogramEditorState,
      action: PayloadAction<boolean>
    ) => {
      state.isShowProductDetail = action.payload;
    },
    updateProductPosition: (
      state: PlanogramEditorState,
      action: PayloadAction<ProductPosition | undefined>
    ) => {
      state.productPosition = action.payload;
    },
    updateDetailView: (
      state: PlanogramEditorState,
      action: PayloadAction<ShelfDetailView>
    ) => {
      state.detailView = action.payload;
    },
    updateDetailMode: (
      state: PlanogramEditorState,
      action: PayloadAction<ShelfDetailMode>
    ) => {
      state.detailMode = action.payload;
    },
    updateProductTagTab: (
      state: PlanogramEditorState,
      action: PayloadAction<ProductTag>
    ) => {
      state.productTag = action.payload;
    },
    updateProfitTab: (
      state: PlanogramEditorState,
      action: PayloadAction<ProfitTab>
    ) => {
      state.profitTab = action.payload;
    },
    updateRateValue: (
      state: PlanogramEditorState,
      action: PayloadAction<Rate>
    ) => {
      state.rateValue = action.payload;
    },
    updateIsShowBayPartDetail: (
      state: PlanogramEditorState,
      action: PayloadAction<boolean>
    ) => {
      state.isShowBayPartDetail = action.payload;
    },
    selectBayPartId: (
      state: PlanogramEditorState,
      action: PayloadAction<number | undefined>
    ) => {
      state.selectedBayPartId = action.payload;
    },
    setTags: (state: PlanogramEditorState, action: PayloadAction<string[]>) => {
      state.tags = action.payload;
    },
    setOpenOrientationMenu: (
      state: PlanogramEditorState,
      action: PayloadAction<boolean>
    ) => {
      state.openOrientationMenu = action.payload;
    },
    updateSearchWord: (
      state: PlanogramEditorState,
      action: PayloadAction<string>
    ) => {
      state.searchWord = action.payload;
    },
    updateProductsListScrollPos: (
      state: PlanogramEditorState,
      action: PayloadAction<number>
    ) => {
      state.productsListScrollPos = action.payload;
    },
    // 平置きゴンドラ用 // For BucketPlanogram
    updateBucketMode: (
      state: PlanogramEditorState,
      action: PayloadAction<BucketMode>
    ) => {
      state.bucketMode = action.payload;
    },
    setSelectedBucketId: (
      state: PlanogramEditorState,
      action: PayloadAction<number | undefined>
    ) => {
      state.selectedBucketId = action.payload;
    },
    // To save the old value of selectedBucketId to the active bucket selected
    setSelectedBucketIdClone: (
      state: PlanogramEditorState,
      action: PayloadAction<number | undefined>
    ) => {
      state.selectedBucketIdClone = action.payload;
    },
    rotateGondola: (
      state: PlanogramEditorState,
      action: PayloadAction<number>
    ) => {
      state.rotateDeg = action.payload;
    },
    rotateGondolaInCompare: (
      state: PlanogramEditorState,
      action: PayloadAction<number>
    ) => {
      state.rotateDegInCompare = action.payload;
    },
    setIsTooltipOpen: (
      state: PlanogramEditorState,
      action: PayloadAction<boolean>
    ) => {
      state.isTooltipOpen = action.payload;
    },
    updateIsDragProduct: (
      state: PlanogramEditorState,
      action: PayloadAction<boolean>
    ) => {
      state.isDragProduct = action.payload;
    },
    toggleIsSwappingBayPartMode: (state: PlanogramEditorState) => {
      state.isSwappingBayPartMode = !state.isSwappingBayPartMode;
    },
    updateInitialBayPart: (
      state: PlanogramEditorState,
      action: PayloadAction<PlanogramBayPart | undefined>
    ) => {
      state.initialBayPart = action.payload;
    },
    updateInitialBucket: (
      state: PlanogramEditorState,
      action: PayloadAction<Bucket | undefined>
    ) => {
      state.initialBucket = action.payload;
    },
    changeIsRotateTransitionFlatPlanogram: (
      state: PlanogramEditorState,
      action: PayloadAction<boolean>
    ) => {
      state.isRotateTransitionFlatPlanogram = action.payload;
    },
  },
});

export const {
  changeEditorMode,
  updateOverflowState,
  forget,
  updateSelectedProductCompartment,
  updateIsShowProductDetail,
  updateProductPosition,
  updateDetailView,
  updateDetailMode,
  updateProductTagTab,
  updateProfitTab,
  updateRateValue,
  updateIsShowBayPartDetail,
  selectBayPartId,
  setTags,
  setOpenOrientationMenu,
  updateSearchWord,
  updateProductsListScrollPos,
  updateBucketMode,
  setSelectedBucketId,
  rotateGondola,
  setIsTooltipOpen,
  setSelectedBucketIdClone,
  updateSelectedProductCompartmentClone,
  rotateGondolaInCompare,
  updateIsDragProduct,
  updateFlatOverflowState,
  updateBucketsExceed,
  toggleIsSwappingBayPartMode,
  updateInitialBayPart,
  updateInitialBucket,
  changeIsRotateTransitionFlatPlanogram,
} = planogramEditorSlice.actions;
export const planogramEditorReducer = planogramEditorSlice.reducer;
