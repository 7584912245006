import { ErrorOutlined } from '@mui/icons-material';
import { Box, keyframes } from '@mui/material';
import { selectPlanogramEditorState } from '@reducers/planogramEditor/selectors';
import { selectProductState } from '@reducers/product/selector';
import { useAppSelector } from '@store/index';
import { withOpacity } from '@utils/colors';
import {
  changeSidesByFaceFront,
  convertMeterToPixel,
  orientationToDegree as getDegree,
  isClickableCompartment,
} from '@utils/planogram';
import { getProductDisplaySize, getProductSize } from '@utils/product';
import { range } from 'lodash';
import { FC, MouseEvent, useEffect } from 'react';
import {
  Product,
  ProductTag,
  ShelfDetailMode,
  ShelfDetailView,
} from 'types/common';
import { BboxColors, PlanogramProductCompartment } from 'types/planogram';
import { theme } from '../../../theme';
import { ProductImage } from '../productImage/productImage';
import { useFlatPlanogramPlan } from '@hooks/useFlatPlanogramPlan';

type Props = PlanogramProductCompartment & {
  rowIndex?: number;
  index?: number;
  isSelected: boolean;
  handleClickGroup: (e: MouseEvent<HTMLElement>) => void;
  isOverflown: boolean;
  product: Product;
  diffColor?: string; //TODO: propsで受け取るべきか要検討
  bboxColors: BboxColors;
  bboxEnabled: boolean; //TODO: propsで受け取るべきか要検討
  mode?: ShelfDetailMode;
  view?: ShelfDetailView;
  justifyMargin: number;
  margin?: number;
  isCompared?: boolean;
  lastIndex?: number;
  setIsAllChildRenndered?: (value: boolean) => void;
};
const border = 2;
const opacity = 0.2;
const profitOpacity = 0.5;
const productGroupBgOpacity = 0.4;

export const ProductsGroup: FC<Props> = ({
  count,
  orientation,
  face_front: faceFront,
  handleClickGroup,
  isSelected,
  isOverflown,
  product,
  diffColor,
  rowIndex = -1,
  index = -1,
  bboxColors,
  bboxEnabled,
  mode,
  view,
  justifyMargin,
  margin = 2,
  isCompared = false,
  lastIndex,
  setIsAllChildRenndered,
}) => {
  const {
    mode: editorMode,
    selectedBucketId,
    bucketMode,
    productTag,
    isDragProduct,
    selectedBucketIdClone,
  } = useAppSelector(selectPlanogramEditorState);
  const { comparisonSameIds } = useAppSelector(selectProductState);
  const { bucketProductPosition } = useFlatPlanogramPlan();
  const isDecreaseOpacity =
    (bucketProductPosition || selectedBucketId || selectedBucketIdClone) &&
    bucketMode === 'area';
  const { x: countX, y: countY } = count ?? { x: 0, y: 0 };
  const size = getProductSize(product);
  const faceFrontSize = changeSidesByFaceFront(size, faceFront);
  const displaySize = getProductDisplaySize(product, faceFront, orientation);
  const isProductFlagView = view === 'productFlag';
  const isAnalyticsView = view === 'profit';
  const hasTargetTag = product.detail?.tags?.includes(productTag);
  const isSkippingColorChange =
    (isProductFlagView && !hasTargetTag) || isAnalyticsView;
  const isPreviewMode = editorMode === 'preview';
  const isEditorMode = editorMode === 'CompartmentEditor';
  const isComparisonCommonId = comparisonSameIds.includes(product.id);
  const { borderColor, bgColor } = getActivityColors(
    isOverflown,
    isSelected,
    bboxColors,
    !isPreviewMode && bboxEnabled,
    isComparisonCommonId,
    isSkippingColorChange,
    diffColor,
    view,
    mode,
    isEditorMode,
    isDragProduct
  );
  const blink = createKeyframes(theme.palette.white.primary);
  const productImageElement = (
    <Box
      component="div"
      data-mark="product-image"
      sx={{
        transform: `rotate(${getDegree(orientation)}deg)`,
      }}
      left="50%"
      bottom="50%"
      width={convertMeterToPixel(faceFrontSize.width)}
      height={convertMeterToPixel(faceFrontSize.height)}
      position="absolute"
      // eslint-disable-next-line no-magic-numbers -- 中央揃えのため
      marginLeft={`${convertMeterToPixel(-faceFrontSize.width / 2)}px`}
      // eslint-disable-next-line no-magic-numbers -- 中揃えのため
      marginBottom={`${convertMeterToPixel(-faceFrontSize.height / 2)}px`}
    >
      <ProductImage
        product={product}
        width={convertMeterToPixel(faceFrontSize.width)}
        height={convertMeterToPixel(faceFrontSize.height)}
        faceFrontId={faceFront}
        layout="fill"
        objectFit="contain"
        objectPosition="bottom"
        imageSizeType={isPreviewMode ? 'medium' : 'tiny'}
        baseSize={convertMeterToPixel(
          Math.max(faceFrontSize.height, faceFrontSize.width)
        )}
      />
    </Box>
  );

  useEffect(() => {
    if (lastIndex && setIsAllChildRenndered) {
      if (+index === +lastIndex) {
        setIsAllChildRenndered(true);
      }
    }
  }, [lastIndex, index, setIsAllChildRenndered]);

  return (
    <Box
      component="div"
      data-group-position={
        !isPreviewMode && !isCompared
          ? `group-position-${rowIndex}-${index}`
          : undefined
      }
      onClick={handleClickGroup}
      position="relative"
      width="100%"
      height={convertMeterToPixel(displaySize.height * countY)}
    >
      <Box
        component="div"
        borderRadius="4px"
        boxSizing="content-box"
        width="100%"
        height={convertMeterToPixel(displaySize.height * countY)}
        margin={`-${margin}px`}
        position="absolute"
      >
        <Box component="div" position="relative" width="100%" height="100%">
          {range(countY).map((i) => {
            return (
              <Box
                component="div"
                key={i}
                sx={{
                  opacity: isDecreaseOpacity
                    ? // eslint-disable-next-line no-magic-numbers -- 半透明にするため
                      0.2
                    : getOpacity(productTag, isSelected, product, view),
                }}
                position="absolute"
                width={convertMeterToPixel(displaySize.width)}
                height={convertMeterToPixel(displaySize.height)}
                bottom={convertMeterToPixel(displaySize.height * i)}
              >
                <Box
                  component="div"
                  position="relative"
                  width="100%"
                  height="100%"
                >
                  {range(countX).map((j) => (
                    <Box
                      component="div"
                      key={j}
                      width={convertMeterToPixel(displaySize.width)}
                      height={convertMeterToPixel(displaySize.height)}
                      position="absolute"
                      left={convertMeterToPixel(
                        (displaySize.width + justifyMargin) * j
                      )}
                      bottom="0"
                    >
                      <Box
                        component="div"
                        position="relative"
                        width="100%"
                        height="100%"
                      >
                        {productImageElement}
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            );
          })}
          <Box
            component="div"
            borderRadius="6px"
            boxSizing="content-box"
            width="100%"
            height="100%"
            position="absolute"
            margin={`-${border}px`}
            {...(bucketMode !== 'area' && {
              border: `${border}px solid ${borderColor}`,
            })}
            sx={{
              animation:
                isSelected && !isSkippingColorChange && !isDragProduct
                  ? `${blink} 1.2s infinite`
                  : '',
            }}
            className="haha"
          >
            <Box
              component="div"
              bgcolor={
                isSelected
                  ? withOpacity(bgColor, productGroupBgOpacity)
                  : bgColor
              }
              border={
                isSelected && !isDragProduct
                  ? `${border}px solid ${borderColor}`
                  : undefined
              }
              borderRadius="4px"
              boxSizing="content-box"
              width={isSelected ? 'calc(100% - 4px)' : '100%'}
              height={isSelected ? 'calc(100% - 4px)' : '100%'}
            />
          </Box>
        </Box>
      </Box>
      {isOverflown && !isPreviewMode && (
        <Box
          component="div"
          sx={{
            width: 11,
            height: 11,
            backgroundColor: bgColor,
            position: 'absolute',
            top: '-5px',
            right: '-7px',
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '20px',
          }}
        >
          <ErrorOutlined
            sx={{ fill: theme.palette.error.main, width: 15, height: 15 }}
          />
        </Box>
      )}
    </Box>
  );
};

const createKeyframes = (borderColor: string) => keyframes`
  0%,100% {
    border-color:${borderColor};
  }
  50% {
    border-color:transparent;
  }
`;

const getOpacity = (
  productTag: ProductTag,
  isSelected: boolean,
  product: Product,
  view?: ShelfDetailView
) => {
  if (!view) return 1;

  return isClickableCompartment(view, product, productTag) ? 1 : opacity;
};

const getActivityColors = (
  isOverflown: boolean,
  isSelected: boolean,
  bboxColors: BboxColors,
  bboxEnabled: boolean,
  isComparisonCommonId: boolean,
  isSkippingColorChange: boolean,
  diffColor?: string,
  view?: ShelfDetailView,
  mode?: ShelfDetailMode,
  isEditorMode?: boolean,
  isDragProduct?: boolean
) => {
  if (!bboxEnabled || isSkippingColorChange) {
    return { borderColor: 'transparent', bgColor: 'transparent' };
  }

  if (isOverflown) {
    return {
      borderColor: theme.palette.error.main,
      bgColor: withOpacity(theme.palette.error.main, opacity),
    };
  }

  if (isSelected && !isDragProduct) {
    return {
      borderColor: theme.palette.primary.main,
      bgColor: theme.palette.primary.main,
    };
  }

  if (mode === 'comparison' && !isEditorMode) {
    switch (view) {
      case 'default':
      case 'productFlag':
        if (isComparisonCommonId) {
          return { borderColor: theme.palette.white.primary, bgColor: 'unset' };
        }
        if (!isComparisonCommonId) {
          return {
            borderColor: theme.palette.error.main,
            bgColor: withOpacity(theme.palette.error.main, opacity),
          };
        }
        return {
          borderColor: 'transparent',
          bgColor: 'transparent',
        };
      case 'profit':
        // TODO: implement color judging after planogram sales report api is ready
        return {
          borderColor: 'transparnt',
          bgColor: 'transparnt',
        };
      default:
        return {
          borderColor: diffColor ?? '#C6DAFC',
          bgColor: withOpacity(diffColor ?? '', profitOpacity),
        };
    }
  }

  if (view === 'profit') {
    // TODO: implement color judging after planogram sales report api is ready
    return {
      borderColor: 'transparnt',
      bgColor: 'transparemt',
    };
  }

  return { borderColor: '#C6DAFC', bgColor: 'unset' };
};
