import { CircularSpinner, ProductEmptyResult } from '@components/molecules';
import { ChangeBboxButton } from '@components/molecules/changeBboxButton/changeBboxButton';
import { FaceAreaControllerBox } from '@components/molecules/faceAreaControllerBox/faceAreaControllerBox';
import { ZoomController } from '@components/organisms';
import { HeatMap } from '@components/organisms/heatMap/heatMap';
import { useRealogramCandidateImage } from '@hooks/useImage';
import { useRealogramImageRatio } from '@hooks/useRealogramImageRatio';
import { useZoomController } from '@hooks/useZoomController';
import { Box } from '@mui/material';
import { updateRatio } from '@reducers/realogramCandidate';
import { useAppDispatch } from '@store/index';
import {
  formatNumberToYen,
  profitTabGrossProfit,
  profitTabQuantity,
  profitTabSales,
} from '@utils/const';
import { maxValueInReport } from '@utils/product';
import Image from 'next/legacy/image';
import { FC, useEffect, useRef, useState } from 'react';
import {
  ProfitTab,
  Rate,
  ShelfDetailMode,
  ShelfDetailView,
} from 'types/common';
import { ProductReport } from 'types/products';
import {
  BboxColors,
  RealogramSelectedItem,
  RealogramShelfBoard,
  UnknownProduct,
} from 'types/realogram';
import { theme } from '../../../theme';
import { Bboxes } from './fragments/bboxes';

type Props = {
  imageIsLoading: boolean;
  markerEnabled?: boolean;
  defaultBboxColor?: BboxColors;
  realogramCandidateId: number;
  shelfBoards?: RealogramShelfBoard[];
  view: ShelfDetailView;
  mode: ShelfDetailMode;
  handleClickBbox: (selectedItem: RealogramSelectedItem) => void;
  handleChangeLoading: () => void;
  createdAt: string;
  rate?: Rate;
  onClickNext?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onClickPrev?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  profitTab?: ProfitTab;
  productReports?: ProductReport[];
  comparedProductReports?: ProductReport[];
  comparisonSameIdsSet?: Set<number>;
  isComparingBox?: boolean;
  isBucketType: boolean;
  isDisabledAction?: boolean;
  isComparedBucketType?: boolean;
  isUnknownProductsReviseMode?: boolean;
  unknownProductsInfo?: UnknownProduct[];
  isNextDisabled?: boolean;
  isPrevDisabled?: boolean;
  isRealogramLoading?: boolean;
};

const timer = 5000;

export const RealogramImageAndBbox: FC<Props> = ({
  imageIsLoading,
  markerEnabled = false,
  defaultBboxColor,
  realogramCandidateId,
  shelfBoards,
  view,
  mode,
  handleClickBbox,
  handleChangeLoading,
  createdAt,
  rate,
  onClickNext,
  onClickPrev,
  profitTab,
  productReports,
  comparisonSameIdsSet,
  isComparingBox = false,
  isBucketType,
  isDisabledAction = false,
  isComparedBucketType = false,
  isUnknownProductsReviseMode = false,
  unknownProductsInfo,
  comparedProductReports,
  isNextDisabled = false,
  isPrevDisabled = false,
  isRealogramLoading = false,
}) => {
  const dispatch = useAppDispatch();
  const ref = useRef<HTMLElement>();
  const isRealogramBucketType = isComparingBox
    ? isComparedBucketType
    : isBucketType;
  const dataImage = isComparingBox ? 'comparison-image' : 'original-image';
  const imageElement: HTMLImageElement | null = document.querySelector(
    `[data-image="${dataImage}"]`
  );
  const [isCompletedLoadingImage, setCompletedLoadingImage] = useState(false);
  const { ratio } = useRealogramImageRatio(
    imageIsLoading,
    imageElement,
    ref,
    mode,
    isBucketType,
    isComparedBucketType
  );
  const {
    image: firstImage,
    error: firstImageError,
    isLoading: isFirstImageLoading,
    getImage: refetchFirstImage,
    setImage: setFistImage,
    setError: setFirstImageError,
  } = useRealogramCandidateImage(
    {
      realogramCandidateId,
      size: 'huge',
      shotIndex: 1,
    },
    { skip: false, isNoCache: true }
  );
  const {
    image: secondImage,
    error: secondImageError,
    isLoading: isSecondImageLoading,
    getImage: refetchSecondImage,
    setImage: setSecondImage,
    setError: setSecondImageError,
  } = useRealogramCandidateImage(
    {
      realogramCandidateId,
      size: 'huge',
      shotIndex: 2,
    },
    { skip: !isRealogramBucketType, isNoCache: true }
  );
  const originalMaxThreshold = getMaxValueInReport(profitTab, productReports);
  const comparedMaxThreshold = getMaxValueInReport(
    profitTab,
    comparedProductReports
  );

  const maxThreshold =
    originalMaxThreshold > comparedMaxThreshold
      ? originalMaxThreshold
      : comparedMaxThreshold;
  const isPrice = profitTab === profitTabSales;
  const [bboxEnabled, setBboxEnabled] = useState(true);
  const firstShot = 1;
  const secondShot = 2;
  const firstShotBboxes = shelfBoards?.filter(
    (el) => el.shot_index === firstShot
  );
  const secondShotBboxes = shelfBoards?.filter(
    (el) => el.shot_index === secondShot
  );
  const width = (imageElement?.naturalWidth ?? 0) * ratio;
  const height = (imageElement?.naturalHeight ?? 0) * ratio;
  const {
    zoomScale,
    handleDecrementZoom,
    handleIncrementZoom,
    handleTriggerZoom,
  } = useZoomController();

  useEffect(() => {
    if (ratio) {
      dispatch(updateRatio(ratio));
    }
  }, [ratio, dispatch]);

  useEffect(() => {
    if (isRealogramBucketType) return;
    if (firstImage && !isFirstImageLoading) {
      setCompletedLoadingImage(true);
    }
  }, [firstImage, isFirstImageLoading, isRealogramBucketType]);

  useEffect(() => {
    if (!isRealogramBucketType) return;
    if (
      firstImage &&
      !isFirstImageLoading &&
      secondImage &&
      !isSecondImageLoading
    ) {
      setCompletedLoadingImage(true);
    }
  }, [
    isRealogramBucketType,
    firstImage,
    secondImage,
    isFirstImageLoading,
    isSecondImageLoading,
  ]);

  useEffect(() => {
    if (!firstImage && firstImageError) {
      const intervalRefetchFirstImage = setInterval(async () => {
        const result = refetchFirstImage && (await refetchFirstImage());
        if (result) {
          setFistImage && setFistImage(URL.createObjectURL(result));
          setFirstImageError && setFirstImageError(undefined);
        }
      }, timer);

      return () => {
        clearInterval(intervalRefetchFirstImage);
      };
    }
  }, [
    firstImage,
    firstImageError,
    isFirstImageLoading,
    refetchFirstImage,
    setFirstImageError,
    setFistImage,
  ]);

  useEffect(() => {
    if (!secondImage && secondImageError) {
      const intervalRefetchSecondImage = setInterval(async () => {
        const result = refetchSecondImage && (await refetchSecondImage());
        if (result) {
          setSecondImage && setSecondImage(URL.createObjectURL(result));
          setSecondImageError && setSecondImageError(undefined);
        }
      }, timer);

      return () => {
        clearInterval(intervalRefetchSecondImage);
      };
    }
  }, [
    secondImage,
    secondImageError,
    refetchSecondImage,
    setSecondImage,
    setSecondImageError,
    isSecondImageLoading,
  ]);

  return (
    <Box
      component="div"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      position="relative"
      flex={1}
      sx={{ overflow: 'hidden' }}
    >
      <Box
        ref={ref}
        component="div"
        display="flex"
        alignItems="self-start"
        alignSelf="center"
        position="relative"
        width={firstImageError || secondImageError ? '100%' : 'unset'}
        flex={1}
        sx={{ overflow: 'auto' }}
      >
        {isRealogramLoading && (
          <Box
            component="div"
            sx={{
              width: '100%',
              height: '100%',
              overflow: 'hidden',
            }}
          >
            <CircularSpinner />
          </Box>
        )}
        {firstImageError || secondImageError ? (
          <Box component="div" width="100%" height="100%">
            <ProductEmptyResult
              title="画像が表示できません"
              message="時間を置いて再度ご確認ください。"
            />
          </Box>
        ) : (
          <Box
            component="div"
            sx={{
              transform: `scale(${zoomScale},${zoomScale})`,
              position: 'relative',
              display: 'flex',
              flex: 1,
              alignItems: 'center',
              gap: '3px',
              justifyContent: 'center',
              height: '100%',
              transformOrigin: 'left top',
            }}
          >
            {!isRealogramLoading && firstImage && isCompletedLoadingImage && (
              <Box
                flex={1}
                component="div"
                height={height}
                width={width}
                position="relative"
              >
                <Image
                  data-image={dataImage}
                  src={firstImage}
                  alt=""
                  layout="fill"
                  onLoadingComplete={handleChangeLoading}
                  objectFit="contain"
                />
                {firstShotBboxes && (
                  <Bboxes
                    markerEnabled={markerEnabled}
                    ratio={ratio}
                    bboxEnabled={bboxEnabled}
                    shelfBoards={firstShotBboxes}
                    defaultBboxColor={defaultBboxColor}
                    view={view}
                    mode={mode}
                    handleClickBbox={handleClickBbox}
                    rate={rate}
                    maxThreshold={maxThreshold}
                    productReports={productReports}
                    profitTab={profitTab}
                    comparisonSameIdsSet={comparisonSameIdsSet}
                    unknownProductsInfo={unknownProductsInfo}
                    isUnknownProductsReviseMode={isUnknownProductsReviseMode}
                  />
                )}
              </Box>
            )}

            {!isRealogramLoading && secondImage && isCompletedLoadingImage && (
              <Box
                flex={1}
                component="div"
                height={height}
                width={width}
                position="relative"
              >
                <Image
                  data-image={dataImage}
                  src={secondImage}
                  alt=""
                  layout="fill"
                  onLoadingComplete={handleChangeLoading}
                  objectFit="contain"
                />
                {secondShotBboxes && (
                  <Bboxes
                    markerEnabled={markerEnabled}
                    ratio={ratio}
                    bboxEnabled={bboxEnabled}
                    shelfBoards={secondShotBboxes}
                    defaultBboxColor={defaultBboxColor}
                    view={view}
                    mode={mode}
                    handleClickBbox={handleClickBbox}
                    rate={rate}
                    maxThreshold={maxThreshold}
                    productReports={productReports}
                    profitTab={profitTab}
                    comparisonSameIdsSet={comparisonSameIdsSet}
                    unknownProductsInfo={unknownProductsInfo}
                    isUnknownProductsReviseMode={isUnknownProductsReviseMode}
                  />
                )}
              </Box>
            )}
          </Box>
        )}
      </Box>
      <Box
        component="div"
        py={1.25}
        px={1.25}
        display="flex"
        alignItems="center"
        justifyContent="center"
        height={56}
        sx={{
          backgroundColor: theme.palette.white.primary,
          position: 'relative',
        }}
      >
        <FaceAreaControllerBox
          createdAt={createdAt}
          onClickNext={onClickNext}
          onClickPrev={onClickPrev}
          sx={{ fontSize: '14px', color: theme.palette.textBlack.primary }}
          isNextDisabled={isNextDisabled}
          isPrevDisabled={isPrevDisabled}
        />
        {firstImageError || secondImageError ? (
          <></>
        ) : (
          <>
            <ZoomController
              zoomScale={zoomScale}
              handleIncrementZoom={handleIncrementZoom}
              handleDecrementZoom={handleDecrementZoom}
              handleTriggerZoom={handleTriggerZoom}
              isDisabled={isDisabledAction}
              orientation="horizontal"
              sxGroupBtnZoom={{
                flexDirection: 'row-reverse',
                width: '146px',
                border: '1px solid rgba(0, 0, 0, 0.12)',
                height: '40px',
                marginRight: '10px',
              }}
              sxBtnIncrement={{
                height: '40px !important',
                width: '48px',
                padding: '9px',
              }}
              sxBtnDecrement={{
                height: '40px !important',
                width: '48px',
                padding: '9px',
              }}
              sxBoxText={{ height: '40px', width: '48px', padding: '9px' }}
              sxTextPercent={{ fontSize: '14px' }}
            />
            <ChangeBboxButton
              bboxEnabled={bboxEnabled}
              setBboxEnabled={setBboxEnabled}
              top={0}
              left={0}
              styleBox={{ position: 'relative', transform: 'none' }}
              styleBtn={{ width: '48px', height: '40px' }}
              isDisabledAction={isDisabledAction}
            />
          </>
        )}
      </Box>
      {view === 'profit' &&
        mode === 'default' &&
        profitTab !== undefined &&
        profitTab !== profitTabGrossProfit && ( // 「荒利」の場合ヒートマップを表示しない
          <Box
            component="div"
            sx={{ position: 'absolute', bottom: 64, left: 8 }}
          >
            <HeatMap
              start={isPrice ? '¥0' : '0'}
              end={
                isPrice
                  ? formatNumberToYen(maxThreshold)
                  : maxThreshold.toString()
              }
            />
          </Box>
        )}
    </Box>
  );
};

const getMaxValueInReport = (
  profitTab?: ProfitTab,
  productReports?: ProductReport[]
) => {
  if (profitTab === undefined || !productReports) return 0;
  switch (profitTab) {
    case profitTabSales:
      return maxValueInReport('gross_sales_price', productReports);
    case profitTabQuantity:
      return maxValueInReport('sales_count', productReports);
    case profitTabGrossProfit:
      return maxValueInReport('gross_profit_price', productReports);
    default:
      return 0;
  }
};
