import { CircularSpinner } from '@components/molecules';
import { SelectComparison } from '@components/molecules/selectComparison/selectComparison';
import { DevelopingFeatureDialog } from '@components/organisms/developingFeatureDialog/developingFeatureDialog';
import { SearchModal } from '@components/organisms/searchModal/searchModal';
import { SelectedItemCompare } from '@components/organisms/selectedItemCompare/selectedItemCompare';
import { useComparisonItemModal } from '@hooks/useComparisonItemModal';
import { useEstimatedProfit } from '@hooks/useEstimatedProfit';
import { useSelectPrevNextRealogram } from '@hooks/useSelectPrevNextRealogram';
import { useUrlQueryParams } from '@hooks/useUrlQueryParams';
import { Box, Divider } from '@mui/material';
import { CurrentSelectedType } from '@reducers/comparisonItemModal';
import {
  updateProductPosition,
  updateSelectedProductCompartment,
} from '@reducers/planogramEditor/reducer';
import { selectPlanogramEditorState } from '@reducers/planogramEditor/selectors';
import { setComparisonSameIds } from '@reducers/product';
import { selectProductState } from '@reducers/product/selector';
import { updateSelectedRealogramItem } from '@reducers/realogramCandidate';
import { selectRealogramSelectedItem } from '@reducers/realogramCandidate/selector';
import { useAppDispatch, useAppSelector } from '@store/index';
import { ComparisonTabModal, getComparisonDiffColor } from '@utils/const';
import { Format, format } from '@utils/date';
import { getProductsLayout } from '@utils/planogram';
import { isSelectedItem } from '@utils/realogram';
import { intersection } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { theme } from 'theme';
import { Planogram } from 'types/planogram';
import { ProductSalesReport } from 'types/products';
import {
  RealogramCandidateContainCurrentRole,
  RealogramSelectedItem,
  RealogramShelfBoard,
} from 'types/realogram';
import { PlanogramComparison } from './planogramComparison';

type Props = {
  handleClickBbox: (selectedItem: RealogramSelectedItem) => void;
  planogram?: Planogram;
  comparedPlanogram?: Planogram;
  comparedRealogram?: RealogramCandidateContainCurrentRole;
  isLoading: boolean;
  currentSelectedType?: CurrentSelectedType;
  comparedShelfboards?: RealogramShelfBoard[];
  comparisonProductIds?: string;
  isForbidden?: boolean;
  isBucketType?: boolean;
  isTenantSalesAnalytics?: boolean;
  displayComparedAnalyticsData?: ProductSalesReport;
  comparedAnalyticsDataTerm: string;
  disableChangeToComparedPreviousWeek: boolean;
  disableChangeToComparedNextWeek: boolean;
  handleComparedNextWeekTerm: () => void;
  handleComparedPreviousWeekTerm: () => void;
};

export const RealogramOrPlanogramImages: FC<Props> = ({
  planogram,
  comparedPlanogram,
  isLoading,
  currentSelectedType,
  comparedShelfboards,
  comparedRealogram,
  comparisonProductIds,
  isForbidden = false,
  isBucketType = false,
  isTenantSalesAnalytics,
  displayComparedAnalyticsData,
  comparedAnalyticsDataTerm,
  disableChangeToComparedPreviousWeek,
  disableChangeToComparedNextWeek,
  handleComparedNextWeekTerm,
  handleComparedPreviousWeekTerm,
}) => {
  const dispatch = useAppDispatch();
  const { detailView, detailMode, profitTab, rateValue } = useAppSelector(
    selectPlanogramEditorState
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [openDevFeatureDialog, setOpenDevFeatureDialog] = useState(false);
  const { updateQueryParameter, removeQueryParameter } = useUrlQueryParams();
  const selectedRealogramItem = useAppSelector(selectRealogramSelectedItem);
  const { comparisonSameIds } = useAppSelector(selectProductState);
  const [isComparedImageLoading, setIsComparedImageLoading] = useState(false); //realogramImageが完全に読み込まれたことを確認するフラグ
  useEffect(() => {
    if (!planogram || !comparisonProductIds) return;
    const productIds = (
      planogram?.plan ? getProductsLayout(planogram.plan) : []
    ).flatMap(({ row }) => row.map(({ product_id }) => product_id));
    const source = comparisonProductIds?.split(',').map(Number);

    dispatch(setComparisonSameIds(intersection(productIds, source)));
  }, [dispatch, comparisonProductIds, planogram]);
  const showDevFeatureDialog = () => {
    setOpenDevFeatureDialog(true);
  };

  const { handleOpenComparisonItemModal } = useComparisonItemModal();
  const handleClickRealogramBbox = (item: RealogramSelectedItem) => {
    dispatch(updateProductPosition(undefined));
    dispatch(updateSelectedProductCompartment(undefined));

    if (isSelectedItem(detailView, item, selectedRealogramItem)) {
      dispatch(updateSelectedRealogramItem(undefined));
      removeQueryParameter('item');
      return;
    }
    dispatch(updateSelectedRealogramItem(item));
    updateQueryParameter('item', item.compartmentId);
  };
  const {
    realogramEstimatedData,
    handleChangeProfit,
    storeAreaType: comparedStoreAreaType,
    profit: comparedProfit,
    planogramEstimatedData: comparedPlanogramEstimatedData,
    isLoadingEstimate,
  } = useEstimatedProfit({
    realogram: comparedRealogram,
    planogram: comparedPlanogram,
  });
  const { onClickNext, onClickPrev } =
    useSelectPrevNextRealogram(comparedRealogram);
  return (
    <Box component="div" display="flex" flex={1} height="100%">
      <Box
        component="div"
        flex={1}
        width="50%"
        display="flex"
        flexDirection="column"
      >
        {comparedPlanogram || comparedRealogram ? (
          <SelectedItemCompare
            currentSelectedType={currentSelectedType}
            comparedPlanogram={comparedPlanogram}
            comparedRealogram={comparedRealogram}
            comparedProfit={comparedProfit}
            rateValue={rateValue}
            profitTab={profitTab}
            comparedRealogramEstimatedData={realogramEstimatedData}
            comparedStoreAreaType={comparedStoreAreaType}
            comparedShelfboards={comparedShelfboards}
            detailView={detailView}
            detailMode={detailMode}
            comparisonSameIds={comparisonSameIds}
            isComparedImageLoading={isComparedImageLoading}
            handleChangeComparedProfit={handleChangeProfit}
            handleClickRealogramBbox={handleClickRealogramBbox}
            setIsComparedImageLoading={setIsComparedImageLoading}
            handleOpenComparisonItemModal={handleOpenComparisonItemModal}
            showDevFeatureDialog={showDevFeatureDialog}
            handleRecalculate={() => void 0}
            comparedPlanogramEstimatedData={comparedPlanogramEstimatedData}
            planogram={planogram}
            isComparedBucketType={
              comparedRealogram?.shot_type === 'split_bucket'
            }
            isBucketType={isBucketType}
            isLoadingEstimate={isLoadingEstimate}
            isTenantSalesAnalytics={isTenantSalesAnalytics}
            comparedAnalyticsData={displayComparedAnalyticsData}
            comparedAnalyticsDataTerm={comparedAnalyticsDataTerm}
            disableChangeToComparedPreviousWeek={
              disableChangeToComparedPreviousWeek
            }
            disableChangeToComparedNextWeek={disableChangeToComparedNextWeek}
            handleComparedNextWeekTerm={handleComparedNextWeekTerm}
            handleComparedPreviousWeekTerm={handleComparedPreviousWeekTerm}
            onClickNext={onClickNext}
            onClickPrev={onClickPrev}
          />
        ) : (
          <Box component="div" height="100%">
            {isLoading ? (
              <CircularSpinner sx={{ display: 'flex', alignItems: 'center' }} />
            ) : (
              !comparedPlanogram && (
                <SelectComparison
                  emptyTopBoxSx={{ height: '43px' }}
                  handleOpenModal={() =>
                    handleOpenComparisonItemModal({
                      defaultTab: ComparisonTabModal.PLANOGRAM_TAB,
                      originPlanogramId: planogram?.id,
                    })
                  }
                  isForbidden={isForbidden}
                />
              )
            )}
          </Box>
        )}
      </Box>
      <Divider orientation="vertical" flexItem />
      <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
        <PlanogramComparison
          key={planogram?.id}
          bboxColors={{
            borderColor: theme.palette.beforeAfter.after,
            backgroundColor: theme.palette.beforeAfter.afterOverlay,
          }}
          createdAt={format(planogram?.created_at, Format.datetimeWithWeekday)}
          planogram={planogram}
          backgroundColor={theme.palette.shelf.backgroundTana}
          diffColor={getComparisonDiffColor(detailView)}
          detailMode={detailMode}
          detailView={detailView}
          sx={{ width: '50%' }}
          isTenantSalesAnalytics={isTenantSalesAnalytics}
        />
      </DndProvider>
      <SearchModal open={modalOpen} handleClose={() => setModalOpen(false)} />
      <DevelopingFeatureDialog
        open={openDevFeatureDialog}
        handleCancel={() => setOpenDevFeatureDialog(false)}
      />
    </Box>
  );
};
