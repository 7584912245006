import { FullHeightBox } from '@components/molecules';
import { MfaStep as MfaStepComponent } from '@components/pages/mfa/fragments';
import { Box } from '@mui/material';
import { selectEmail, selectIsAuthenticated } from '@reducers/auth/selectors';
import { useAppSelector } from '@store/index';
import { paths } from '@utils/const';
import { Navigate, useLocation } from 'react-router-dom';

export const Mfa = () => {
  const location = useLocation();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const locationState = location.state as { email: string };
  const selectorEmail = useAppSelector(selectEmail); //NOTE: // ログイン後はstoreからemailを取得できるが、ログイン前はemailをstoreから取得できないためlocationでemailを渡す
  const email = isAuthenticated ? selectorEmail : locationState?.email;

  if (!email) return <Navigate to={paths.login} />;

  return (
    <FullHeightBox flexDirection="column">
      <Box component="div" flex={1} display="flex" alignItems="center">
        {email && (
          <MfaStepComponent email={email} isInitial={!!locationState} />
        )}
      </Box>
    </FullHeightBox>
  );
};
