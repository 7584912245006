import { Box } from '@mui/material';
import { AddFaceModeHeader } from './addFaceModeHeader';
import { RealogramShelfBoard } from 'types/realogram';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useRealogramImageRatio } from '@hooks/useRealogramImageRatio';
import { ZoomController } from '@components/organisms';
import { useZoomController } from '@hooks/useZoomController';
import 'react-image-crop/dist/ReactCrop.css';
import { OperationModal } from './operationModal';
import { useCropImage } from '@components/pages/scannerDetails/hooks/useCropImage';
import { CropImageAndBbox } from './cropImageAndBbox';
import { Crop } from 'react-image-crop';
import { useAppDispatch } from '@store/index';
import { updateLoadingIndicatorState } from '@reducers/loadingIndicator';

type Props = {
  addFaceModeLabel: string;
  shelfBoards?: RealogramShelfBoard[];
  realogramImage?: string;
  compartmentNumber?: number;
  shotBboxes?: RealogramShelfBoard[];
  canNotShowImage: boolean;
  isLarger: boolean;
  maxWidth?: number;
  maxHeight?: number;
  setCompartmentNumber: (compartmentNumber: number) => void;
  handleAddFaceMode: (compartmentNumber: number) => void;
  handleSaveNewFace: (crop: Crop, zoomScale: number, ratio: number) => void;
};
export const AddFaceMode = ({
  addFaceModeLabel,
  shelfBoards,
  realogramImage,
  compartmentNumber,
  shotBboxes,
  canNotShowImage,
  isLarger,
  maxWidth,
  maxHeight,
  setCompartmentNumber,
  handleAddFaceMode,
  handleSaveNewFace,
}: Props) => {
  const ref = useRef<HTMLElement>();
  const imageElement: HTMLImageElement | null = document.querySelector(
    `[data-image="original-image"]`
  );
  const { ratio } = useRealogramImageRatio(
    true,
    imageElement,
    ref,
    'default',
    false,
    false,
    true
  );
  const { width, height } = useMemo(() => {
    if (!imageElement) return { width: 0, height: 0 };
    else
      return {
        width: imageElement.naturalWidth * ratio,
        height: imageElement.naturalHeight * ratio,
      };
  }, [imageElement, ratio]);

  const [isOperationDialogOpen, setIsOperationDialogOpen] = useState(false);
  const {
    zoomScale,
    handleDecrementZoom,
    handleIncrementZoom,
    handleTriggerZoom,
  } = useZoomController();
  const { crop, isEnableSave, setCrop } = useCropImage(maxWidth, maxHeight);

  const dispatch = useAppDispatch();
  const [isReady, setIsReady] = useState(false);
  useEffect(() => {
    // widthとheightを待たないとBboxのみ表示されてしまう
    if (!realogramImage) {
      setIsReady(false);
      dispatch(updateLoadingIndicatorState(false));
    } else if (width <= 0 && height <= 0) {
      setIsReady(false);
      dispatch(updateLoadingIndicatorState(true));
    } else {
      setIsReady(true);
      dispatch(updateLoadingIndicatorState(false));
    }
  }, [dispatch, width, height, realogramImage]);

  return (
    <Box component="div" ref={ref}>
      <AddFaceModeHeader
        handleAddFaceMode={handleAddFaceMode}
        shelfBoards={shelfBoards}
        addFaceModeLabel={addFaceModeLabel}
        compartmentNumber={compartmentNumber}
        setCompartmentNumber={setCompartmentNumber}
        isEnableSave={isEnableSave}
        setIsOperationDialogOpen={() => setIsOperationDialogOpen(true)}
        handleSaveNewFace={() => handleSaveNewFace(crop, zoomScale, ratio)}
      />
      <CropImageAndBbox
        realogramImage={realogramImage}
        zoomScale={zoomScale}
        crop={crop}
        height={height}
        width={width}
        ratio={ratio}
        setCrop={setCrop}
        shotBboxes={shotBboxes}
        canNotShowImage={canNotShowImage}
        isReady={isReady}
      />
      {isLarger && (
        <ZoomController
          zoomScale={zoomScale}
          handleIncrementZoom={handleIncrementZoom}
          handleDecrementZoom={handleDecrementZoom}
          handleTriggerZoom={handleTriggerZoom}
          sxGroupBtnZoom={{
            flexDirection: 'column',
            width: '48px',
            border: '1px solid rgba(0, 0, 0, 0.12)',
            height: '146px',
            position: 'absolute',
            top: '10%',
            left: '2%',
          }}
          sxBtnIncrement={{
            height: '40px',
            padding: '9px',
          }}
          sxBtnDecrement={{
            height: '40px',
            padding: '9px',
          }}
          sxBoxText={{ height: '48px', width: '48px' }}
          sxTextPercent={{ fontSize: '14px' }}
        />
      )}
      <OperationModal
        open={isOperationDialogOpen}
        onClose={() => setIsOperationDialogOpen(false)}
      />
    </Box>
  );
};
