import { GoBackButton } from '@components/molecules/goBackButtonIcon/goBackButton';
import { ProductDrawer } from '@components/organisms';
import { Categories } from '@components/organisms/categories/categories';
import { Search } from '@mui/icons-material/';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Divider, IconButton, TextField, Typography } from '@mui/material';
import { updateSelectedProductCompartment } from '@reducers/planogramEditor/reducer';
import { selectPlanogramEditorState } from '@reducers/planogramEditor/selectors';
import { useListProductsQuery } from '@reducers/shelfAppsApi';
import { useAppDispatch, useAppSelector } from '@store/index';
import { fullHeight } from '@utils/const';
import { itemsPerPage, productDrawerHeight } from '@utils/product';
import { FC, useMemo, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import { theme } from 'theme';
import { Product } from 'types/common';
import { ProductCategory } from 'types/productCategories';
import { InferType, object, string } from 'yup';
import { useProductsSearch } from '../../../hooks/useProductsSearch';
import { headerHeight } from '../header/header';
import { ProductsSearchResults } from '../productsSearchResults/productsSearchResults';

type Props = {
  categoryIndex: number;
  handleBack: () => void;
  productCategoryHistory: ProductCategory[];
  categoryType: 'categories' | 'list';
  handleClick: (category: ProductCategory) => void;
  fetchFilteredCategories: (text: string) => void;
  filteredCategories: ProductCategory[];
  handleClickProductCategory: (category: ProductCategory) => void;
  isCompareMode?: boolean;
};

const schema = object({
  searchText: string().required(),
});

type FormData = InferType<typeof schema>;

const tabHeight = 80;

export const Products: FC<Props> = ({
  categoryIndex,
  handleBack,
  productCategoryHistory,
  categoryType,
  handleClick,
  fetchFilteredCategories,
  filteredCategories,
  handleClickProductCategory,
}) => {
  const dispatch = useAppDispatch();
  const { detailMode, selectedProductCompartment, isShowProductDetail } =
    useAppSelector(selectPlanogramEditorState);
  const isCompareMode = detailMode === 'comparison';
  const { tags } = useAppSelector(selectPlanogramEditorState);
  const handleClickProduct = (product: Product) => {
    if (selectedProductCompartment?.id === product.id) {
      dispatch(updateSelectedProductCompartment(undefined));
    } else {
      dispatch(updateSelectedProductCompartment(product));
    }
  };

  const { isFocused, control, searchWord, focus, search, reset, handleSubmit } =
    useProductsSearch(fetchFilteredCategories);

  const [offset, setOffset] = useState(0);

  const { data, isLoading, isFetching } = useListProductsQuery(
    {
      offset: offset,
      limit: itemsPerPage,
      detail: true,
      shape: true,
      category: [productCategoryHistory[categoryIndex]?.name],
      tags,
    },
    {
      skip: !!searchWord || categoryType === 'categories',
      refetchOnMountOrArgChange: true,
    }
  );

  const [searchedOffset, setSearchedOffset] = useState(0);

  const {
    data: searchedData,
    isLoading: isSearchedLoading,
    isFetching: isSearchFetching,
  } = useListProductsQuery(
    {
      offset: searchedOffset,
      limit: itemsPerPage,
      detail: true,
      shape: true,
      q: searchWord,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !searchWord,
    }
  );

  const handleEndReached = (index: number) => {
    if (isLoading || isFetching) return;
    const offset = index + 1;
    if (data?.pager.total && offset >= data?.pager.total) return; // NOTE: 全件取得済みのときは何もしない
    setOffset(offset);
  };

  const handleSearchEndReached = (index: number) => {
    if (isSearchedLoading || isSearchFetching) return;
    const offset = index + 1;
    if (searchedData?.pager.total && offset >= searchedData?.pager.total)
      return; // NOTE: 全件取得済みのときは何もしない
    setSearchedOffset(offset);
  };

  const productsRef = useRef<HTMLDivElement>(null);

  const categoryRef = useRef<HTMLDivElement>();
  const chipsRef = useRef<HTMLDivElement>();
  const categoryHeight = categoryRef.current?.clientHeight ?? 0;
  const chipsHegight = chipsRef.current?.clientHeight ?? 0;
  const drawerHeight = useMemo(
    () =>
      `calc(${fullHeight} - ${headerHeight}px - ${tabHeight}px - ${chipsHegight}px - ${categoryHeight}px - ${
        selectedProductCompartment?.id ? productDrawerHeight : 0
      }px)`,
    [categoryHeight, chipsHegight, selectedProductCompartment?.id]
  );

  const onSubmit = (data: FormData) => {
    setSearchedOffset(0);
    search(data);
    productsRef.current?.scrollTo(0, 0);
  };
  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      display="flex"
      flexDirection="column"
      height="100vh"
    >
      <Box
        component="div"
        ref={categoryRef}
        sx={{
          py: 1,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {!isFocused && (
          <Typography
            onClick={() => {
              handleBack();
              setOffset(0);
            }}
            component="h2"
            sx={{
              fontSize: '16px',
              fontWeight: 700,
              letterSpacing: '0.15px',
              cursor: categoryIndex > 0 ? 'pointer' : 'default',
              display: 'inline-flex',
              alignItems: 'center',
              lineHeight: '175%',
            }}
          >
            {categoryIndex > 0 && <GoBackButton width={32} height={32} />}
            {categoryIndex > 0
              ? productCategoryHistory[categoryIndex]?.name
              : 'カテゴリ'}
          </Typography>
        )}
        <Controller
          name="searchText"
          control={control}
          render={({ field }) => (
            <>
              {isFocused ? (
                <TextField
                  id="searchedTextId"
                  key="searchedTextFullWidth"
                  {...field}
                  placeholder="全カテゴリから検索"
                  size="small"
                  sx={{
                    width: '100%',
                    'input::placeholder': {
                      fontSize: '14px',
                    },
                  }}
                  InputProps={{
                    sx: {
                      borderRadius: '40px',
                      backgroundColor: '#F7F7F7',
                    },
                    startAdornment: (
                      <Search
                        sx={{
                          color: theme.palette.icons.primary,
                          mr: '4px',
                        }}
                      />
                    ),
                    endAdornment: isFocused && (
                      <IconButton
                        onClick={() => {
                          reset();
                          setOffset(0);
                        }}
                      >
                        <CloseIcon
                          sx={{
                            width: '18px',
                            height: '18px',
                            cursor: 'pointer',
                            color: theme.palette.icons.primary,
                          }}
                        />
                      </IconButton>
                    ),
                  }}
                />
              ) : isCompareMode ? (
                <Search
                  sx={{
                    color: theme.palette.icons.primary,
                    mr: '4px',
                    cursor: 'pointer',
                  }}
                  onClick={() => focus()}
                />
              ) : (
                <TextField
                  key="searchedTextCollapsed"
                  {...field}
                  placeholder="全カテゴリから検索"
                  onFocus={focus}
                  size="small"
                  sx={{
                    width: '184px',
                    'input::placeholder': {
                      fontSize: '14px',
                    },
                  }}
                  InputProps={{
                    sx: {
                      borderRadius: '40px',
                      backgroundColor: '#F7F7F7',
                    },
                    startAdornment: (
                      <Search
                        sx={{
                          color: theme.palette.icons.primary,
                          mr: '4px',
                        }}
                      />
                    ),
                  }}
                />
              )}
            </>
          )}
        />
      </Box>
      <Divider sx={{ mx: -2 }} />
      {!isFocused ? (
        <Categories
          categoryName={productCategoryHistory[categoryIndex]?.name}
          categoryType={categoryType}
          productCategories={
            productCategoryHistory[categoryIndex]?.sub_categories
          }
          handleClick={handleClick}
          productsRef={productsRef}
          isCompareMode={isCompareMode}
          chipsRef={chipsRef}
          drawerHeight={drawerHeight}
          handleEndReached={handleEndReached}
          isLoading={isLoading || isFetching}
          products={data?.products}
          handleClickProduct={handleClickProduct}
          offset={offset}
          selectedItemId={selectedProductCompartment?.id}
          setOffset={setOffset}
        />
      ) : (
        <>
          {searchWord && (
            <ProductsSearchResults
              products={searchedData?.products}
              reset={() => {
                reset();
                setSearchedOffset(0);
              }}
              offset={searchedOffset}
              filteredCategories={filteredCategories}
              handleClickProductCategory={(category: ProductCategory) => {
                handleClickProductCategory(category);
                setSearchedOffset(0);
              }}
              isLoading={isSearchedLoading || isSearchFetching}
              productsRef={productsRef}
              isCompareMode={isCompareMode}
              handleEndReached={handleSearchEndReached}
              total={searchedData?.pager.total}
              handleClickProduct={handleClickProduct}
              height={drawerHeight}
              selectedItemId={selectedProductCompartment?.id}
            />
          )}
        </>
      )}
      {selectedProductCompartment && !isShowProductDetail && (
        <Box
          component="div"
          sx={{
            p: 2,
            position: 'absolute',
            zIndex: 2,
            bottom: 0,
            width: isCompareMode ? '254px' : '100%',
            boxShadow: '5px 2px 8px rgba(0, 0, 0, 0.25)',
            backgroundColor: theme.palette.white.primary,
            ...(isCompareMode && {
              right: 0,
              height: '80px',
            }),
            ...(!isCompareMode && {
              left: 0,
            }),
          }}
        >
          <ProductDrawer
            isCompareMode={isCompareMode}
            product={selectedProductCompartment}
            productsRef={productsRef}
          />
        </Box>
      )}
    </Box>
  );
};
