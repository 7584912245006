import { yupResolver } from '@hookform/resolvers/yup';
import { updateLoadingIndicatorState } from '@reducers/loadingIndicator';
import {
  useActivateUserMutation,
  useDeactivateUserMutation,
  useGetIfUserMfaRegisteredQuery,
  useUpdateUserMutation,
} from '@reducers/shelfAppsApi';
import { openToast } from '@reducers/toast';
import { useAppDispatch } from '@store/index';
import { isOnlySpaces, paths, toastMessages } from '@utils/const';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { User } from 'types/user';
import { UserFormData, schema } from '../types/userForm';

export const useUpdateUser = () => {
  const { id } = useParams();
  const intId = parseInt(id ?? '');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [selectedUser, setSelectedUser] = useState<User>();
  const { data: mfaData } = useGetIfUserMfaRegisteredQuery(
    { id: intId },
    { skip: !id }
  );
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm<UserFormData>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      fullname: selectedUser?.fullname ?? '',
      email: selectedUser?.email ?? '',
      role: selectedUser?.role ?? 'user',
      isActive: selectedUser?.deleted_at ? 'false' : 'true',
    },
  });

  const [fullname, role, isActive] = watch(['fullname', 'role', 'isActive']);

  const [updateUser] = useUpdateUserMutation();
  const [activateUser] = useActivateUserMutation();
  const [deactivateUser] = useDeactivateUserMutation();

  const isSelectedUserActive = selectedUser?.deleted_at ? 'false' : 'true';

  const isDisabled =
    !fullname ||
    !role ||
    (fullname === selectedUser?.fullname &&
      role === selectedUser?.role &&
      isActive === isSelectedUserActive) ||
    isOnlySpaces(fullname);

  const updateAccount = async (data: UserFormData) => {
    if (!selectedUser) return;
    dispatch(updateLoadingIndicatorState(true));
    try {
      const isActivePreviously = !selectedUser.deleted_at;

      if (data.isActive === 'false' && isActivePreviously) {
        await deactivateUser({
          userId: selectedUser.id,
        });
        await updateUser({
          id: String(id),
          ...data,
        });
      } else if (data.isActive === 'true' && !isActivePreviously) {
        await activateUser({
          userId: selectedUser.id,
        });
        await updateUser({
          id: String(id),
          ...data,
        });
      } else {
        await updateUser({
          id: String(id),
          ...data,
        });
      }
    } catch (error) {
      console.error(error);
      dispatch(
        openToast({
          type: 'error',
          message: toastMessages.error.updateUser,
        })
      );
    } finally {
      dispatch(updateLoadingIndicatorState(false));
      dispatch(
        openToast({
          type: 'success',
          message: toastMessages.success.updateUser,
        })
      );
      resetSelectedUser();
    }
  };

  const resetSelectedUser = () => {
    navigate(paths.users.root);
    clearErrors('fullname');
    setSelectedUser(undefined);
  };

  const selectUser = (user: User) => {
    setSelectedUser(user);
    navigate(paths.users.user(user.id));
  };
  useEffect(() => {
    setValue('fullname', selectedUser?.fullname ?? '');
    setValue('email', selectedUser?.email ?? '');
    setValue('role', selectedUser?.role ?? 'user');
    setValue('isActive', isSelectedUserActive);
  }, [setValue, selectedUser, isSelectedUserActive]);

  return {
    control,
    handleSubmit,
    errors,
    isDisabled,
    resetSelectedUser,
    selectUser,
    selectedUser,
    updateAccount,
    isMfaActive: !!mfaData?.preferred_mfa_setting,
  };
};
