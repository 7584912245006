import { FullHeightBox } from '@components/molecules';
import { PasswordResetForm } from '@components/organisms';
import { usePageTitle } from '@hooks/usePageTitle';
import { selectIsAuthenticated } from '@reducers/auth/selectors';
import {
  useConfirmForgotPasswordMutation,
  useForgotPasswordMutation,
} from '@reducers/shelfAppsApi';
import { openToast } from '@reducers/toast';
import { useAppDispatch, useAppSelector } from '@store/index';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PasswordReminder } from './fragments/passwordReminder';
import { toastMessages, paths } from '@utils/const';
import {
  forgotPasswordToErrorMessage,
  passwordResetToErrorMessage,
} from './utils/password';

type Props = {
  isInitial?: boolean;
};
export const PasswordReset: FC<Props> = ({ isInitial = false }) => {
  usePageTitle('パスワードリセット');
  const dispatch = useAppDispatch();
  const [reminded, setReminded] = useState(false);
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const [
    forgotPassword,
    { error: forgotPasswordError, isLoading: forgotPasswordIsLoading },
  ] = useForgotPasswordMutation();
  const [
    confirmForgotPassword,
    { error, isLoading: confirmForgotPasswordIsLoading },
  ] = useConfirmForgotPasswordMutation();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);

  const handleForgotPassword = async (email: string, hasToast = false) => {
    try {
      await forgotPassword({ username: email }).unwrap();
      setEmail(email);
      setReminded(true);
      if (hasToast) {
        dispatch(
          openToast({
            type: 'success',
            message: toastMessages.success.resendAuthCode,
          })
        );
      }
    } catch (e) {
      console.error(e);
      if (hasToast) {
        dispatch(
          openToast({
            type: 'error',
            message: toastMessages.error.resendAuthCode,
          })
        );
      }
    }
  };

  if (reminded) {
    return (
      <FullHeightBox sx={{ height: { xs: 'auto', sm: 'auto', md: '100vh' } }}>
        <PasswordResetForm
          email={email}
          isLoading={confirmForgotPasswordIsLoading}
          forgotPasswordIsLoading={forgotPasswordIsLoading}
          errorMessage={error && passwordResetToErrorMessage(error)}
          isAuthenticated={isAuthenticated}
          handleNavigateLogin={() =>
            navigate(isAuthenticated ? paths.home : paths.login)
          }
          onSubmit={async ({ code, password }) => {
            try {
              await confirmForgotPassword({
                confirmationCode: code,
                username: email,
                password,
              }).unwrap();
              dispatch(
                openToast({
                  type: 'success',
                  message: toastMessages.success.setNewPassword,
                })
              );
              if (isInitial) {
                navigate(paths.account.mfa, { state: { email } });
              } else {
                navigate(paths.login);
              }
            } catch (e) {
              console.error(e);
            }
          }}
          handleForgotPassword={async () =>
            await handleForgotPassword(email, true)
          }
        />
      </FullHeightBox>
    );
  }

  //パスワードリマインダ-
  return (
    <FullHeightBox sx={{ height: { xs: 'auto', sm: 'auto', md: '100vh' } }}>
      <PasswordReminder
        errorMessage={
          forgotPasswordError &&
          forgotPasswordToErrorMessage(forgotPasswordError)
        }
        isAuthenticated={isAuthenticated}
        isLoading={forgotPasswordIsLoading}
        onSubmit={async ({ email }) => await handleForgotPassword(email)}
      />
    </FullHeightBox>
  );
};
