import { CircularSpinner } from '@components/molecules/circularSpinner/circularSpinner';
import { ComparisonProduct } from '@components/molecules/comparisonProduct/comparisonProduct';
// import { useRerenderingDetails } from '@hooks/rerenderingComponents';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { useComparisonItemModal } from '@hooks/useComparisonItemModal';
import { useExtractRealogramData } from '@hooks/useExtractRealogramData';
import { useUrlQueryParams } from '@hooks/useUrlQueryParams';
import { ArrowBackIos } from '@mui/icons-material';
import { Box, Button, Divider, Typography } from '@mui/material';
import {
  CurrentSelectedType,
  setCurrentTab,
  updateCurrentSelectedItemId,
  updateCurrentSelectedType,
  updateSelectedItemId,
} from '@reducers/comparisonItemModal';
import { selectComparisonItemModal } from '@reducers/comparisonItemModal/selectors';
import { selectModalProps } from '@reducers/modal/selector';
import {
  updateProductPosition,
  updateSelectedProductCompartment,
} from '@reducers/planogramEditor/reducer';
import { selectPlanogramEditorState } from '@reducers/planogramEditor/selectors';
import { updateSelectedRealogramItem } from '@reducers/realogramCandidate';
import { selectRealogramSelectedItem } from '@reducers/realogramCandidate/selector';
import {
  useGetProductQuery,
  useListProductsBulkQuery,
} from '@reducers/shelfAppsApi';
import { useAppDispatch, useAppSelector } from '@store/index';
import { ComparisonTabModal } from '@utils/const';
import { calcPlanStatistics, getProductsLayout } from '@utils/planogram';
import { isInferredAsProduct } from '@utils/product';
import { calculateRealogramStatistics, isSelectedItem } from '@utils/realogram';
import { FC, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { theme } from 'theme';
import {
  ProductTag,
  ProfitTab,
  Rate,
  ShelfDetailMode,
  ShelfDetailView,
} from 'types/common';
import { ProductSalesReport } from 'types/products';
import {
  ListProductsBulk,
  RealogramCandidate,
  RealogramSelectedItem,
  RealogramShelfBoard,
} from 'types/realogram';
import { ComparisonStatistics } from './comparisonStatistics';
import { RealogramOrPlanogramImages } from './realogramOrPlanogramImages';
import { useInitSelectedRealogram } from '@hooks/useInitSelectedRealogram';
import { BucketPlanogramPlan, PlanogramPlan } from 'types/planogram';
import { useRealogramAnalyticsData } from '../../../../hooks/useRealogramAnalyticsData';
import { Statistic } from 'types/statistics';

type Props = {
  realogramCandidateId: number;
  view: ShelfDetailView;
  mode: ShelfDetailMode;
  shelfBoards?: RealogramShelfBoard[];
  filteredShelfboards?: RealogramShelfBoard[];
  primaryCandidates?: ListProductsBulk;
  storeBayName: string;
  createdAt: string;
  rate: Rate;
  productTag: ProductTag;
  report?: ProductSalesReport;
  handleChangeProductTag: (productTag: ProductTag) => void;
  handleChangeRate: (rate: Rate) => void;
  comparisonProfitTabValue: ProfitTab;
  setComparisonProfitTabValue: (profitTab: ProfitTab) => void;
  isImageLoading: boolean;
  handleChangeLoading: () => void;
  realogramCandidate?: RealogramCandidate;
  isBucketType: boolean;
  isTenantSalesAnalytics?: boolean;
  isGetRealogramCandidateLoading: boolean;
  initRerenderingSelectedProduct: boolean;
  setInitRerenderingSelectedProduct: (value: boolean) => void;
  compareQueryParams?: {
    id: number;
    type: CurrentSelectedType;
  };
  analyticsDataTerm: string;
  disableChangeToPreviousWeek: boolean;
  disableChangeToNextWeek: boolean;
  handleNextWeekTerm: () => void;
  handlePreviousWeekTerm: () => void;
  realogramStatistics?: Statistic;
};

export const statisticsWidth = 254;

export const Comparison: FC<Props> = ({
  shelfBoards,
  filteredShelfboards,
  primaryCandidates,
  view,
  mode,
  realogramCandidateId,
  createdAt,
  storeBayName,
  productTag,
  rate,
  report,
  handleChangeProductTag,
  handleChangeRate,
  comparisonProfitTabValue,
  setComparisonProfitTabValue,
  isImageLoading,
  handleChangeLoading,
  realogramCandidate,
  isBucketType,
  isTenantSalesAnalytics,
  isGetRealogramCandidateLoading,
  initRerenderingSelectedProduct,
  setInitRerenderingSelectedProduct,
  compareQueryParams,
  analyticsDataTerm,
  disableChangeToPreviousWeek,
  disableChangeToNextWeek,
  handleNextWeekTerm,
  handlePreviousWeekTerm,
  realogramStatistics,
}) => {
  const { isLarger } = useBreakpoint();
  const { open: isOpenModal } = useSelector(selectModalProps);
  const selectedRealogramItem = useAppSelector(selectRealogramSelectedItem);
  const dispatch = useAppDispatch();
  const { selectedProductCompartment } = useAppSelector(
    selectPlanogramEditorState
  );
  const { data } = useGetProductQuery(
    {
      productId: selectedRealogramItem?.item.primary_candidate?.product_id ?? 0,
    },
    {
      skip:
        !isInferredAsProduct(selectedRealogramItem?.item) ||
        !selectedRealogramItem?.item.primary_candidate?.product_id,
    }
  );
  const product = selectedRealogramItem
    ? data?.product
    : selectedProductCompartment;

  const {
    isLoading,
    comparedPlanogram,
    comparedRealogram,
    isForbidden,
    fetchGetPlanogram,
  } = useComparisonItemModal();

  const comparedRealogramShelfboards =
    comparedRealogram?.detail?.products_shelves.shelf_boards;
  const comparedPlanogramPlan = useMemo(
    () => comparedPlanogram?.plan,
    [comparedPlanogram?.plan]
  );

  const { currentSelectedItemId, currentSelectedType } = useAppSelector(
    selectComparisonItemModal
  );
  const { updateQueryParameter, removeQueryParameter } = useUrlQueryParams();

  const handleClickRealogramBbox = (item: RealogramSelectedItem) => {
    dispatch(updateProductPosition(undefined));
    dispatch(updateSelectedProductCompartment(undefined));

    if (isSelectedItem(view, item, selectedRealogramItem)) {
      dispatch(updateSelectedRealogramItem(undefined));
      removeQueryParameter('item');
      return;
    }
    dispatch(updateSelectedRealogramItem(item));
    updateQueryParameter('item', item.compartmentId);
  };

  const handleClickOutSideRealogramBbox = () => {
    if (selectedRealogramItem) {
      dispatch(updateSelectedRealogramItem(undefined));
      removeQueryParameter('item');
    }
  };

  const reset = () => {
    dispatch(updateProductPosition(undefined));
    dispatch(updateSelectedProductCompartment(undefined));
    dispatch(updateSelectedRealogramItem(undefined));
  };

  const {
    isLoadingAnalyticsData: isLoadingComparedAnalyticsData,
    analyticsDataTerm: comparedAnalyticsDataTerm,
    displayAnalyticsData: displayComparedAnalyticsData,
    disableChangeToPreviousWeek: disableChangeToComparedPreviousWeek,
    disableChangeToNextWeek: disableChangeToComparedNextWeek,
    handleNextWeekTerm: handleComparedNextWeekTerm,
    handlePreviousWeekTerm: handleComparedPreviousWeekTerm,
  } = useRealogramAnalyticsData(
    comparedRealogram?.id ?? 0,
    !comparedRealogram || !isTenantSalesAnalytics || comparedRealogram?.id === 0
  );

  const { filteredShelfBoards: comparedShelfBoards } = useExtractRealogramData(
    view,
    productTag,
    displayComparedAnalyticsData?.products ?? [],
    rate,
    comparedRealogramShelfboards
  );

  const allShelfBoards = useMemo(
    () => [...(comparedShelfBoards || []), ...(filteredShelfboards || [])],
    [comparedShelfBoards, filteredShelfboards]
  );

  useInitSelectedRealogram({
    selectedItem: selectedRealogramItem,
    shelfBoards: allShelfBoards,
    comparedPlanogramPlan: comparedPlanogramPlan as
      | PlanogramPlan
      | BucketPlanogramPlan,

    initSelectedData: {
      isSkipInit:
        isLoading ||
        isGetRealogramCandidateLoading ||
        isLoadingComparedAnalyticsData ||
        initRerenderingSelectedProduct,
      onInitCompleted: setInitRerenderingSelectedProduct,
      view,
      productTag,
    },
  });

  // First drawing: Item information is compared
  useEffect(() => {
    const { id: queryId, type: queryType } = compareQueryParams || {};
    const shouldSkip = !queryId || !queryType || isOpenModal;

    if (shouldSkip) return;

    if (queryType === 'plan') {
      dispatch(setCurrentTab(ComparisonTabModal.PLANOGRAM_TAB));
    } else if (queryType === 'actual') {
      dispatch(setCurrentTab(ComparisonTabModal.REALOGRAM_TAB));
    }
    dispatch(updateSelectedItemId(queryId));
    dispatch(updateCurrentSelectedItemId(queryId));
    dispatch(updateCurrentSelectedType(queryType));
  }, [compareQueryParams, isOpenModal, dispatch]);

  useEffect(() => {
    if (currentSelectedItemId?.toString() && currentSelectedType) {
      void fetchGetPlanogram(currentSelectedItemId, currentSelectedType);
    }
  }, [currentSelectedItemId, currentSelectedType, fetchGetPlanogram]);

  const comparisonProductIds = () => {
    if (comparedPlanogram) {
      return (
        comparedPlanogram?.plan ? getProductsLayout(comparedPlanogram.plan) : []
      )
        .flatMap(({ row }) => row.map(({ product_id }) => product_id))
        .sort()
        .join(',');
    }
    if (comparedShelfBoards) {
      return (comparedShelfBoards ?? [])
        ?.flatMap((shelfboard) =>
          shelfboard.compartments.flatMap((compartment) =>
            compartment.faces
              .filter((face) => isInferredAsProduct(face))
              .map((face) => face.primary_candidate?.product_id)
          )
        )
        .sort()
        .join(',');
    }
    return '';
  };

  const { data: comparisonProductsBulk } = useListProductsBulkQuery(
    { productIds: comparisonProductIds(), shape: true, detail: true },
    { skip: comparisonProductIds() === '' }
  );

  const { detailView } = useAppSelector(selectPlanogramEditorState);

  const comparedStatistics = useMemo(() => {
    if (comparedPlanogram) {
      return calcPlanStatistics(
        comparedPlanogram?.plan,
        detailView,
        productTag,
        comparisonProductsBulk?.products
      );
    }
    if (comparedRealogram) {
      return calculateRealogramStatistics(
        comparedRealogram.detail?.products_shelves.shelf_boards ?? [],
        primaryCandidates?.products
      );
    }
  }, [
    comparedPlanogram,
    comparedRealogram,
    detailView,
    productTag,
    comparisonProductsBulk?.products,
    primaryCandidates?.products,
  ]);

  // TODO: add const with defining which data to return planogram or realogram

  const comparedAnalytics = useMemo(() => {
    if (comparedRealogram) {
      return displayComparedAnalyticsData?.products.find(
        (product) =>
          product.product_id ===
            selectedRealogramItem?.item.primary_candidate?.product_id ||
          product.product_id === selectedProductCompartment?.id
      );
    }
    // TODO: add planogram data after api is ready
    if (comparedPlanogram) {
      return;
    }
  }, [
    displayComparedAnalyticsData,
    selectedRealogramItem,
    selectedProductCompartment,
    comparedPlanogram,
    comparedRealogram,
  ]);

  const originalRealogramAnalytics = useMemo(() => {
    return report?.products.find(
      (product) =>
        product.product_id ===
        selectedRealogramItem?.item.primary_candidate?.product_id
    );
  }, [report, selectedRealogramItem]);

  return (
    <>
      <Divider orientation="horizontal" flexItem />
      <Box
        component="div"
        display="flex"
        flexDirection={{ xs: 'column', breakpoint: 'row' }}
        flex={1}
      >
        <Box component="div" flex={1} overflow="hidden" display="flex">
          <RealogramOrPlanogramImages
            realogramCandidateId={realogramCandidateId}
            shelfBoards={filteredShelfboards}
            comparedPlanogram={comparedPlanogram}
            comparedRealogram={comparedRealogram}
            comparedShelfboards={comparedShelfBoards}
            isLoading={isLoading}
            handleClickRealogramBbox={handleClickRealogramBbox}
            handleClickOutSideRealogramBbox={handleClickOutSideRealogramBbox}
            view={view}
            mode={mode}
            createdAt={createdAt}
            storeBayName={storeBayName}
            rate={rate}
            profitTab={comparisonProfitTabValue}
            productReports={report?.products}
            isImageLoading={isImageLoading}
            handleChangeLoading={handleChangeLoading}
            realogramCandidate={realogramCandidate}
            currentSelectedType={currentSelectedType}
            report={report}
            isForbidden={isForbidden}
            isBucketType={isBucketType}
            isTenantSalesAnalytics={isTenantSalesAnalytics}
            analyticsDataTerm={analyticsDataTerm}
            disableChangeToPreviousWeek={disableChangeToPreviousWeek}
            disableChangeToNextWeek={disableChangeToNextWeek}
            handleNextWeekTerm={handleNextWeekTerm}
            handlePreviousWeekTerm={handlePreviousWeekTerm}
            comparedAnalyticsData={displayComparedAnalyticsData}
            comparedAnalyticsDataTerm={comparedAnalyticsDataTerm}
            disableChangeToComparedPreviousWeek={
              disableChangeToComparedPreviousWeek
            }
            disableChangeToComparedNextWeek={disableChangeToComparedNextWeek}
            handleComparedNextWeekTerm={handleComparedNextWeekTerm}
            handleComparedPreviousWeekTerm={handleComparedPreviousWeekTerm}
          />
        </Box>
        <Divider orientation={isLarger ? 'vertical' : 'horizontal'} flexItem />
        <Box
          component="div"
          p={2}
          minWidth={{ xs: 'unset', breakpoint: `${statisticsWidth}px` }}
          maxWidth={{ xs: 'unset', breakpoint: `${statisticsWidth}px` }}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          {selectedRealogramItem || selectedProductCompartment ? (
            <Box component="div">
              <Button
                variant="outlined"
                sx={{
                  width: '72px',
                  height: '32px',
                  padding: '0 12px',
                  border: `1px solid ${theme.palette.primary.main}`,
                  '& .MuiButton-startIcon': { margin: '0' },
                }}
                startIcon={
                  <ArrowBackIos
                    sx={{
                      color: theme.palette.primary.main,
                      width: '1rem',
                      height: '1rem',
                    }}
                    fontSize="small"
                  />
                }
                onClick={() => reset()}
              >
                戻る
              </Button>
              <ComparisonProduct
                view={view}
                product={product}
                realograms={shelfBoards}
                comparisonPlanogram={comparedPlanogram}
                comparisonRealogram={comparedRealogramShelfboards}
                selectedRealogramItem={selectedRealogramItem}
                comparedAnalyticsData={comparedAnalytics}
                analyticsData={originalRealogramAnalytics}
              />
            </Box>
          ) : (
            <Box component="div">
              {isLoading && (
                <CircularSpinner
                  sx={{ display: 'flex', alignItems: 'center' }}
                />
              )}
              {!isLoading && (
                <ComparisonStatistics
                  view={view}
                  shelfBoards={shelfBoards}
                  productTag={productTag}
                  handleChangeProductTag={handleChangeProductTag}
                  rate={rate}
                  handleChangeRate={handleChangeRate}
                  realogramStatistics={realogramStatistics}
                  comparedStatistics={comparedStatistics}
                  report={report}
                  comparedReport={displayComparedAnalyticsData}
                  comparisonProfitTabValue={comparisonProfitTabValue}
                  setComparisonProfitTabValue={setComparisonProfitTabValue}
                  isFlatPlanogram={isBucketType}
                  isComparedWithPlanogram={!!comparedPlanogram}
                />
              )}
            </Box>
          )}
          {mode === 'comparison' &&
            (view === 'default' || view === 'productFlag') && (
              <Box component="div" display="flex" gap="4px" alignItems="center">
                <Box
                  component="div"
                  borderRadius="2px"
                  width={16}
                  height={16}
                  sx={{
                    backgroundColor: theme.palette.confidence.low,
                  }}
                />
                <Typography
                  color={theme.palette.textBlack.secondary}
                  fontSize={12}
                >
                  片方のみにあるアイテム
                </Typography>
              </Box>
            )}
        </Box>
      </Box>
    </>
  );
};
