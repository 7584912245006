import { yupResolver } from '@hookform/resolvers/yup';
import { GetUsersQueryParams, useGetUsersQuery } from '@reducers/shelfAppsApi';
import {
  removeFirstLastSpaces,
  rowsPerPage,
  userRoleTags,
  userStatusTags,
} from '@utils/const';
import { useState } from 'react';
import { Control, UseFormHandleSubmit, useForm } from 'react-hook-form';
import { UserOrder } from 'types/user';
import { InferType, object, string } from 'yup';
import { AssertsShape } from 'yup/lib/object';
import { RequiredStringSchema } from 'yup/lib/string';
import { AnyObject } from 'yup/lib/types';

const schema = object({
  searchText: string().required(),
});

export type UserListFormData = InferType<typeof schema>;

type Shape = {
  searchText: RequiredStringSchema<string | undefined, AnyObject>;
};

export type UserListControl = Control<AssertsShape<Shape>>;
export type UserListSubmit = UseFormHandleSubmit<AssertsShape<Shape>>;

export const useListUsers = () => {
  const [isFocused, setIsFocused] = useState(false);

  const [condition, setCondition] = useState<GetUsersQueryParams>({
    firstOrder: 'created_at_desc',
    scope: 'organization_employee_with_deleted',
  });
  const [offset, setOffset] = useState(0);
  const [selectedUserRoleTags, setSelectedUserRoleTags] = useState<string[]>(
    []
  );
  const [selectedUserStatusTags, setSelectedUserStatusTags] = useState<
    string[]
  >([]);
  const { control, handleSubmit, setValue } = useForm<UserListFormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      searchText: '',
    },
  });

  const { data, isLoading, isFetching } = useGetUsersQuery(
    {
      limit: rowsPerPage,
      offset,
      ...condition,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const reset = () => {
    setIsFocused(false);
    setOffset(0);
    setValue('searchText', '');
    setCondition({
      ...condition,
      fullname: undefined,
      q: undefined,
      offset: 0,
    });
  };

  const resetFiltering = () => {
    setCondition({
      ...condition,
      deletedUser: false,
      role: undefined,
      scope: 'organization_employee_with_deleted',
      offset: 0,
    });
    setSelectedUserRoleTags([]);
    setSelectedUserStatusTags([]);
  };

  const onSubmit = (data: UserListFormData) => {
    const text = removeFirstLastSpaces(data.searchText);
    if (text === '') return;
    setOffset(0);

    setCondition({
      ...condition,
      q: text,
      offset: 0,
    });
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleEndReached = (index: number) => {
    if (isLoading || isFetching || !data?.pager.total) return;
    const offset = index + 1;
    if (offset >= data.pager.total) return;
    setOffset(offset);
    setCondition({ ...condition, offset: offset });
  };

  const handleChangeOrder = (firstOrder: UserOrder) => {
    setOffset(0);
    setCondition({ ...condition, firstOrder, offset: 0 });
  };

  const handleChangeSelectedUserRoleTags = (tags?: string[]) => {
    setSelectedUserRoleTags(tags ?? []);
    setOffset(0);
    if (tags?.length === userRoleTags.length || tags?.length === 0) {
      setCondition({
        ...condition,
        role: undefined,
        offset: 0,
      });
    } else if (tags?.length && tags.includes('admin')) {
      setCondition({
        ...condition,
        role: 'admin',
        offset: 0,
      });
    } else {
      setCondition({
        ...condition,
        role: 'user',
        offset: 0,
      });
    }
  };

  const handleChangeSelectedUserStatusTags = (tags?: string[]) => {
    setSelectedUserStatusTags(tags ?? []);
    setOffset(0);
    if (tags?.length === userStatusTags.length || tags?.length === 0) {
      setCondition({
        ...condition,
        scope: 'organization_employee_with_deleted',
        deletedUser: false,
        offset: 0,
      });
    } else if (tags?.length && tags.includes('active')) {
      setCondition({
        ...condition,
        scope: 'organization_employee',
        deletedUser: false,
        offset: 0,
      });
    } else {
      setCondition({
        ...condition,
        scope: 'organization_employee_with_deleted',
        deletedUser: true,
        offset: 0,
      });
    }
  };

  return {
    control,
    handleSubmit,
    data,
    isFocused,
    selectedUserRoleTags,
    selectedUserStatusTags,
    isLoading,
    isFetching,
    condition,
    offset,
    reset,
    resetFiltering,
    onSubmit,
    handleFocus,
    handleEndReached,
    handleChangeOrder,
    handleChangeSelectedUserRoleTags,
    handleChangeSelectedUserStatusTags,
  };
};
