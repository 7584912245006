import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { CheckCircleOutline } from '@mui/icons-material';
import { mfaDeviceResponse } from '../../utils/const';
import { paths } from '@utils/const';

type Props = {
  preferredMfaSetting?: string | undefined;
  isMfaDeviceLoading: boolean;
  setIsPasswordInputOpen: () => void;
  isSAMLEnable: boolean;
  isTenantsPublicInfoLoading: boolean;
};

export const AccountMfa: FC<Props> = ({
  preferredMfaSetting,
  isMfaDeviceLoading,
  setIsPasswordInputOpen,
  isSAMLEnable,
  isTenantsPublicInfoLoading,
}) => {
  const navigate = useNavigate();
  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 3,
      }}
    >
      <Typography variant="h6" fontWeight="bold">
        二要素認証
      </Typography>
      <Typography fontSize={14}>
        二要素認証を設定すると、より安全にログインできます。
        {isSAMLEnable && !isTenantsPublicInfoLoading && (
          <>
            <br />
            （SSOでのログインの場合、設定は不要です。）
          </>
        )}
      </Typography>
      {!preferredMfaSetting && !isMfaDeviceLoading && (
        <Button
          variant="outlined"
          onClick={() => {
            navigate(paths.account.mfa);
          }}
          disabled={!!preferredMfaSetting}
        >
          デバイスを登録
        </Button>
      )}
      {preferredMfaSetting === mfaDeviceResponse && (
        <Box
          component="div"
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <CheckCircleOutline sx={{ width: 24, height: 24, fill: '#4CAF50' }} />
          <Typography ml={1} mr={2}>
            デバイス登録済み
          </Typography>
          <Button color="error" onClick={setIsPasswordInputOpen}>
            削除
          </Button>
        </Box>
      )}
    </Box>
  );
};
