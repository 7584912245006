import { Box, SxProps, Theme, Typography } from '@mui/material';
import { FC } from 'react';
import { theme } from 'theme';

type Props = {
  children?: React.ReactNode;
  name: string;
  bayName: string;
  targetCompareText?: string;
  isRowStyleDirection?: boolean;
  sx?: SxProps<Theme>;
};
export const BayHeader: FC<Props> = ({
  children,
  name,
  bayName,
  targetCompareText,
  isRowStyleDirection,
  sx = {},
}) => {
  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        alignItems: 'center',
        paddingX: '12px',
        paddingY: '8px',
        height: isRowStyleDirection ? '50px' : '44px',
        flexGrow: isRowStyleDirection ? 1 : 'auto',
        borderRight: isRowStyleDirection
          ? `1px solid ${theme.palette.dividerBlack.light}`
          : undefined,
        borderBottom: isRowStyleDirection
          ? undefined
          : `1px solid ${theme.palette.dividerBlack.light}`,
        ...sx,
      }}
    >
      <Box component="div" sx={{ minWidth: 0 }}>
        <Typography
          variant="subtitle2"
          lineHeight="100%"
          noWrap
          sx={{ marginBottom: '4px' }}
        >
          {name}
        </Typography>
        <Box component="div" sx={{ display: 'flex', alignItems: 'center' }}>
          {targetCompareText && (
            <Typography
              variant="caption1"
              lineHeight="100%"
              sx={{
                color: theme.palette.primary.main,
                backgroundColor: theme.palette.primary.background,
                border: `1px solid ${theme.palette.shelf.selectOutline}`,
                borderRadius: '2px',
                height: '18px',
                width: '52px',
                lineHeight: '16px',
                marginRight: '8px',
                textAlign: 'center',
              }}
            >
              {targetCompareText}
            </Typography>
          )}
          <Typography
            variant="body3"
            lineHeight="100%"
            noWrap
            sx={{ color: theme.palette.textBlack.secondary }}
          >
            {bayName}
          </Typography>
        </Box>
      </Box>
      {children}
    </Box>
  );
};
