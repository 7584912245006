import { Box, SxProps, Theme } from '@mui/material';
import { FC } from 'react';
import { theme } from 'theme';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { BayHeader } from './fragments/bayHeader';

export type HeaderStyleDirection = 'column' | 'row';

type Props = {
  children?: React.ReactNode;
  bayHeaderActions?: React.ReactNode;
  name: string;
  bayName: string;
  targetCompareText?: string;
  headerStyleDirection?: HeaderStyleDirection;
  sx?: SxProps<Theme>;
};
export const ComparisonBayHeader: FC<Props> = ({
  children,
  bayHeaderActions,
  name,
  bayName,
  targetCompareText,
  headerStyleDirection,
  sx = {},
}) => {
  const { isLarger } = useBreakpoint();
  const isRowStyleDirection = headerStyleDirection === 'row';
  return (
    <Box
      component="div"
      display="flex"
      flexDirection={isRowStyleDirection ? 'row' : 'column'}
      sx={{
        borderBottom: `1px solid ${theme.palette.dividerBlack.light}`,
        ...sx,
      }}
    >
      <BayHeader
        isRowStyleDirection={isRowStyleDirection}
        name={name}
        bayName={bayName}
        targetCompareText={targetCompareText}
        sx={{
          ...(isRowStyleDirection && {
            width: isLarger ? 'calc(100% - 360px)' : 'calc(100% - 320px)',
          }),
        }}
      >
        {bayHeaderActions && (
          <Box
            component="div"
            display="flex"
            alignItems="center"
            gap={1}
            sx={{ marginLeft: 'auto', paddingLeft: '8px' }}
          >
            {bayHeaderActions}
          </Box>
        )}
      </BayHeader>
      {children && (
        <Box
          component="div"
          py="8px"
          px="12px"
          sx={{
            display: 'flex',
            height: '50px',
            ...(isRowStyleDirection && { width: '600px' }),
          }}
        >
          {children}
        </Box>
      )}
    </Box>
  );
};
