import { useBreakpoint } from '@hooks/useBreakpoint';
import { Box, Typography } from '@mui/material';
import {
  emptyText,
  formatNumberToYen,
  getProfitTabsValueByKey,
  profitTabsKey,
  profitTabSales,
  profitTabQuantity,
  profitTabGrossProfit,
} from '@utils/const';
import { ProfitTab } from 'types/common';
import { FC } from 'react';
import { theme } from 'theme';
import { ProductSalesReport } from 'types/products';
import { SelectableCard } from '../selectableCard/selectableCard';
import { t } from 'i18next';
import { StyledIconButton } from '@components/molecules/faceAreaControllerBox/fragments/createdAt';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';

type Props = {
  term?: string;
  profitTab: ProfitTab;
  setProfitTab: (profitTab: ProfitTab) => void;
  analyticsDataTerm?: string;
  displayAnalyticsData?: ProductSalesReport;
  disableChangeToPreviousWeek?: boolean;
  disableChangeToNextWeek?: boolean;
  handleNextWeekTerm?: () => void;
  handlePreviousWeekTerm?: () => void;
};

export const ProfitStatistics: FC<Props> = ({
  term,
  profitTab,
  setProfitTab,
  analyticsDataTerm,
  displayAnalyticsData,
  disableChangeToPreviousWeek = false,
  disableChangeToNextWeek = false,
  handleNextWeekTerm,
  handlePreviousWeekTerm,
}) => {
  const totalGrossSalesPrice = displayAnalyticsData?.summary
    ?.total_gross_sales_price
    ? formatNumberToYen(
        displayAnalyticsData?.summary?.total_gross_sales_price ?? 0
      )
    : '';
  const totalSalesCount =
    displayAnalyticsData?.summary?.total_sales_count ?? '';
  const totalGrossSalesProfit = displayAnalyticsData?.summary
    ?.total_gross_profit_price
    ? formatNumberToYen(
        displayAnalyticsData?.summary?.total_gross_profit_price ?? 0
      )
    : '';
  const { isLarger } = useBreakpoint();
  const flexDirection = isLarger ? 'row' : 'column';
  const termWidth = 167;
  const selectableCardGap = 3;
  return (
    <Box
      component="div"
      display="flex"
      flexDirection={flexDirection}
      alignItems={isLarger ? 'center' : 'flex-start'}
      gap={1}
      p={1}
      borderTop={
        isLarger ? '' : `1px solid ${theme.palette.dividerBlack.medium}`
      }
    >
      <Box component="div">
        {analyticsDataTerm ? (
          <Box component="div">
            <Typography
              variant="body3"
              color={theme.palette.textBlack.secondary}
            >
              参照期間
            </Typography>

            <Box
              component="div"
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <StyledIconButton
                disabled={disableChangeToPreviousWeek}
                onClick={handlePreviousWeekTerm}
              >
                <KeyboardArrowLeft fontSize="small" />
              </StyledIconButton>
              <Typography
                variant="body2"
                color={theme.palette.textBlack.primary}
                width={isLarger ? termWidth : '100%'}
                textAlign="center"
              >
                {analyticsDataTerm}
              </Typography>
              <StyledIconButton
                disabled={disableChangeToNextWeek}
                onClick={handleNextWeekTerm}
              >
                <KeyboardArrowRight fontSize="small" />
              </StyledIconButton>
            </Box>
          </Box>
        ) : (
          <Typography
            variant="body2"
            color={theme.palette.textBlack.table}
            width={isLarger ? termWidth : '100%'}
            p={1}
          >
            {term}
          </Typography>
        )}
      </Box>
      <Box component="div" display="flex" gap={1}>
        {profitTabsKey.map((item, index) => {
          const isSelected = profitTab === item;
          const profitValue =
            item === profitTabSales
              ? totalGrossSalesPrice
              : item === profitTabQuantity
              ? totalSalesCount
              : item === profitTabGrossProfit
              ? totalGrossSalesProfit
              : '';
          const isHasProfitValue = !!(profitValue && profitValue.toString());
          return (
            <Box component="div" key={index} minWidth={112}>
              <SelectableCard
                selected={isSelected}
                onClick={() => setProfitTab(item)}
                sx={{
                  p: 1,
                  alignItems: 'unset',
                  flexDirection: flexDirection,
                  justifyContent: 'space-between',
                  height: isLarger ? '34px' : '54px',
                  whiteSpace: 'nowrap',
                  gap: isLarger ? selectableCardGap : 1,
                }}
              >
                <Typography
                  variant="body3"
                  color={theme.palette.textBlack.secondary}
                  textAlign="start"
                  lineHeight="12px"
                >
                  {getProfitTabsValueByKey(item, t('gross_profit'))}
                </Typography>
                {isHasProfitValue ? (
                  <Typography
                    fontSize={18}
                    fontWeight={500}
                    textAlign="end"
                    color={theme.palette.textBlack.primary}
                    lineHeight="16px"
                  >
                    {profitValue}
                  </Typography>
                ) : (
                  <Typography
                    variant="body1"
                    textAlign="end"
                    color={theme.palette.textBlack.disabled}
                    lineHeight="16px"
                  >
                    {emptyText}
                  </Typography>
                )}
              </SelectableCard>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
