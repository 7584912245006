import { FormPaper } from '@components/molecules/formPaper/formPaper';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { selectEmail } from '@reducers/auth/selectors';
import { useGetTenantsQuery } from '@reducers/shelfAppsApi/injections/tenantsApi';
import { useAppSelector } from '@store/index';
import { QRCodeSVG } from 'qrcode.react';
import { FC, useMemo } from 'react';
import { CancelButton } from './cancelButton';

type Props = {
  secretCode?: string;
  isInitial: boolean;
  next: () => void;
};

export const AuthAppAddition: FC<Props> = ({ next, secretCode, isInitial }) => {
  const email = useAppSelector(selectEmail);

  const { data, isLoading } = useGetTenantsQuery();
  const qrCodeValue = useMemo(() => {
    if (!data || !email || !secretCode) {
      return;
    }
    const tenantKey = data.tenant.tenant_key;
    const environment = data.environment;

    let tenantAndEnv = '';
    if (environment === 'dev') {
      tenantAndEnv = `[${tenantKey}:dev]`;
    } else if (environment === 'staging') {
      tenantAndEnv = `[${tenantKey}:stg]`;
    } else {
      tenantAndEnv = `[${tenantKey}]`;
    }

    return `otpauth://totp/Shelf%20Apps%20${encodeURIComponent(
      tenantAndEnv
    )}%20%28${email}%29?secret=${secretCode}`;
  }, [email, secretCode, data]);

  const renderQRCodeContent = () => {
    if (isLoading) return <CircularProgress />;
    if (qrCodeValue)
      return (
        <QRCodeSVG width={120} height={120} level="H" value={qrCodeValue} />
      );
    return <>データを表示できません。</>;
  };

  return (
    <FormPaper title="Authenticatorの登録">
      <Typography mb={3}>
        インストールしたアプリケーションを起動し、＋ボタンを押して QR
        コードを読み取ってください。
      </Typography>
      <Box component="div" textAlign="center">
        {renderQRCodeContent()}
      </Box>
      <Button variant="contained" sx={{ my: 3 }} fullWidth onClick={next}>
        次へ
      </Button>
      <CancelButton isInitial={isInitial} />
    </FormPaper>
  );
};
