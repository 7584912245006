import { Box } from '@mui/material';
import { selectPlanogramEditorState } from '@reducers/planogramEditor/selectors';
import { useAppSelector } from '@store/index';
import { FC } from 'react';
import { PlanogramPlan } from 'types/planogram';
import { Planogram } from '../planogram/planogram';
import { theme } from 'theme';

type Props = {
  plan: PlanogramPlan;
  scale: number;
  handleClickAway: () => void;
  isEditor?: boolean;
  bboxEnabled?: boolean;
};

export const ZoomablePlanogram: FC<Props> = ({
  plan,
  scale,
  handleClickAway,
  isEditor,
  bboxEnabled,
}) => {
  const { detailMode, detailView } = useAppSelector(selectPlanogramEditorState);
  return (
    <Box
      component="div"
      sx={{
        flex: 1,
        width: '100%',
        background: theme.palette.shelf.backgroundTana,
        overflow: 'auto',
      }}
    >
      <Box
        component="div"
        sx={{
          height: '100%',
          pt: 6,
        }}
      >
        <Box
          component="div"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            transform: `scale(${scale},${scale})`,
            transformOrigin: 'top center',
            alignItems: 'center',
            height: { xs: '68vh', breakpoint: '100%' },
          }}
          onClick={handleClickAway}
        >
          <Planogram
            bboxEnabled={bboxEnabled}
            plan={plan}
            detailMode={detailMode}
            detailView={detailView}
            isEditor={isEditor}
          />
        </Box>
      </Box>
    </Box>
  );
};
