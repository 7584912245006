import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { FC } from 'react';
import { theme } from 'theme';

type Props = {
  selectNext: () => void;
  selectPrevious: () => void;
  shouldButtonBeDisabled: (value: 'next' | 'prev') => boolean;
};

export const SlideButtons: FC<Props> = ({
  shouldButtonBeDisabled,
  selectNext,
  selectPrevious,
}) => {
  return (
    <Box
      component="div"
      sx={{
        width: '100%',
        justifyContent: 'flex-end',
      }}
    >
      <Box component="div" display="flex" flexDirection="row-reverse">
        <IconButton
          disabled={shouldButtonBeDisabled('next')}
          onClick={selectNext}
          sx={{ color: theme.palette.icons.primary, margin: '0 0 0 4px' }}
        >
          <ArrowForwardIos />
        </IconButton>
        <IconButton
          disabled={shouldButtonBeDisabled('prev')}
          onClick={selectPrevious}
          sx={{ color: theme.palette.icons.primary, margin: '0 4px 0 0' }}
        >
          <ArrowBackIosNew />
        </IconButton>
      </Box>
    </Box>
  );
};
