import { ProfitTab, Rate } from 'types/common';
import {
  emptyText,
  formatNumberToYen,
  productTags,
  profitTabGrossProfit,
  profitTabQuantity,
  profitTabSales,
} from './const';
import { ProductReportProduct, ProductSalesReport } from 'types/products';
import { t } from 'i18next';
import { getComparisonStatisticType } from './comparison';
import { maxValueInReport } from './product';
import { ColumnType } from '@components/organisms/statisticBox/statisticBox';
import { Statistic } from 'types/statistics';

const calcDiff = (targetA?: string, targetB?: string) => {
  return targetA && targetB
    ? (parseInt(targetB) - parseInt(targetA)).toString()
    : '';
};
// 棚割
export const createDefaultValue = (
  isFlatPlanogram: boolean,
  statistics?: Statistic,
  comparisonStatistics?: Statistic,
  isComparedWithPlanogram = false
) => {
  const shelvesDiff = calcDiff(
    comparisonStatistics?.totalShelves,
    statistics?.totalShelves
  );
  const shelvesStatisticType = getComparisonStatisticType(+shelvesDiff);

  const itemDiff = calcDiff(
    comparisonStatistics?.totalUniques,
    statistics?.totalUniques
  );
  const itemStatisticType = getComparisonStatisticType(+itemDiff);

  const outOfStockDiff = calcDiff(
    comparisonStatistics?.totalUniques,
    statistics?.totalUniques
  );
  const outOfStockStatisticType = getComparisonStatisticType(+outOfStockDiff);

  const unknownDiff = calcDiff(
    comparisonStatistics?.totalUnknowns,
    statistics?.totalUnknowns
  );
  const unknownStatisticType = getComparisonStatisticType(+unknownDiff);

  const isTotalOutOfStocksEmpty =
    !comparisonStatistics ||
    (isComparedWithPlanogram && comparisonStatistics?.totalOutOfStocks === '0');
  const isTotalUnknownsEmpty =
    !comparisonStatistics ||
    (isComparedWithPlanogram && comparisonStatistics?.totalUnknowns === '0');
  return [
    {
      title: isFlatPlanogram ? 'カゴ数' : '段数',
      statisticType: shelvesStatisticType,
      columnType: 'positive',
      value: statistics?.totalShelves || emptyText,
      prevValue: comparisonStatistics?.totalShelves || emptyText,
      diffValue: shelvesDiff,
      isPrevValueEmpty: !comparisonStatistics?.totalShelves,
      isValueEmpty: !statistics?.totalShelves,
    },
    {
      title: 'アイテム数',
      statisticType: itemStatisticType,
      columnType: 'positive',
      value: statistics?.totalUniques || emptyText,
      prevValue: comparisonStatistics?.totalUniques || emptyText,
      diffValue: itemDiff,
      isPrevValueEmpty: !comparisonStatistics?.totalUniques,
      isValueEmpty: !statistics?.totalUniques,
    },
    {
      title: '欠品区画数',
      statisticType: outOfStockStatisticType,
      columnType: 'negative',
      value: statistics?.totalOutOfStocks || emptyText,
      prevValue: isTotalOutOfStocksEmpty
        ? emptyText
        : comparisonStatistics?.totalOutOfStocks,
      diffValue: outOfStockDiff,
      isPrevValueEmpty:
        isTotalOutOfStocksEmpty || !comparisonStatistics?.totalOutOfStocks,
      isValueEmpty: !statistics?.totalOutOfStocks,
    },
    {
      title: '不明な商品フェイス数',
      statisticType: unknownStatisticType,
      columnType: 'negative',
      value: statistics?.totalUnknowns || emptyText,
      prevValue: isTotalUnknownsEmpty
        ? emptyText
        : comparisonStatistics?.totalUnknowns,
      diffValue: unknownDiff,
      isPrevValueEmpty:
        isTotalUnknownsEmpty || !comparisonStatistics?.totalUnknowns,
      isValueEmpty: !statistics?.totalUnknowns,
    },
  ];
};
// 商品属性
export const createProductTagInformation = (
  statistics?: Statistic,
  comparisonStatistics?: Statistic
) => {
  const totalNewFacesFirstWeekDiff = calcDiff(
    comparisonStatistics?.productFlag.totalNewFacesFirstWeek,
    statistics?.productFlag.totalNewFacesFirstWeek
  );
  const totalNewFacesSecondWeekDiff = calcDiff(
    comparisonStatistics?.productFlag?.totalNewFacesSecondWeek,
    statistics?.productFlag?.totalNewFacesSecondWeek
  );
  const totalMainFacesDiff = calcDiff(
    comparisonStatistics?.productFlag?.totalMainFaces,
    statistics?.productFlag?.totalMainFaces
  );
  const totalRecommendedToCancelationFacesDiff = calcDiff(
    comparisonStatistics?.productFlag.totalRecomendedToCancelationFaces,
    statistics?.productFlag.totalRecomendedToCancelationFaces
  );
  const totalNot3DScannedFacesDiff = calcDiff(
    comparisonStatistics?.productFlag.totalNot3DScannedFaces,
    statistics?.productFlag.totalNot3DScannedFaces
  );
  return [
    {
      title: productTags[0],
      prevValue:
        comparisonStatistics?.productFlag?.totalNewFacesFirstWeek || emptyText,
      value: statistics?.productFlag?.totalNewFacesFirstWeek || emptyText,
      diffValue: totalNewFacesFirstWeekDiff ?? '',
      statisticType: getComparisonStatisticType(
        parseInt(totalNewFacesFirstWeekDiff ?? '')
      ),
      isPrevValueEmpty:
        !comparisonStatistics?.productFlag?.totalNewFacesFirstWeek,
      isValueEmpty: !statistics?.productFlag?.totalNewFacesFirstWeek,
      columnType: 'neutral' as ColumnType,
    },
    {
      title: productTags[1],
      prevValue:
        comparisonStatistics?.productFlag?.totalNewFacesSecondWeek || emptyText,
      value: statistics?.productFlag?.totalNewFacesSecondWeek || emptyText,
      diffValue: totalNewFacesSecondWeekDiff ?? '',
      statisticType: getComparisonStatisticType(
        parseInt(totalNewFacesSecondWeekDiff ?? '')
      ),
      isPrevValueEmpty:
        !comparisonStatistics?.productFlag?.totalNewFacesSecondWeek,
      isValueEmpty: !statistics?.productFlag?.totalNewFacesSecondWeek,
      columnType: 'neutral' as ColumnType,
    },
    {
      title: productTags[2],
      prevValue: comparisonStatistics?.productFlag?.totalMainFaces || emptyText,
      value: statistics?.productFlag?.totalMainFaces || emptyText,
      diffValue: totalMainFacesDiff ?? '',
      statisticType: getComparisonStatisticType(
        parseInt(totalMainFacesDiff ?? '')
      ),
      isPrevValueEmpty: !comparisonStatistics?.productFlag?.totalMainFaces,
      isValueEmpty: !statistics?.productFlag?.totalMainFaces,
      columnType: 'neutral' as ColumnType,
    },
    {
      title: productTags[3],
      prevValue:
        comparisonStatistics?.productFlag?.totalRecomendedToCancelationFaces ||
        emptyText,
      value:
        statistics?.productFlag?.totalRecomendedToCancelationFaces || emptyText,
      diffValue: totalRecommendedToCancelationFacesDiff ?? '',
      statisticType: getComparisonStatisticType(
        parseInt(totalRecommendedToCancelationFacesDiff ?? '')
      ),
      isPrevValueEmpty:
        !comparisonStatistics?.productFlag?.totalRecomendedToCancelationFaces,
      isValueEmpty: !statistics?.productFlag?.totalRecomendedToCancelationFaces,
      columnType: 'negative' as ColumnType,
    },
    {
      title: productTags[4],
      prevValue:
        comparisonStatistics?.productFlag?.totalNot3DScannedFaces || emptyText,
      value: statistics?.productFlag?.totalNot3DScannedFaces || emptyText,
      diffValue: totalNot3DScannedFacesDiff ?? '',
      statisticType: getComparisonStatisticType(
        parseInt(totalNot3DScannedFacesDiff ?? '')
      ),
      isPrevValueEmpty:
        !comparisonStatistics?.productFlag?.totalNot3DScannedFaces,
      isValueEmpty: !statistics?.productFlag?.totalNot3DScannedFaces,
      columnType: 'negative' as ColumnType,
    },
  ];
};

// related to 分析
export const createComparisonProfitValue = (
  report?: ProductSalesReport,
  comparedReport?: ProductSalesReport
) => {
  const totalGrossSalesPriceDiff = calcDiff(
    comparedReport?.summary.total_gross_sales_price?.toString(),
    report?.summary.total_gross_sales_price?.toString()
  );

  const totalSalesCountDiff = calcDiff(
    comparedReport?.summary.total_sales_count?.toString(),
    report?.summary.total_sales_count?.toString()
  );

  const totalGrossProfitPriceDiff = calcDiff(
    comparedReport?.summary.total_gross_profit_price?.toString(),
    report?.summary.total_gross_profit_price?.toString()
  );
  const divideBy100 = 100;
  const totalGrossProfitPricePercent =
    typeof totalGrossProfitPriceDiff === 'number'
      ? Math.round(
          (totalGrossProfitPriceDiff /
            (comparedReport?.summary.total_gross_profit_price ?? 0)) *
            divideBy100
        )
      : '';

  const getMaxValue = (
    key: 'gross_sales_price' | 'sales_count' | 'gross_profit_price',
    reportProduct?: ProductReportProduct[],
    comparedReportProduct?: ProductReportProduct[]
  ) => {
    const reportMax = reportProduct ? maxValueInReport(key, reportProduct) : 0;
    const comparedReportMax = comparedReportProduct
      ? maxValueInReport(key, comparedReportProduct)
      : 0;
    return reportMax > comparedReportMax ? reportMax : comparedReportMax;
  };
  return [
    {
      title: '売上金額見込み',
      prevValue: comparedReport?.summary.total_gross_sales_price
        ? formatNumberToYen(comparedReport.summary.total_gross_sales_price)
        : '',
      statisticType: getComparisonStatisticType(+totalGrossSalesPriceDiff),
      value: report?.summary.total_gross_sales_price
        ? formatNumberToYen(report.summary.total_gross_sales_price)
        : '',
      diff:
        totalGrossSalesPriceDiff !== ''
          ? formatNumberToYen(+totalGrossSalesPriceDiff)
          : '',
      percent: '',
      min: formatNumberToYen(0),
      max: formatNumberToYen(
        getMaxValue(
          'gross_sales_price',
          report?.products,
          comparedReport?.products
        )
      ),
      isTotalGrossSalesPrice: true,
      key: profitTabSales as ProfitTab,
    },
    {
      title: '販売数見込み',
      statisticType: getComparisonStatisticType(+totalSalesCountDiff),
      prevValue: comparedReport?.summary.total_sales_count?.toString() ?? '',
      value: report?.summary.total_sales_count?.toString() ?? '',
      diff: totalSalesCountDiff.toString(),
      percent: '',
      min: '0',
      max: getMaxValue(
        'sales_count',
        report?.products,
        comparedReport?.products
      ).toString(),
      key: profitTabQuantity as ProfitTab,
    },
    {
      title: t('gross_profit') + '見込み',
      statisticType: getComparisonStatisticType(+totalGrossProfitPriceDiff),
      prevValue: comparedReport?.summary.total_gross_profit_price
        ? formatNumberToYen(comparedReport.summary.total_gross_profit_price)
        : '',
      value: report?.summary.total_gross_profit_price
        ? formatNumberToYen(report.summary.total_gross_profit_price)
        : '',
      diff:
        totalGrossProfitPriceDiff !== ''
          ? formatNumberToYen(+totalGrossProfitPriceDiff)
          : '',
      percent: totalGrossProfitPricePercent.toString(),
      min: formatNumberToYen(0),
      max: formatNumberToYen(
        getMaxValue(
          'gross_profit_price',
          report?.products,
          comparedReport?.products
        )
      ),
      key: profitTabGrossProfit as ProfitTab,
    },
    // {
    //   title: '平均単価見込み',
    //   // Todo: バックエンド実装され次第実装
    //   statisticType: 'neutral',
    //   prevValue: '',
    //   value: '',
    //   diff: '',
    //   percent: '',
    //   key: '',
    // },
  ];
};

// 評価
// 棚割計画の場合データがない項目があるのでAPIが出来たら修正する
export const createRateValues = (
  comparisonStatistics?: Statistic,
  realogramStatistics?: Statistic,
  isComparedWithPlanogram = false
) => {
  const isTotalOutOfStocksEmpty =
    !comparisonStatistics ||
    (isComparedWithPlanogram && comparisonStatistics?.totalOutOfStocks === '0');
  const isTotalUnknownsEmpty =
    !comparisonStatistics ||
    (isComparedWithPlanogram && comparisonStatistics?.totalUnknowns === '0');
  const isTotalFaceUpEmpty =
    !comparisonStatistics ||
    (isComparedWithPlanogram && comparisonStatistics?.totalFaceUp === '0');
  const isTotalNotFaceUpEmpty =
    !comparisonStatistics ||
    (isComparedWithPlanogram && comparisonStatistics?.totalNotFaceUp === '0');

  const totalFaceUpDiff = calcDiff(
    comparisonStatistics?.totalFaceUp,
    realogramStatistics?.totalFaceUp
  );
  const outOfStockDiff = calcDiff(
    comparisonStatistics?.totalOutOfStocks,
    realogramStatistics?.totalOutOfStocks
  );
  const unknownDiff = calcDiff(
    comparisonStatistics?.totalUnknowns,
    realogramStatistics?.totalUnknowns
  );
  const totalNotFaceUpDiff = calcDiff(
    comparisonStatistics?.totalNotFaceUp,
    realogramStatistics?.totalNotFaceUp
  );

  return [
    {
      item: '評価OK' as Rate,
      prevValue: isTotalFaceUpEmpty
        ? emptyText
        : comparisonStatistics?.totalFaceUp,
      value: realogramStatistics?.totalFaceUp || emptyText,
      columnType: 'positive' as ColumnType,
      // TODO: バックエンド実装次第変更
      statisticType: getComparisonStatisticType(+totalFaceUpDiff),
      diff: isTotalFaceUpEmpty ? '' : totalFaceUpDiff.toString(),
      isPrevValueEmpty:
        isTotalFaceUpEmpty || !comparisonStatistics?.totalFaceUp,
      isValueEmpty: isTotalFaceUpEmpty || !realogramStatistics?.totalFaceUp,
    },
    {
      item: '欠品区画数' as Rate,
      prevValue: isTotalOutOfStocksEmpty
        ? emptyText
        : comparisonStatistics?.totalOutOfStocks,
      value: realogramStatistics?.totalOutOfStocks || emptyText,
      columnType: 'negative' as ColumnType,
      // TODO: バックエンド実装次第変更
      statisticType: getComparisonStatisticType(+outOfStockDiff),
      diff: isTotalOutOfStocksEmpty ? '' : outOfStockDiff.toString(),
      isPrevValueEmpty:
        isTotalOutOfStocksEmpty || !comparisonStatistics?.totalOutOfStocks,
      isValueEmpty: !realogramStatistics?.totalOutOfStocks,
    },
    {
      item: '不明な商品フェイス数' as Rate,
      prevValue: isTotalUnknownsEmpty
        ? emptyText
        : comparisonStatistics?.totalUnknowns,
      value: realogramStatistics?.totalUnknowns || emptyText,
      columnType: 'negative' as ColumnType,
      // TODO: バックエンド実装次第変更
      statisticType: getComparisonStatisticType(+unknownDiff),
      diff: isTotalUnknownsEmpty ? '' : unknownDiff.toString(),
      isPrevValueEmpty:
        isTotalUnknownsEmpty || !comparisonStatistics?.totalUnknowns,
      isValueEmpty: !realogramStatistics?.totalUnknowns,
    },
    {
      item: 'フェイスアップNG' as Rate,
      prevValue: isTotalNotFaceUpEmpty
        ? emptyText
        : comparisonStatistics?.totalNotFaceUp,
      value: realogramStatistics?.totalNotFaceUp || emptyText,
      columnType: 'negative' as ColumnType,
      // TODO: バックエンド実装次第変更
      statisticType: getComparisonStatisticType(+totalNotFaceUpDiff),
      diff: isTotalNotFaceUpEmpty ? '' : totalNotFaceUpDiff.toString(),
      isPrevValueEmpty:
        isTotalNotFaceUpEmpty || !comparisonStatistics?.totalNotFaceUp,
      isValueEmpty: !realogramStatistics?.totalNotFaceUp,
    },
  ];
};
