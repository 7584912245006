import { useState } from 'react';
import { Crop } from 'react-image-crop';
import { theme } from 'theme';

export const useCropImage = (maxWidth?: number, maxHeight?: number) => {
  const [crop, setCrop] = useState<Crop>({
    unit: '%', // Can be 'px' or '%'
    x: 35,
    y: 45,
    width: 15,
    height: 10,
  });

  // 保存できる条件
  // unitの初期値は%、一度でも操作するとpxになる
  // width, height, x, yは１以上
  // x+width, y+height が products_shelves_image.x, y 以下であること
  const isEnableSave =
    !!crop &&
    crop.width >= 1 &&
    crop.height >= 1 &&
    crop.x >= 1 &&
    crop.y >= 1 &&
    !!maxWidth &&
    crop.x + crop.width < maxWidth &&
    !!maxHeight &&
    crop.y + crop.height < maxHeight &&
    crop.unit === 'px';

  const cropSelectionElement = document.querySelector(
    '.ReactCrop__crop-selection'
  ) as HTMLElement;
  const dragHandleElements = document.querySelectorAll(
    '.ReactCrop__drag-handle'
  );
  if (cropSelectionElement) {
    cropSelectionElement.style.border = '2px solid #fff';
    cropSelectionElement.style.opacity = '1';
    cropSelectionElement.style.backgroundColor = 'rgba(255,255,255,0.4)';
    cropSelectionElement.style.backgroundImage = 'none';
    cropSelectionElement.style.animation = 'none';
    cropSelectionElement.style.zIndex = '100';
  }
  if (dragHandleElements) {
    dragHandleElements.forEach((element) => {
      (element as HTMLElement).style.borderRadius = '50%';
      (element as HTMLElement).style.backgroundColor =
        theme.palette.primary.main;
      (element as HTMLElement).style.border = theme.palette.primary.main;
      (element as HTMLElement).style.width = '8px';
      (element as HTMLElement).style.height = '8px';
      (element as HTMLElement).style.display = 'block';
    });
  }
  return {
    crop,
    isEnableSave,
    setCrop,
  };
};
