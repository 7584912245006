import { useFlatPlanogramPlan } from '@hooks/useFlatPlanogramPlan';
import { Box } from '@mui/material';
import { selectHistories, selectIsDirty } from '@reducers/flatPlan/selectors';
import { updateIsDragProduct } from '@reducers/planogramEditor/reducer';
import { productsApi } from '@reducers/shelfAppsApi';
import { useAppDispatch, useAppSelector } from '@store/index';
import {
  changeSidesByFaceFront,
  getCompartmentItems,
  hasDifferentSplitAxisDirection,
  minAreaSize,
} from '@utils/planogram';
import { getProductSize } from '@utils/product';
import { isEqual } from 'lodash';
import {
  FC,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDrop } from 'react-dnd';
import { FaceFrontId, Product } from 'types/common';
import {
  BucketArea as BucketAreaType,
  PlanogramProductCompartment,
  Position,
} from 'types/planogram';
import { ItemTypes } from 'types/rack';
import { Area } from './fragments/area';
import { BucketCompartment } from './fragments/bucketCompartment';

type Props = {
  bucketArea: BucketAreaType;
  handleClickCompartment: (
    e: MouseEvent<HTMLElement>,
    position: Position[],
    product: Product,
    bucketIndex: number
  ) => void;
  path?: Position[];
  anchorEl: HTMLElement | null;
  resetState: () => void;
  selectedBucketId?: number;
  bucketIndex: number;
  onHandleClickArea: (
    e: MouseEvent<HTMLElement>,
    bucketIndex?: number,
    path?: Position[],
    productId?: number | undefined
  ) => void;
  isDisabledBucket: boolean;
  parentAreaWidth: number;
  parentAreaHeight: number;
  productSize?: {
    width: number;
    height: number;
  };
  bboxEnabled: boolean;
  hightLightAreaPosition?: Position[] | undefined;
  setHightLightAreaPosition: (value?: Position[]) => void;
  isCompared?: boolean;
  isEditor?: boolean;
};

type DndData = {
  product: Product;
  hoverIndex: number;
  compartment: PlanogramProductCompartment;
};

export type Item = {
  data: DndData | undefined;
  bucketIndex: number;
  index: number;
  areaPath: Position[];
};

const initialPath = [{ indexX: 0, indexY: 0 }];

export const BucketArea: FC<Props> = ({
  bucketArea,
  handleClickCompartment,
  path,
  anchorEl,
  resetState,
  selectedBucketId,
  bucketIndex,
  onHandleClickArea,
  isDisabledBucket,
  parentAreaWidth,
  parentAreaHeight,
  productSize,
  bboxEnabled,
  hightLightAreaPosition,
  setHightLightAreaPosition,
  isCompared = false,
  isEditor = false,
}) => {
  const dispatch = useAppDispatch();
  const {
    addProduct,
    removeProducts,
    plan,
    bucketProductPosition,
    updateCanSplitHorizontal,
    updateCanSplitVertical,
  } = useFlatPlanogramPlan();
  const histories = useAppSelector(selectHistories);
  const isDirty = useAppSelector(selectIsDirty);
  const isPathEqual = isEqual(path, bucketProductPosition);

  useEffect(() => {
    if (!(selectedBucketId === bucketIndex && isPathEqual)) return;

    const canSplitHorizontal = getCanSplitHorizontal(
      bucketArea,
      productSize,
      parentAreaHeight
    );
    const canSplitVertical = getCanSplitVertical(
      bucketArea,
      productSize,
      parentAreaWidth
    );

    updateCanSplitHorizontal(canSplitHorizontal);
    updateCanSplitVertical(canSplitVertical);
  }, [
    bucketIndex,
    bucketProductPosition,
    parentAreaHeight,
    parentAreaWidth,
    selectedBucketId,
    updateCanSplitHorizontal,
    updateCanSplitVertical,
    isPathEqual,
    productSize,
    bucketArea,
  ]);
  const itemTypes = [ItemTypes.ITEM, ItemTypes.ITEM_GROUP];

  const [{ isOverCurrent, isOver, canDrop }, drop] = useDrop<
    Item,
    unknown,
    {
      item?: Item;
      itemType: string | symbol | null;
      isOverCurrent: boolean;
      isOver: boolean;
      canDrop: boolean;
    }
  >(
    () => ({
      accept: itemTypes,
      collect: (monitor) => ({
        item: monitor.getItem(),
        itemType: monitor.getItemType(),
        isOverCurrent: !!monitor.isOver({ shallow: true }),
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
      drop: (item, monitor) => {
        dispatch(updateIsDragProduct(false));
        if (
          bucketArea.type === 'compartment' ||
          bucketArea.children?.length !== 0
        )
          return;
        const itemType = monitor.getItemType();
        const { index: prevBucketIndex, areaPath } = item;

        if (!item.data?.product) {
          return;
        }
        const faceFrontId = 2;
        const faceDirection = item.data.product.detail?.face_direction;
        // reset state when drop to another bucket
        setHightLightAreaPosition(undefined);
        if (itemType === ItemTypes.ITEM) {
          addProduct({
            to: path ?? initialPath,
            bucketIndex,
            product: item.data.product,
            ...(faceDirection === 'top' && {
              faceFront: faceFrontId,
              orientation: 0,
            }),
          });
        } else if (itemType === ItemTypes.ITEM_GROUP) {
          const { count, orientation, face_front } = item.data.compartment;
          addProduct({
            to: path ?? initialPath,
            bucketIndex,
            product: item.data.product,
            count,
            orientation,
            faceFront: face_front,
          });
          removeProducts({
            bucketIndex: prevBucketIndex,
            areaPath,
            isMakeHistory: false,
          });
        } else {
          resetState();
        }
      },
    }),
    [bucketArea]
  );

  const [offspringHeight, setOffspringHeight] = useState<number>();
  const [offspringWidth, setOffspringWidth] = useState<number>();

  const isChangeableHeight = useMemo(() => {
    return (
      bucketArea?.split_axis === 'compartment_y' &&
      bucketArea.children?.[0] &&
      bucketArea.children[0].split_axis === 'compartment_x' &&
      !hasDifferentSplitAxisDirection(bucketArea.children[0], 'compartment_x')
    );
  }, [bucketArea.children, bucketArea?.split_axis]);

  const isChangeableWidth = useMemo(() => {
    return (
      bucketArea?.split_axis === 'compartment_x' &&
      bucketArea.children?.[0] &&
      bucketArea.children[0].split_axis === 'compartment_y' &&
      !hasDifferentSplitAxisDirection(bucketArea.children[0], 'compartment_y')
    );
  }, [bucketArea.children, bucketArea?.split_axis]);

  const compartmentItems = useMemo(() => {
    if (!bucketArea || !bucketArea.children || !bucketArea.split_axis) return;

    if (
      isEqual(
        histories.past[histories.past.length - 1]?.frame.detail.buckets?.[
          bucketIndex
        ],
        plan.frame.detail.buckets?.[bucketIndex]
      )
    )
      return;

    if (bucketArea.children?.[0]) {
      return getCompartmentItems(bucketArea.children[0]);
    }

    return;
  }, [bucketArea, bucketIndex, histories.past, plan.frame.detail.buckets]);

  const getOffspringHeight = useCallback(async () => {
    if (!compartmentItems) return;
    if (compartmentItems?.length === 0) {
      setOffspringHeight(undefined);
      return;
    }
    try {
      const res = await dispatch(
        productsApi.endpoints.getProduct.initiate({
          productId: compartmentItems[0].product_id ?? 0,
        })
      ).unwrap();
      const size = res.product;
      const faceFrontSize = changeSidesByFaceFront(
        getProductSize(size),
        (compartmentItems[0].face_front ?? 1) as FaceFrontId
      );
      const productSize =
        (faceFrontSize.height ?? 0) * (compartmentItems[0].count?.y ?? 0);
      setOffspringHeight(productSize);
      return;
    } catch (e) {
      console.log();
    }
  }, [compartmentItems, dispatch]);

  const getOffspringWidth = useCallback(async () => {
    if (!compartmentItems) return;
    if (compartmentItems?.length === 0) {
      setOffspringWidth(undefined);
      return;
    }
    try {
      const res = await dispatch(
        productsApi.endpoints.getProduct.initiate({
          productId:
            compartmentItems[compartmentItems.length - 1].product_id ?? 0,
        })
      ).unwrap();
      const size = getProductSize(res?.product);
      const faceFrontSize = changeSidesByFaceFront(
        size,
        (compartmentItems[compartmentItems.length - 1].face_front ??
          1) as FaceFrontId
      );
      const productSize =
        (faceFrontSize.width ?? 0) *
        (compartmentItems[compartmentItems.length - 1].count?.x ?? 0);

      setOffspringWidth(productSize);
      return;
    } catch (e) {
      console.log();
    }
  }, [compartmentItems, dispatch]);

  useEffect(() => {
    if (
      isChangeableHeight &&
      (!isEqual(
        histories.past[histories.past.length - 1]?.frame.detail.buckets?.[
          bucketIndex
        ],
        plan.frame.detail.buckets?.[bucketIndex]
      ) ||
        !isDirty)
    ) {
      void getOffspringHeight();
    } else if (!isChangeableHeight) {
      setOffspringHeight(undefined);
    }
  }, [
    bucketIndex,
    getOffspringHeight,
    histories.past,
    isChangeableHeight,
    isDirty,
    plan.frame.detail.buckets,
  ]);

  useEffect(() => {
    if (
      isChangeableWidth &&
      (!isEqual(
        histories.past[histories.past.length - 1]?.frame.detail.buckets?.[
          bucketIndex
        ],
        plan.frame.detail.buckets?.[bucketIndex]
      ) ||
        !isDirty)
    ) {
      void getOffspringWidth();
    } else if (!isChangeableWidth) {
      setOffspringWidth(undefined);
    }
  }, [
    bucketIndex,
    getOffspringWidth,
    histories.past,
    isChangeableWidth,
    isDirty,
    plan.frame.detail.buckets,
  ]);

  const isActive = canDrop && isOver;
  useEffect(() => {
    if (isActive) {
      dispatch(updateIsDragProduct(true));
    }
  }, [isActive, dispatch]);

  useEffect(() => {
    if (isOverCurrent) {
      setHightLightAreaPosition(path);
    }
  }, [isOverCurrent, path, setHightLightAreaPosition]);

  return (
    <Box
      component="div"
      ref={
        bucketArea.children?.length !== 0 || isDisabledBucket ? undefined : drop
      }
      width="100%"
      height="100%"
      sx={{
        display: 'flex',
        flexDirection:
          `${bucketArea.split_axis || ''}` === 'compartment_x'
            ? 'row'
            : 'column-reverse',
        position: 'relative',
      }}
    >
      {bucketArea.children?.map((area, index) => {
        const newPosition = {
          indexX: bucketArea.split_axis === 'compartment_y' ? 0 : index,
          indexY: bucketArea.split_axis === 'compartment_x' ? 0 : index,
        };
        let newPath = path ?? [newPosition];

        if (area.type !== 'compartment') {
          newPath = path ? [...path, newPosition] : [newPosition];
        }

        if (area.type === 'compartment') {
          return (
            <BucketCompartment
              key={index}
              bucketArea={area}
              bboxEnabled={bboxEnabled}
              handleClickCompartment={handleClickCompartment}
              path={newPath}
              anchorEl={anchorEl}
              resetState={resetState}
              bucketIndex={bucketIndex}
              isDisabledBucket={isDisabledBucket}
              parentAreaHeight={parentAreaHeight}
              parentAreaWidth={parentAreaWidth}
              isCompared={isCompared}
              isEditor={isEditor}
            />
          );
        }
        if (area.type === 'area') {
          return (
            <Area
              key={index}
              bucketArea={bucketArea}
              index={index}
              area={area}
              handleClickCompartment={handleClickCompartment}
              anchorEl={anchorEl}
              resetState={resetState}
              bucketIndex={bucketIndex}
              onHandleClickArea={onHandleClickArea}
              isDisabledBucket={isDisabledBucket}
              parentAreaWidth={parentAreaWidth}
              parentAreaHeight={parentAreaHeight}
              offspringHeight={offspringHeight}
              offspringWidth={offspringWidth}
              path={newPath}
              selectedBucketId={selectedBucketId}
              bboxEnabled={bboxEnabled}
              hightLightAreaPosition={hightLightAreaPosition}
              setHightLightAreaPosition={setHightLightAreaPosition}
              isCompared={isCompared}
            />
          );
        }
      })}
    </Box>
  );
};

/**
 * Checks if a bucket area can be split horizontally by calculate the bucket remaining height of the
 * and compare it to minAreaSize.
 *
 * @param bucketArea - The bucket area to check.
 * @param productSize - The size of the product.
 * @param parentAreaHeight - The height of the parent area.
 * @returns A boolean indicating whether the bucket area can be split horizontally.
 */
function getCanSplitHorizontal(
  bucketArea: BucketAreaType,
  productSize: Props['productSize'],
  parentAreaHeight: number
) {
  let requiredHeight = parentAreaHeight;

  // If there is a product in the bucket area, the calculation size must be the parent area height minus the product height.
  if (productSize && bucketArea.children?.[0]?.type === 'compartment') {
    requiredHeight = parentAreaHeight - productSize.height;
  }

  return requiredHeight > minAreaSize;
}

/**
 * Checks if a bucket area can be split vertically by calculate the bucket remaining width of the bucket
 *  and compare it to minAreaSize.
 *
 * @param bucketArea - The bucket area to check.
 * @param productSize - The size of the product.
 * @param parentAreaWidth - The width of the parent area.
 * @returns A boolean indicating whether the bucket area can be split vertically.
 */
function getCanSplitVertical(
  bucketArea: BucketAreaType,
  productSize: Props['productSize'],
  parentAreaWidth: number
) {
  let requiredWidth = parentAreaWidth;

  // If there is a product in the bucket area, the calculation size must be the parent area width minus the product width.
  if (productSize && bucketArea.children?.[0]?.type === 'compartment') {
    requiredWidth = parentAreaWidth - productSize.width;
  }

  return requiredWidth > minAreaSize;
}
