import { ComparisonBayHeader } from '@components/molecules/comparisionBayHeader/comparisionBayHeader';
import { RealogramImageAndBbox } from '@components/organisms';
import { RealogramInfoDialog } from '@components/organisms/realogramInfoDialog/realogramInfoDialog';
import { StatisticsValueSelector } from '@components/organisms/statisticsValueSelector/statisticsValueSelector';
import { FlatOrShelfPlanogramComparison } from '@components/pages/planogramEditor/fragments/flatOrShelfPlanogramComparison';
import { InfoOutlined } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { CurrentSelectedType } from '@reducers/comparisonItemModal';
import {
  EstimateForPlanogramResponse,
  EstimateForRealogramResponse,
} from '@reducers/shelfAppsApi';
import {
  ComparisonTabModal,
  getDisplayValue,
  getProfitsMenu,
  pointersDummy,
} from '@utils/const';
import { Format, format, getTextDateStatistic } from '@utils/date';
import { t } from 'i18next';
import { FC, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { theme } from 'theme';
import {
  ProfitTab,
  Rate,
  ShelfDetailMode,
  ShelfDetailView,
} from 'types/common';
import { Planogram } from 'types/planogram';
import { ProductSalesReport, StoreArea } from 'types/products';
import {
  RealogramCandidate,
  RealogramSelectedItem,
  RealogramShelfBoard,
} from 'types/realogram';

type Props = {
  isEditor?: boolean;
  comparedPlanogram?: Planogram;
  comparedRealogram?: RealogramCandidate;
  currentSelectedType?: CurrentSelectedType;
  comparedProfit: string;
  comparedRealogramEstimatedData?: EstimateForRealogramResponse | undefined;
  comparedStoreAreaType: StoreArea;
  rateValue: Rate;
  profitTab: ProfitTab;
  detailView: ShelfDetailView;
  detailMode: ShelfDetailMode;
  comparisonSameIds?: number[];
  comparedShelfboards?: RealogramShelfBoard[];
  isComparedImageLoading: boolean;
  handleOpenComparisonItemModal: ({
    defaultTab,
    originPlanogramId,
  }: {
    defaultTab?: ComparisonTabModal;
    originPlanogramId?: number;
    originRealogramId?: number;
  }) => void;
  handleChangeComparedProfit: (value: string) => void;
  handleClickRealogramBbox: (item: RealogramSelectedItem) => void;
  setIsComparedImageLoading: (value: boolean) => void;
  showDevFeatureDialog: () => void;
  handleRecalculate: () => void;
  planogram?: Planogram;
  comparedPlanogramEstimatedData?: EstimateForPlanogramResponse;
  handleClickOutItemCompareBbox?: () => void;
  isSwappingBayPartMode?: boolean;
  isBucketType?: boolean;
  isComparedBucketType?: boolean;
  isLoadingEstimate?: boolean;
  realogramCandidate?: RealogramCandidate;
  isTenantSalesAnalytics?: boolean;
  comparedAnalyticsData?: ProductSalesReport;
  comparedAnalyticsDataTerm?: string;
  disableChangeToComparedPreviousWeek?: boolean;
  disableChangeToComparedNextWeek?: boolean;
  handleComparedNextWeekTerm?: () => void;
  handleComparedPreviousWeekTerm?: () => void;
  onClickNext?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onClickPrev?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isLoading?: boolean;
};

export const SelectedItemCompare: FC<Props> = ({
  currentSelectedType,
  comparedPlanogram,
  comparedRealogram,
  comparedProfit,
  rateValue,
  profitTab,
  comparedRealogramEstimatedData,
  comparedStoreAreaType,
  comparedShelfboards,
  detailView,
  detailMode,
  comparisonSameIds,
  isComparedImageLoading,
  handleChangeComparedProfit,
  handleClickRealogramBbox,
  setIsComparedImageLoading,
  handleOpenComparisonItemModal,
  showDevFeatureDialog,
  handleRecalculate,
  planogram,
  comparedPlanogramEstimatedData,
  handleClickOutItemCompareBbox,
  isSwappingBayPartMode,
  isBucketType = false,
  isComparedBucketType = false,
  isLoadingEstimate,
  realogramCandidate,
  isTenantSalesAnalytics = false,
  isEditor = false,
  comparedAnalyticsData,
  comparedAnalyticsDataTerm,
  disableChangeToComparedPreviousWeek,
  disableChangeToComparedNextWeek,
  handleComparedNextWeekTerm,
  handleComparedPreviousWeekTerm,
  onClickNext,
  onClickPrev,
  isLoading = false,
}) => {
  const [openRealogramInfoDialog, setOpenRealogramInfoDialog] = useState(false);
  const headerStyleDirection = isBucketType ? 'row' : 'column';

  if (currentSelectedType === 'actual' && comparedRealogram) {
    const { start_date: startDateFromAPI, end_date: endDateFromAPI } =
      comparedRealogramEstimatedData?.estimate.summary.aggregation_period || {};

    const term = getTextDateStatistic(startDateFromAPI, endDateFromAPI);

    return (
      <>
        <ComparisonBayHeader
          headerStyleDirection={headerStyleDirection}
          name={comparedRealogram.store_bay.name}
          bayName={comparedRealogram?.store_bay?.store?.name ?? '-'}
          targetCompareText="比較対象"
          bayHeaderActions={
            <>
              <InfoOutlined
                sx={{
                  cursor: 'pointer',
                  color: 'rgba(0, 0, 0, 0.54)',
                  width: '20px',
                  height: '20px',
                }}
                onClick={() => setOpenRealogramInfoDialog(true)}
              />
              <Button
                variant="outlined"
                onClick={() =>
                  handleOpenComparisonItemModal({
                    originRealogramId: realogramCandidate?.id,
                    originPlanogramId: planogram?.id,
                  })
                }
                disabled={isSwappingBayPartMode}
                sx={{
                  height: '28px',
                  p: '0',
                  minWidth: '28px',
                  fontSize: '12px',
                  width: '40px',
                }}
              >
                変更
              </Button>
            </>
          }
        >
          {isTenantSalesAnalytics && (
            <StatisticsValueSelector
              value={getDisplayValue(
                comparedProfit,
                comparedAnalyticsData?.summary,
                t('gross_profit')
              )}
              profits={getProfitsMenu(t('gross_profit'))}
              pointers={pointersDummy}
              selectedProfitType={comparedProfit}
              selectedPointerType={comparedStoreAreaType}
              handleChangeProfitValue={handleChangeComparedProfit}
              isPlans={false}
              category="実績"
              term={term}
              isLoading={isLoadingEstimate}
              disabled={isSwappingBayPartMode}
              analyticsDataTerm={comparedAnalyticsDataTerm}
              disableChangeToPreviousWeek={disableChangeToComparedPreviousWeek}
              disableChangeToNextWeek={disableChangeToComparedNextWeek}
              handleNextWeekTerm={handleComparedNextWeekTerm}
              handlePreviousWeekTerm={handleComparedPreviousWeekTerm}
            />
          )}
        </ComparisonBayHeader>
        <Box
          component="div"
          display="flex"
          flexDirection="column"
          flex={1}
          sx={{
            backgroundColor: theme.palette.backgroundBlack.black,
          }}
          onClick={handleClickOutItemCompareBbox}
        >
          <RealogramImageAndBbox
            isComparingBox
            realogramCandidateId={comparedRealogram.id}
            view={detailView}
            mode={detailMode}
            shelfBoards={comparedShelfboards}
            handleClickBbox={handleClickRealogramBbox}
            createdAt={format(
              comparedRealogram?.created_at,
              Format.datetimeWithWeekday
            )}
            onClickNext={onClickNext}
            onClickPrev={onClickPrev}
            rate={rateValue}
            profitTab={profitTab}
            productReports={comparedAnalyticsData?.products}
            imageIsLoading={isComparedImageLoading}
            handleChangeLoading={() => setIsComparedImageLoading(true)}
            comparisonSameIdsSet={new Set(comparisonSameIds)}
            isBucketType={isBucketType}
            isComparedBucketType={isComparedBucketType}
            isDisabledAction={isSwappingBayPartMode}
            isNextDisabled={
              !comparedRealogram.link?.next_created_realogram_candidate_id
            }
            isPrevDisabled={
              !comparedRealogram.link?.prev_created_realogram_candidate_id
            }
            isRealogramLoading={isLoading}
          />
        </Box>
        <RealogramInfoDialog
          open={openRealogramInfoDialog}
          onClose={() => setOpenRealogramInfoDialog(false)}
          realogramCandidate={comparedRealogram}
        />
      </>
    );
  } else if (currentSelectedType === 'plan' && comparedPlanogram) {
    return (
      <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
        <FlatOrShelfPlanogramComparison
          handleRecalculate={handleRecalculate}
          handleClickAwayPlanogram={() => void 0}
          handleChangeComparedProfit={handleChangeComparedProfit}
          showDevFeatureDialog={showDevFeatureDialog}
          storeAreaType={comparedStoreAreaType}
          profitCompared={comparedProfit}
          handleOpenComparisonItemModal={handleOpenComparisonItemModal}
          detailView={detailView}
          detailMode={detailMode}
          comparedPlanogram={comparedPlanogram}
          planogram={planogram}
          comparedPlanogramEstimatedData={comparedPlanogramEstimatedData}
          headerStyleDirection={headerStyleDirection}
          isSwappingBayPartMode={isSwappingBayPartMode}
          isLoadingEstimate={isLoadingEstimate}
          isComparingBox
          realogramCandidate={realogramCandidate}
          isTenantSalesAnalytics={isTenantSalesAnalytics}
          isEditor={isEditor}
        />
      </DndProvider>
    );
  }
  return <></>;
};
