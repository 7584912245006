type QueryParamsObject = Record<string, string>;

export const filteringQueryParams = (
  allowKeys: string[],
  existingParams: QueryParamsObject
) => {
  return Object.fromEntries(
    Object.entries(existingParams).filter(([key]) => allowKeys.includes(key))
  );
};
