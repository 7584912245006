import { FormPaper } from '@components/molecules/formPaper/formPaper';
import { Button, CircularProgress, Typography } from '@mui/material';
import { selectAccessToken } from '@reducers/auth/selectors';
import { useGetMfaDeviceQuery } from '@reducers/shelfAppsApi/injections/usersApi';
import { useAppSelector } from '@store/index';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CancelButton } from './cancelButton';
import { paths } from '@utils/const';

type Props = {
  next: () => void;
  isInitial: boolean; //初期設定画面からの遷移かどうか
};

export const Initial: FC<Props> = ({ next, isInitial }) => {
  const accessToken = useAppSelector(selectAccessToken);
  const { data, isLoading } = useGetMfaDeviceQuery({ accessToken });
  const navigate = useNavigate();
  useEffect(() => {
    if (data?.preferred_mfa_setting) {
      navigate(paths.account.root);
    }
  }, [data, navigate]);

  return isLoading ? (
    <CircularProgress />
  ) : (
    <FormPaper title="二要素認証登録">
      <Typography mb={3}>
        ID とパスワードに加えて、スマートフォンを使って認証を行います。
      </Typography>
      <Typography>
        事前に対応アプリケーションを、このデバイスとは異なるデバイスにインストールしてください（このデバイスにはインストールしないでください）。
      </Typography>
      <Button variant="contained" sx={{ my: 3 }} fullWidth onClick={next}>
        設定を開始
      </Button>
      <CancelButton isInitial={isInitial} />
    </FormPaper>
  );
};
