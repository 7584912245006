import { ListSubheader } from '@components/molecules/listSubheader/listSubheader';
import { HeaderOptional } from '@components/organisms/compartmentsTable/fragments/headerOptional';
import { ListOptional } from '@components/organisms/compartmentsTable/fragments/listOptional';
import { EmptyCompartment } from '@components/organisms/realogramCandidatesList/fragments';
import { ShelfListHeader } from '@components/organisms/shelfListHeader/shelfListHeader';
import { ShelfListItem } from '@components/organisms/shelfListItem/shelfListItem';
import { useFlatPlanogramPlan } from '@hooks/useFlatPlanogramPlan';
import { Box, List, Typography } from '@mui/material';
import { selectPlanogramEditorState } from '@reducers/planogramEditor/selectors';
import { useAppSelector } from '@store/index';
import { hasProductTag } from '@utils/product';
import { isEqual } from 'lodash';
import { FC, Fragment, MutableRefObject, useEffect, useState } from 'react';
import { Product, ProfitTab } from 'types/common';
import {
  BucketPlanogramPlan,
  CompartmentBucketPosition,
  PlanogramProductCompartment,
  Position,
} from 'types/planogram';
import { embed } from '@utils/const';
import { theme } from 'theme';

type Props = {
  refs: MutableRefObject<Map<string, null | HTMLDivElement>>;
  products?: Product[];
  frame: BucketPlanogramPlan['frame'];
  handleClickListItem: (
    position: Position[],
    bucketIndex: number,
    product?: Product
  ) => void;
  profitTab: ProfitTab;
  positions?: CompartmentBucketPosition[][];
};

export const BucketCompartmentsTable: FC<Props> = ({
  refs,
  products,
  frame,
  handleClickListItem,
  profitTab,
  positions,
}) => {
  const buckets = frame.detail.buckets;
  const { bucketProductPosition } = useFlatPlanogramPlan();
  const {
    detailView: view,
    productTag,
    selectedBucketId,
  } = useAppSelector(selectPlanogramEditorState);
  const [showOrganizationProductId, setShowOrganizationProductId] =
    useState(false);

  useEffect(() => {
    const embedParam = new URLSearchParams(window.location.search).get('embed');
    if (embedParam === embed) setShowOrganizationProductId(true);
  }, []);

  const hasNoProductsToShow =
    (!products && view !== 'default' && view !== 'rate') ||
    (view === 'productFlag' &&
      products?.every((product) => {
        return !hasProductTag(productTag, product?.detail?.tags); // 商品属性ビューで該当のタグがない場合
      }));

  return (
    <>
      <ShelfListHeader>
        <HeaderOptional profitTab={profitTab} />
      </ShelfListHeader>
      <List
        sx={{ width: '100%', height: '100%', overflow: 'auto' }}
        subheader={<Box component="li" />}
      >
        {!!buckets?.length && //[]の時(ロード中)にemptyを表示しない
          hasNoProductsToShow && (
            <Typography sx={{ color: '#999999', mt: 5, textAlign: 'center' }}>
              該当する区画がありません
            </Typography>
          )}
        {positions?.map((position, bucketIndex) => {
          return (
            <Fragment key={bucketIndex}>
              {view === 'default' && (
                <ListSubheader
                  title={`${bucketIndex + 1}カゴ目`}
                  backgroundColor={theme.palette.backgroundBlack.active}
                  color={theme.palette.textBlack.primary}
                  sx={{ fontWeight: 400, height: 30 }}
                />
              )}
              {view === 'default' && !position.length ? (
                <EmptyCompartment
                  emptyText="カゴに配置されている商品はありません"
                  // eslint-disable-next-line @typescript-eslint/no-empty-function -- TODOリファクタリング
                  handleClickDeleteShelfBoard={() => {}}
                />
              ) : (
                position.map(({ compartment, position }, index) => {
                  const product = products?.find(
                    (p) => p.id === compartment.product_id
                  );
                  if (
                    view === 'productFlag' &&
                    !hasProductTag(productTag, product?.detail?.tags)
                  ) {
                    return <Fragment key={index} />;
                  }
                  return (
                    <ShelfListItem
                      key={index}
                      refs={refs}
                      view={view}
                      refKey={`${JSON.stringify(position)}-${bucketIndex}}`}
                      product={product}
                      isSelected={
                        isEqual(position, bucketProductPosition) &&
                        bucketIndex === selectedBucketId
                      }
                      handleClick={() => {
                        handleClickListItem(position, bucketIndex, product);
                      }}
                      name={product?.name}
                      organizationProductId={
                        showOrganizationProductId
                          ? product?.detail?.organization_product_id
                          : ''
                      }
                      eanCode={product?.detail?.ean_code}
                      productCode={product?.detail?.organization_product_id}
                    >
                      <ListOptional
                        compartment={
                          {
                            ...compartment,
                            face_count:
                              (compartment.count?.x ?? 0) *
                              (compartment.count?.y ?? 0),
                          } as PlanogramProductCompartment
                        }
                        handleClick={() =>
                          handleClickListItem(position, bucketIndex, product)
                        }
                        profitTab={profitTab}
                      />
                    </ShelfListItem>
                  );
                })
              )}
            </Fragment>
          );
        })}
      </List>
    </>
  );
};
