import { Box, Divider } from '@mui/material';
import { FC } from 'react';
import { ShelfDetailView, ProfitTab } from 'types/common';
import { ProductSalesReport } from 'types/products';
import { ProfitStatistics } from '../profitStatistics/profitStatistics';
import { RealogramStatistics } from './fragments';
import { theme } from 'theme';
import { Statistic } from 'types/statistics';

type Props = {
  type: ShelfDetailView;
  hasSelected?: boolean;
  profitTab: ProfitTab;
  setProfitTab: (profitTab: ProfitTab) => void;
  isBucketType?: boolean;
  setIsUnknownProductsReviseMode?: () => void;
  analyticsDataTerm: string;
  displayAnalyticsData?: ProductSalesReport;
  disableChangeToPreviousWeek: boolean;
  disableChangeToNextWeek: boolean;
  handleNextWeekTerm: () => void;
  handlePreviousWeekTerm: () => void;
  realogramStatistics?: Statistic;
};

export const RealogramCandidateOverview: FC<Props> = ({
  type,
  hasSelected = false,
  profitTab,
  setProfitTab,
  isBucketType,
  setIsUnknownProductsReviseMode,
  analyticsDataTerm,
  displayAnalyticsData,
  disableChangeToPreviousWeek,
  disableChangeToNextWeek,
  handleNextWeekTerm,
  handlePreviousWeekTerm,
  realogramStatistics,
}) => {
  switch (type) {
    case 'default':
      return (
        <Box
          component="div"
          p={2}
          borderTop={`1px solid ${theme.palette.dividerBlack.medium}`}
        >
          <RealogramStatistics
            totalShelfBoards={realogramStatistics?.totalShelves ?? ''}
            totalUniques={realogramStatistics?.totalUniques ?? ''}
            totalOutOfStocks={realogramStatistics?.totalOutOfStocks ?? ''}
            totalUnknowns={realogramStatistics?.totalUnknowns ?? ''}
            isBucketType={isBucketType}
            setIsUnknownProductsReviseMode={setIsUnknownProductsReviseMode}
          />
        </Box>
      );
    case 'productFlag':
      return <></>;
    case 'profit':
      return (
        <>
          <ProfitStatistics
            profitTab={profitTab}
            setProfitTab={setProfitTab}
            analyticsDataTerm={analyticsDataTerm}
            displayAnalyticsData={displayAnalyticsData}
            disableChangeToPreviousWeek={disableChangeToPreviousWeek}
            disableChangeToNextWeek={disableChangeToNextWeek}
            handleNextWeekTerm={handleNextWeekTerm}
            handlePreviousWeekTerm={handlePreviousWeekTerm}
          />
          {hasSelected && <Divider flexItem />}
        </>
      );
    case 'rate':
      return <></>;
    default:
      return <></>;
  }
};
