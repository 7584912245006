import { ErrorMessage } from '@components/molecules';
import { FormPaper } from '@components/molecules/formPaper/formPaper';
import { Box, Button, Stack, Typography } from '@mui/material';
import {
  googleAuthenticatorAndroid,
  googleAuthenticatorIos,
} from '@utils/const';
import Image from 'next/legacy/image';
import { QRCodeSVG } from 'qrcode.react';
import { FC } from 'react';
import { CancelButton } from './cancelButton';

type Props = {
  isLoading: boolean;
  isInitial: boolean;
  next: () => void;
  errorMessage?: string;
};

export const AuthAppInstallation: FC<Props> = ({
  next,
  isLoading,
  isInitial,
  errorMessage,
}) => {
  return (
    <FormPaper title="Authenticator アプリのインストール">
      <Box component="div" display="flex" flexDirection="column" gap={2}>
        {errorMessage && <ErrorMessage message={errorMessage} />}
        <Typography>
          このデバイスとは異なるデバイス（スマートフォン）に Authenticator
          アプリケーションをインストールしてください。
        </Typography>
      </Box>
      <Stack direction="row" justifyContent="space-around" mt={4}>
        <Stack direction="column">
          <p>
            <QRCodeSVG
              width={120}
              height={120}
              level="H"
              value={googleAuthenticatorIos}
            />
          </p>
          <Image
            alt=""
            src="/appStoreBadge.svg"
            width={120}
            height={36}
            objectFit="contain"
            //TODO: UIでは端末毎に表示を切り替えるべきか
            onClick={() => window.open(googleAuthenticatorIos, '_blank')}
          />
        </Stack>
        <Stack direction="column">
          <p>
            <QRCodeSVG
              width={120}
              height={120}
              level="H"
              value={googleAuthenticatorAndroid}
            />
          </p>
          <Image
            alt=""
            src="/googlePlayBadge.svg"
            width={120}
            height={36}
            objectFit="contain"
            onClick={() => window.open(googleAuthenticatorAndroid, '_blank')}
          />
        </Stack>
      </Stack>
      <Button
        variant="contained"
        sx={{ my: 3 }}
        fullWidth
        onClick={next}
        disabled={isLoading}
      >
        次へ
      </Button>
      <CancelButton isInitial={isInitial} />
    </FormPaper>
  );
};
