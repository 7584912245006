import { CorrectedChip } from '@components/molecules';
import { RealogramFaceImage } from '@components/molecules/realogramFaceImage/realogramFaceImage';
import { ProductCandidateOptionalItem } from '@components/organisms/productCandidate/fragments/productCandidateOptionalItem';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { Help } from '@mui/icons-material';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { Box, Stack, styled, Typography } from '@mui/material';
import {
  useGetProductAdjustersQuery,
  useGetProductQuery,
} from '@reducers/shelfAppsApi';
import { imageBoxSize } from '@utils/const';
import { convertMeterToMilliMeter, isPlans } from '@utils/planogram';
import {
  findMostHighScore,
  getProductDisplayValues,
  isInferredAsProduct,
} from '@utils/product';
import { FC, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { theme } from 'theme';
import { Product, ProfitTab, ShelfDetailView } from 'types/common';
import { ProductSalesReport } from 'types/products';
import {
  ProductCandidate as ProductCandidateType,
  RealogramSelectedItem,
} from 'types/realogram';
import { DynamicImage } from '../dynamicImage/dynamicImage';
import { ProfitInfo } from './fragments/profitInfo';
import { SlideButtons } from './fragments/slideButtons';

type Props = {
  products?: Product[];
  selectedItem: RealogramSelectedItem;
  referenceImage: string;
  selectNext: () => void;
  selectPrevious: () => void;
  handleModalOpen: () => void;
  handleOutOfStockModalOpen?: () => void;
  shouldButtonBeDisabled: (value: 'next' | 'prev') => boolean;
  handleOpenReferenceImagePreview: () => void;
  realogramDetailView: ShelfDetailView;
  productSalesReport?: ProductSalesReport[];
  profitTab?: ProfitTab;
  setSelectedProductZoomIn?: (product?: Product) => void;
  realogramCandidateId?: number;
  isCanNotEditProductAndCompartment?: boolean;
  isTenantSalesAnalytics?: boolean;
  handleChangeCandidate?: (mostHighScoreProduct: ProductCandidateType) => void;
  isBucketType?: boolean;
  displayAnalyticsDataIndex?: number;
};

export const ProductCandidate: FC<Props> = ({
  products = [],
  selectedItem,
  referenceImage,
  selectNext,
  selectPrevious,
  handleModalOpen,
  handleOutOfStockModalOpen,
  shouldButtonBeDisabled,
  handleOpenReferenceImagePreview,
  realogramDetailView,
  productSalesReport,
  profitTab,
  setSelectedProductZoomIn,
  realogramCandidateId,
  isCanNotEditProductAndCompartment,
  isTenantSalesAnalytics,
  handleChangeCandidate,
  isBucketType = false,
  displayAnalyticsDataIndex = 0,
}) => {
  const isInferred = isInferredAsProduct(selectedItem.item);
  const product = useMemo(() => {
    if (!isInferred) return;
    return products?.find(
      (product) =>
        product.id === selectedItem.item?.primary_candidate?.product_id
    );
  }, [isInferred, products, selectedItem.item?.primary_candidate?.product_id]);
  const { name, eanCode, productCode } = getProductDisplayValues(
    selectedItem.item,
    product
  );

  const mostHighScore = useMemo(() => {
    if (!selectedItem.item) return;
    return findMostHighScore(selectedItem.item);
  }, [selectedItem.item]);

  const { data: mostHighScoreProduct } = useGetProductQuery(
    {
      productId: mostHighScore?.product_id ?? 0,
    },
    {
      skip: !mostHighScore?.product_id,
    }
  );

  const { pathname } = useLocation();

  const { data } = useGetProductAdjustersQuery(
    {
      product_id: product?.id ?? 0,
    },
    {
      skip: !product?.id,
    }
  );

  const isEdited = !!data?.product_adjuster;

  const handleOutOfStockOpen = () => {
    if (handleOutOfStockModalOpen) {
      handleOutOfStockModalOpen();
    }
  };

  const eanCodeValue = eanCode ? eanCode : '-';
  const productCodeValue = productCode ? productCode : '-';

  const isProductUnknown =
    selectedItem?.item?.is_unknown && selectedItem?.item?.in_stock;
  const { isLarger } = useBreakpoint();
  // 棚割実績の棚割タブor棚割計画の棚割、分析
  const isShowJANAndCD =
    isInferred &&
    ((isPlans(pathname) && realogramDetailView === 'profit') ||
      realogramDetailView === 'default');
  const styleNumber = 2;
  // 不明な商品の場合
  const isUnrevisedUnknownProduct =
    selectedItem.item.is_unknown && selectedItem.item.in_stock;
  return (
    <Box component="div" height="100%" display="flex" flexDirection="column">
      <Box
        component="div"
        display="flex"
        flexGrow="1"
        flexDirection={
          isBucketType ? 'row' : { xs: 'column', breakpoint: 'row' }
        }
      >
        <Box
          component="div"
          display="flex"
          flexDirection={isLarger ? 'column' : 'row'}
          mr={isLarger || isBucketType ? styleNumber : 0}
          gap={isLarger ? 1 : styleNumber}
        >
          <Box component="div" display="flex">
            {!!referenceImage &&
              (realogramDetailView === 'default' ||
                realogramDetailView === 'rate') && (
                <ImageBox
                  mr={!isProductUnknown ? 0 : 1}
                  sx={{
                    cursor: 'zoom-in',
                    borderRight: !isProductUnknown ? 'none' : '',
                  }}
                  onClick={() => {
                    if (isProductUnknown) handleOpenReferenceImagePreview();
                    else {
                      if (isProductUnknown || !setSelectedProductZoomIn) return;
                      handleOpenReferenceImagePreview();
                      setSelectedProductZoomIn(product);
                    }
                  }}
                >
                  <DynamicImage
                    src={referenceImage}
                    alt=""
                    layout="fill"
                    objectFit="contain"
                  />
                  {isProductUnknown && (
                    <ZoomInIcon
                      sx={{
                        zIndex: 999,
                        fontSize: 24,
                        position: 'absolute',
                        right: '4px',
                        bottom: '4px',
                        color: '#838583',
                      }}
                    />
                  )}
                </ImageBox>
              )}
            {!isProductUnknown && (
              <ImageBox
                sx={{
                  cursor:
                    !isProductUnknown && realogramCandidateId ? 'zoom-in' : '',
                }}
                onClick={() => {
                  if (!setSelectedProductZoomIn) return;
                  handleOpenReferenceImagePreview();
                  setSelectedProductZoomIn(product);
                }}
              >
                <Box
                  component="div"
                  width={imageBoxSize}
                  height={imageBoxSize}
                  position="relative"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <RealogramFaceImage
                    product={product}
                    size={imageBoxSize}
                    face={selectedItem.item}
                    baseSize={imageBoxSize}
                    fontSize={24}
                  />
                  {!isProductUnknown && realogramCandidateId && (
                    <ZoomInIcon
                      sx={{
                        zIndex: 999,
                        fontSize: 24,
                        position: 'absolute',
                        right: '-4px',
                        color: '#838583',
                        bottom: '-4px',
                      }}
                    />
                  )}
                </Box>
              </ImageBox>
            )}
          </Box>
          {/* 分析タブの時の画像下の情報 */}
          {realogramDetailView === 'profit' && realogramCandidateId && (
            <Box component="div">
              {!isLarger && <Typography fontWeight={500}>{name}</Typography>}
              <ProfitInfo
                eanCode={eanCodeValue}
                productCode={productCodeValue}
              />
            </Box>
          )}
        </Box>
        <Stack
          component="div"
          my={isBucketType ? 0 : { xs: 2, breakpoint: 0 }}
          width="100%"
        >
          {(realogramDetailView !== 'profit' ||
            (isLarger && realogramDetailView === 'profit')) && (
            <Box
              component="div"
              sx={{
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: '4px',
              }}
            >
              {isUnrevisedUnknownProduct ? (
                <Box
                  component="div"
                  gap="8px"
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <Help sx={{ width: 24, height: 24, color: '#b5b5b5' }} />
                  <Typography fontWeight={500} lineHeight="24px">
                    {name}
                  </Typography>
                  {selectedItem.item.revised && <CorrectedChip />}
                </Box>
              ) : (
                <Typography fontWeight={500} lineHeight="24px">
                  {name}
                </Typography>
              )}
            </Box>
          )}
          {realogramDetailView === 'default' &&
            isPlans(pathname) && ( //棚割ービューかつエディタ詳細の場合
              <Typography sx={{ fontSize: 12, fontWeight: 500 }}>
                {convertSize(product)}
                {isEdited && (
                  <Box component="span" color={theme.palette.textBlack.label}>
                    （編集済み）
                  </Box>
                )}
              </Typography>
            )}
          {isShowJANAndCD && (
            <Box
              component="div"
              sx={{
                display: 'flex',
                columnGap: 5,
              }}
              margin={`${
                !isPlans(pathname) || realogramDetailView !== 'default'
                  ? '0 0 8px 0'
                  : '8px 0 0 0'
              }`}
            >
              <Typography
                sx={{
                  fontSize: 12,
                  color: theme.palette.textBlack.secondary,
                }}
              >
                JAN：{eanCodeValue}
              </Typography>
              <Typography
                sx={{
                  fontSize: 12,
                  color: theme.palette.textBlack.secondary,
                }}
              >
                商品CD：{productCodeValue}
              </Typography>
            </Box>
          )}

          <ProductCandidateOptionalItem
            isCanNotEditProductAndCompartment={
              isCanNotEditProductAndCompartment
            }
            realogramDetailView={realogramDetailView}
            product={product}
            selectedItem={selectedItem}
            handleModalOpen={handleModalOpen}
            productSalesReport={productSalesReport}
            profitTab={profitTab}
            realogramCandidateId={realogramCandidateId}
            handleOutOfStockModalOpen={handleOutOfStockOpen}
            isTenantSalesAnalytics={isTenantSalesAnalytics}
            mostHighScoreProduct={mostHighScoreProduct?.product}
            mostHighScore={mostHighScore}
            handleChangeCandidate={handleChangeCandidate}
            isUnrevisedUnknownProduct={isUnrevisedUnknownProduct}
            slideButtons={
              (realogramDetailView !== 'profit' || isPlans(pathname)) && (
                <SlideButtons
                  selectNext={selectNext}
                  selectPrevious={selectPrevious}
                  shouldButtonBeDisabled={shouldButtonBeDisabled}
                />
              )
            }
            displayAnalyticsDataIndex={displayAnalyticsDataIndex}
          />
        </Stack>
      </Box>
      {(realogramDetailView !== 'profit' || isPlans(pathname)) &&
        !isUnrevisedUnknownProduct && (
          <SlideButtons
            selectNext={selectNext}
            selectPrevious={selectPrevious}
            shouldButtonBeDisabled={shouldButtonBeDisabled}
          />
        )}
    </Box>
  );
};

// eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase
const ImageBox = styled(Box)(() => ({
  position: 'relative',
  width: 120,
  height: 120,
  border: `1px solid #CCCCCC`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const convertSize = (product?: Product) => {
  if (!product?.shape) {
    return '';
  }
  const { width, height, depth } =
    product.shape.size.display_size ?? product.shape.size.actual;
  return `寸法：W${convertMeterToMilliMeter(width)}
× H${convertMeterToMilliMeter(height)}
× D${convertMeterToMilliMeter(depth)}`;
};
