import { hasProductTag } from '@utils/product';
import { Product, ProductTag, ShelfDetailView } from 'types/common';
import { BucketArea, BucketPlanogramPlan, Position } from 'types/planogram';

export const getBucketCompartmentsByView = (
  view: ShelfDetailView,
  productTag: ProductTag,
  plan?: BucketPlanogramPlan,
  products?: Product[]
) => {
  const positions = plan?.frame.detail.buckets?.map((bucket) => {
    const positions: {
      compartment: BucketArea;
      position: Position[];
    }[] = [];
    const getProductsPosition = (area: BucketArea, position: Position[]) => {
      if (area.product_id) {
        positions.push({
          compartment: area,
          position: position.length ? position : [{ indexX: 0, indexY: 0 }],
        });
      }
      if (area.children) {
        area.children.forEach((child, index) => {
          const newPosition = {
            indexX: area.split_axis === 'compartment_y' ? 0 : index,
            indexY: area.split_axis === 'compartment_x' ? 0 : index,
          };
          const newPath =
            child.type !== 'compartment'
              ? [...position, newPosition]
              : position;
          getProductsPosition(child, newPath);
        });
      }
    };
    getProductsPosition(bucket.detail.area, []);
    return positions;
  });
  if (view === 'productFlag' && positions?.length) {
    return positions?.map((position) => {
      return position.filter((el) => {
        const product = products?.find(
          (p) => p.id === el.compartment.product_id
        );
        return hasProductTag(productTag, product?.detail?.tags);
      });
    });
  }
  return positions;
};

export const getBucketPosition = (
  products?: Product[],
  positions?: {
    compartment: BucketArea;
    position: Position[];
  }[][]
) => {
  if (!products || !positions) return;

  const productId = products?.at(0)?.id;

  const bucketId = positions.findIndex((positionBucket) =>
    positionBucket.some(
      ({ compartment }) => compartment?.product_id === productId
    )
  );

  if (bucketId === -1) return;

  const positionBucketProduct = positions[bucketId].find(
    ({ compartment }) => compartment?.product_id === productId
  )?.position;

  if (!positionBucketProduct?.length) return;

  return {
    position: positionBucketProduct,
    bucketId,
  };
};
