import { GoBackButton } from '@components/molecules/goBackButtonIcon/goBackButton';
import { Categories } from '@components/organisms/categories/categories';
import { Box, Divider, Typography } from '@mui/material';
import { FC, MutableRefObject, useEffect, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import { ProductCategory } from 'types/productCategories';
import { InferType, object, string } from 'yup';
import { ProductsSearchResults } from '../productsSearchResults/productsSearchResults';
import { ProductsSearchTextField } from './fragments/productsSearchTextField';

import { useListProductsQuery } from '@reducers/shelfAppsApi';
import { itemsPerPage } from '@utils/product';
import type { Product } from 'types/common';
import { useProductsSearch } from '../../../hooks/useProductsSearch';
import { useAppSelector } from '@store/index';
import { selectPlanogramEditorState } from '@reducers/planogramEditor/selectors';

type Props = {
  categoryIndex: number;
  handleBack: () => void;
  productCategoryHistory: ProductCategory[];
  categoryType: 'categories' | 'list';
  handleClick: (category: ProductCategory) => void;
  fetchFilteredCategories: (text: string) => void;
  handleClickProductCategory: (category: ProductCategory) => void;
  drawerOpen?: boolean; // drawerで利用する時に必要
  handleSelectProductClick: (product: Product) => void; // actuals配下での利用
  categoryRef: MutableRefObject<HTMLDivElement | undefined>;
  chipsRef: MutableRefObject<HTMLDivElement | undefined>;
  height: string;
  selectedItemId?: number;
  clearProductState: () => void;
};

const schema = object({
  searchText: string().required(),
});

type FormData = InferType<typeof schema>;

export const ProductsSelectOnly: FC<Props> = ({
  categoryIndex,
  handleBack,
  productCategoryHistory,
  categoryType,
  handleClick,
  fetchFilteredCategories,
  handleClickProductCategory,
  drawerOpen,
  handleSelectProductClick,
  categoryRef,
  chipsRef,
  height,
  selectedItemId,
  clearProductState,
}) => {
  const { tags } = useAppSelector(selectPlanogramEditorState);
  const { isFocused, control, handleSubmit, searchWord, focus, search, reset } =
    useProductsSearch(fetchFilteredCategories);

  const productsRef = useRef<HTMLDivElement>(null);

  const onSubmit = (data: FormData) => {
    clearProductState();
    setSearchedOffset(0);
    search(data);
    productsRef.current?.scrollTo(0, 0);
  };

  const [offset, setOffset] = useState(0);
  const [searchedOffset, setSearchedOffset] = useState(0);

  const { data, isLoading, isFetching } = useListProductsQuery(
    {
      offset: offset,
      limit: itemsPerPage,
      detail: true,
      shape: true,
      category: [productCategoryHistory[categoryIndex]?.name],
      tags,
    },
    {
      skip: !!searchWord || categoryType === 'categories',
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    data: searchedData,
    isLoading: isSearchLoading,
    isFetching: isSearchFetching,
  } = useListProductsQuery(
    {
      offset: searchedOffset,
      limit: itemsPerPage,
      detail: true,
      shape: true,
      q: searchWord,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !searchWord,
    }
  );

  const handleEndReached = (index: number) => {
    if (isLoading || isFetching) return;
    const offset = index + 1;
    if (data?.pager.total && offset >= data?.pager.total) return; // NOTE: 全件取得済みのときは何もしない
    setOffset(offset);
  };

  const handleSearchEndReached = (index: number) => {
    if (isSearchLoading || isSearchFetching) return;
    const offset = index + 1;
    if (searchedData?.pager.total && offset >= searchedData?.pager.total)
      return; // NOTE: 全件取得済みのときは何もしない
    setSearchedOffset(offset);
  };

  useEffect(() => {
    const searchedText = document.getElementById('searchedTextId');
    if (searchedText && isFocused) {
      searchedText.focus();
    }
  }, [isFocused]);

  useEffect(() => {
    if (!drawerOpen) reset();
  }, [drawerOpen, reset]);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      display="flex"
      flexDirection="column"
      height="100%"
    >
      <Box
        component="div"
        sx={{
          py: 1,
          px: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        ref={categoryRef}
      >
        {!isFocused && (
          <Typography
            onClick={() => {
              handleBack();
              setOffset(0);
            }}
            component="h2"
            sx={{
              fontSize: '16px',
              fontWeight: 700,
              letterSpacing: '0.15px',
              cursor: categoryIndex > 0 ? 'pointer' : 'default',
              display: 'inline-flex',
              alignItems: 'center',
              lineHeight: '175%',
            }}
          >
            {categoryIndex > 0 && <GoBackButton width={32} height={32} />}
            {categoryIndex > 0
              ? productCategoryHistory[categoryIndex]?.name
              : 'カテゴリ'}
          </Typography>
        )}
        <Controller
          name="searchText"
          control={control}
          render={({ field }) => (
            <ProductsSearchTextField
              isFocused={isFocused}
              field={field}
              handleFocus={focus}
              reset={() => {
                reset();
                setSearchedOffset(0);
                setOffset(0);
                clearProductState();
              }}
            />
          )}
        />
      </Box>
      <Divider sx={{ mx: -1 }} />
      {!isFocused ? (
        <Box component="div" sx={{ pl: 2, pr: 1 }}>
          <Categories
            categoryName={productCategoryHistory[categoryIndex]?.name}
            categoryType={categoryType}
            productCategories={
              productCategoryHistory[categoryIndex]?.sub_categories
            }
            handleClick={handleClick}
            productsRef={productsRef}
            isDraggable={false}
            isLoading={isLoading || isFetching}
            offset={offset}
            handleClickProduct={handleSelectProductClick}
            products={data?.products}
            handleEndReached={handleEndReached}
            drawerHeight={height}
            chipsRef={chipsRef}
            selectedItemId={selectedItemId}
            setOffset={setOffset}
          />
        </Box>
      ) : (
        <>
          {searchWord && (
            <ProductsSearchResults
              products={searchedData?.products}
              reset={() => {
                reset();
                setSearchedOffset(0);
              }}
              handleClickProductCategory={handleClickProductCategory}
              isLoading={isSearchLoading || isSearchFetching}
              productsRef={productsRef}
              isDraggable={false}
              handleClickProduct={handleSelectProductClick}
              offset={searchedOffset}
              total={searchedData?.pager.total}
              handleEndReached={handleSearchEndReached}
              height={height}
              selectedItemId={selectedItemId}
            />
          )}
        </>
      )}
    </Box>
  );
};
